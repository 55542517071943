import { createSlice } from '@reduxjs/toolkit'

import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  customCollapsible: {
    isOpen: false,
    render: () => {}
  }
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    handleContentWidth: (state, action) => {
      state.contentWidth = action.payload
    },

    handleMenuCollapsed: (state, action) => {
      window.localStorage.setItem('menuCollapsed', action.payload)
      state.menuCollapsed = action.payload
    },

    handleMenuHidden: (state, action) => {
      state.menuHidden = action.payload
    },

    handleRTL: (state, action) => {
      state.isRTL = action.menu
    },
    updateCollapsible: (state, action) => {
      state.customCollapsible = action.payload
    }
  }
})

export const layoutActions = layoutSlice.actions

const layoutReducer = layoutSlice.reducer
export default layoutReducer
