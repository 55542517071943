import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  total: 0,
  loading: false,
  error: false,
  customerDetail: {}
}

const customerListSlice = createSlice({
  name: 'customerList',
  initialState,
  reducers: {
    getCustomerList: (state) => {
      state.loading = true
      state.error = false
      state.data = []
    },
    getCustomerListSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload.data
      state.total = action.payload.total
    },
    getCustomerListFailed: (state) => {
      state.loading = false
      state.error = true
      state.data = []
    },

    getCustomerDetail: (state) => {
      state.customerDetail = {}
      state.loading = true
      state.error = false
    },
    getCustomerDetailSuccess: (state, action) => {
      state.loading = false
      state.customerDetail = action.payload
    },
    getCustomerDetailFailed: (state) => {
      state.loading = false
      state.error = true
    }
  }
})

export const customerListActions = customerListSlice.actions

const customerListReducer = customerListSlice.reducer
export default customerListReducer
