import { APPROVAL_STATUS } from '@ourSrc/constants/approvalStatus'
import { formatCardSeri, getCardTypeLabel } from '@ourSrc/utility/CustomerUtils'
import { createSlice } from '@reduxjs/toolkit'

const approvalStatuses = Object.values(APPROVAL_STATUS)

export const formatData = (data, params = {}) =>
  data.map(({ card_type, card_seri, registration_confirm_status, card_no, ...rest }, idx) => ({
    ...rest,
    index: (params.offset || 0) + idx + 1,
    card_type: getCardTypeLabel(card_type),
    card_seri: formatCardSeri(card_seri),
    status_provide_card: !card_no.includes('tmp') ? 'Đã cấp' : '',
    registration_confirm_status: approvalStatuses.find(
      ({ value }) => value === `${registration_confirm_status}`
    )?.label,
    card_no
  }))

const initialState = {
  data: [],
  loading: false,
  error: false,
  totalCount: 0
}

const allCustomersSlice = createSlice({
  name: 'allCustomers',
  initialState,
  reducers: {
    getAllCustomers: (state) => {
      state.loading = true
      state.error = false
      state.data = []
    },
    getAllCustomersSuccess: (state, action) => {
      state.loading = false
      state.error = false
      state.data = formatData(action.payload.data, action.payload.params)
    },

    getAllCustomersFailed: (state) => {
      state.loading = false
      state.error = true
      state.data = []
    },

    getTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    removeCard: (state, action) => {
      state.loading = true
    },
    removeCardSuccess: (state, action) => {
      const cloneData = state.data
      console.log('data:', cloneData)
      const index = state.data.findIndex((item) => item.customer_id === action.payload.customer_id)
      cloneData[index] = {
        ...action.payload,
        index: index + 1,
        card_type: getCardTypeLabel(action.payload.card_type),
        card_seri: formatCardSeri(action.payload.card_seri),
        status_provide_card: !action.payload.card_no.includes('tmp') ? 'Đã cấp' : '',
        registration_confirm_status: approvalStatuses.find(
          ({ value }) => value === `${action.payload.registration_confirm_status}`
        )?.label
      }
      state.data = cloneData
      state.loading = false
    },
    removeCardFailed: (state) => {
      state.loading = false
    },
    exportExcel: () => {}
  }
})

export const allCustomersActions = allCustomersSlice.actions

const allCustomersReducer = allCustomersSlice.reducer
export default allCustomersReducer
