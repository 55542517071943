import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  loading: false,
  error: false
}

const promotionListSlice = createSlice({
  name: 'promotionList',
  initialState,
  reducers: {
    getPromotionList: (state) => {
      state.loading = true
      state.error = false
      state.data = []
    },
    getPromotionListSuccess: (state, action) => {
      state.loading = false
      state.error = false
      state.data = action.payload
    },

    getPromotionListFailed: (state) => {
      state.loading = false
      state.error = true
      state.data = []
    }
  }
})

export const promotionListActions = promotionListSlice.actions

const promotionListReducer = promotionListSlice.reducer
export default promotionListReducer
