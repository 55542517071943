// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './slices/auth.slice'
import navbar from './slices/navbar.slice'
import layout from './slices/layout.slice'
import car from './slices/car.slice'
import route from './slices/route.slice'
import carReport from '@ourSrc/views/reports/car-report/carReport.slice'
import inventoryReport from '@ourSrc/views/reports/inventory-reports/inventoryReport.slice'
import routeReport from '@ourSrc/views/reports/route-report/routeReport.slice'
import stationReport from '@ourSrc/views/reports/station-report/stationReport.slice'
import historyReportA2 from '@ourSrc/views/reportsA2/history-report/historyReportA2.slice'
import ticketChart from '@ourSrc/views/home/slices/ticketChart.slice'
import revenueChart from '@ourSrc/views/home/slices/revenueChart.slice'
import userChart from '@ourSrc/views/home/slices/userChart.saga'
import customerList from '@ourSrc/views/center/customer-list/customerList.slice'
import monthlyTicketWithZion from '@ourSrc/views/center/monthy-ticket-with-zion/monthlyTicketWithZion.slice'
import monthlyTicketAccounting from '@ourSrc/views/finance/accounting-monthy-ticket/monthlyTicketAccounting.slice'
import monthlyTicketWithEnterprise from '@ourSrc/views/center/monthy-ticket-with-enterprise/monthlyTicketWithEnterprise.slice'
import monthlyTicketWithEnterpriseA1 from '@ourSrc/views/reports/monthy-ticket-with-enterprise/monthlyTicketWithEnterprise.slice'
import periodicReport from '@ourSrc/views/center/periodic-report/periodicReport.slice'
import permissionList from '@ourSrc/views/setting/permission-list/permissionList.slice'
import roleList from '@ourSrc/views/setting/role-list/roleList.slice'
import userList from '@ourSrc/views/setting/user-list/userList.slice'
import changePassword from '@ourSrc/views/setting/change-password/changePassword.slice'
import vietbankHistory from '@ourSrc/views/vietbank/vietbank-history/vietbankHistory.slice'
import enterpriseList from '@ourSrc/views/enterprise/enterprise-list/enterpriseList.slice'
import carList from '@ourSrc/views/enterprise/car-list/carList.slice'
import allCustomers from '@ourSrc/views/customer/all-customers/allCustomers.slice'
import promotionList from '@ourSrc/views/customer/promotion-list/promotionList.slice'
import queryCustomer from '@ourSrc/views/customer/query-customer/queryCustomer.slice'
import newCustomer from '@ourSrc/views/customer/new-customer/newCustomer.slice'
import monthlyTicket from '@ourSrc/views/monthly-ticket/slice'
import errorSystem from '@ourSrc/views/error-system/slice'
import syncData from '@ourSrc/views/error-system/synchronize-data/slice'
import cashFlow from '@ourSrc/views/finance/cash-flow-management/slice'
import recipients from '@ourSrc/views/event/promotion-recipients/slice'
import eventList from '@ourSrc/views/event/event-list/slice'
import eInvoice from '@ourSrc/views/e-invoice/export/slice'
import vngBus from '@ourSrc/views/vng-bus/slice'
import tempReceipts from '@ourSrc/views/temp-receipts/slice'
import unipass from '@ourSrc/views/unipass/slice'
import form from './slices/form.slice'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  route,
  car,
  carReport,
  inventoryReport,
  routeReport,
  stationReport,
  ticketChart,
  revenueChart,
  userChart,
  customerList,
  monthlyTicketWithZion,
  monthlyTicketWithEnterprise,
  monthlyTicketWithEnterpriseA1,
  periodicReport,
  permissionList,
  roleList,
  userList,
  changePassword,
  vietbankHistory,
  enterpriseList,
  carList,
  allCustomers,
  promotionList,
  queryCustomer,
  newCustomer,
  monthlyTicket,
  historyReportA2,
  monthlyTicketAccounting,
  errorSystem,
  syncData,
  cashFlow,
  recipients,
  eventList,
  eInvoice,
  vngBus,
  tempReceipts,
  unipass,
  form
})

export default rootReducer
