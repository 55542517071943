import { fetch } from '.'

export default {
  getCustomerList: (params) => fetch('get', '/v3/customer/list', params),
  getCustomerDetail: (body) => fetch('post', '/v3/customer/detail', body),
  addCustomer: (body) => fetch('post', '/v3/customer/add', body),
  updateCustomer: (body) => fetch('put', '/v3/customer/update', body),
  getPromotions: (body) => fetch('post', '/v3/promotion/get_all_transactions', body),
  getPaymentHistory: (body) => fetch('post', '/v3/history/bos/list', body),
  removeCard: (cardNo) => fetch('get', `/v3/customer/customer-card/remove-card/${cardNo}`),
  // TODO
  getTopupHistory: (params) => ({ data: [] })
}
