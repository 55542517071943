import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  loading: false
}

const recipientsSlice = createSlice({
  name: 'recipients',
  initialState,
  reducers: {
    getRecipientsList: (state) => {
      state.loading = true
    },
    getRecipientsListSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    getRecipientsListFail: (state, action) => {
      state.loading = false
      state.data = {}
    },
    handleExport: (state) => {}
  }
})

export const recipientsActions = recipientsSlice.actions

const recipientsReducer = recipientsSlice.reducer
export default recipientsReducer
