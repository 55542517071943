import reportApi from '@ourSrc/api/reportApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import { monthlyTicketWithEnterpriseActions } from './monthlyTicketWithEnterprise.slice'
import { formatVnDate, errorToast, successToast, formatVnFullDate } from '@ourSrc/utility/Utils'
import moment from 'moment'
function* handleGetCenterMonthlyTicketWithEnterpriseGenaralReport({ payload }) {
  const { params } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketCenterEnterpriseGeneralReport, params)
    if (res?.data) {
      yield put(
        monthlyTicketWithEnterpriseActions.setCenterMonthlyTicketWithEnterpriseGenaralReportTotal(
          res?.data.totalItem
        )
      )
      const data = res?.data?.items.map((item) => {
        item.reconcileCode = `#${item.reconcileCode}-${item.enterpriseCode}`
        item.createdDate = formatVnDate(item.createdDate)
        item.status_message = item.status === 0 ? 'Chờ xử lý' : 'Thành công'
        return item
      })
      yield put(
        monthlyTicketWithEnterpriseActions.getCenterMonthlyTicketWithEnterpriseGenaralReportSuccess(
          data
        )
      )
    } else {
      yield put(
        monthlyTicketWithEnterpriseActions.getCenterMonthlyTicketWithEnterpriseGenaralReportFailed()
      )
    }
  } catch (error) {
    console.log('error: ', error)
  }
}
function* handleGetCenterMonthlyTicketWithEnterpriseGenaralDetail({ payload }) {
  const { id } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketCenterEnterpriseGeneralDetail, id)
    if (res?.data) {
      const formatDataForTable = res.data?.monthlyTicketItems?.map((item) => {
        item.amount = item.totalAmount / item.ticketCount
        return item
      })
      const data = {
        ...res.data,
        monthPublish: moment(res.data.createdDate).format('MM/YYYY'),
        datePublish: formatVnDate(res.data.createdDate),
        confirmedDate: res.data.confirmedDate ? formatVnDate(res.data.confirmedDate) : null,
        status_message: res?.data?.status === 0 ? 'Chờ xử lý' : 'Thành công',
        monthlyTicketItems: formatDataForTable
      }
      yield put(
        monthlyTicketWithEnterpriseActions.getCenterMonthlyTicketWithEnterpriseGenaralDetailSuccess(
          data
        )
      )
    } else {
      yield put(
        monthlyTicketWithEnterpriseActions.getCenterMonthlyTicketWithEnterpriseGenaralReportFailed()
      )
    }
  } catch (error) {
    console.log('error: ', error)
  }
}
function* handleConfirmPayment({ payload }) {
  const { id, data, onSuccess } = payload

  try {
    const res = yield call(reportApi.confirmTransferTransactionForEnterprise, id, data)
    if (res?.data) {
      onSuccess()
      successToast(
        'Xác nhận thanh toán thành công',
        `Đã xác nhận khấu trừ - thuế thành công ${res.data?.reconcileCode}`
      )
      yield put(monthlyTicketWithEnterpriseActions.confirmPaymentSuccess(res?.data))
      yield handleGetCenterMonthlyTicketWithEnterpriseGenaralDetail({ payload: { id } })
    } else {
      errorToast('Xác nhận thanh toán thất bại', res?.error.messageVN)
    }
  } catch (error) {
    errorToast(
      'Xác nhận thanh toán thất bại',
      'Đã có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên để được hỗ trợ.'
    )
    console.log('error: ', error)
  }
}
function* handleGetHistory({ payload }) {
  const { params } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketHistoryCenterEnterpriseHistory, params)
    if (res?.data) {
      yield put(monthlyTicketWithEnterpriseActions.setHistoryTotal(res?.data?.totalItem))

      const data = res?.data?.items.map((item, key) => {
        item.idx = res?.data?.offset + key + 1
        item.invoice_code = `#${item.invoice_code}`
        item.payment_status = item.payment_status === 2 ? 'Thành công' : ' Thất bại'
        return item
      })
      yield put(monthlyTicketWithEnterpriseActions.getHistorySuccess(data))
    } else {
      yield put(monthlyTicketWithEnterpriseActions.getHistoryFailed())
    }
  } catch (error) {
    console.log('error: ', error)
  }
}
export default function* monthlyTicketWithEnterpriseSaga() {
  yield takeLatest(
    monthlyTicketWithEnterpriseActions.getCenterMonthlyTicketWithEnterpriseGenaralReport.type,
    handleGetCenterMonthlyTicketWithEnterpriseGenaralReport
  )
  yield takeLatest(monthlyTicketWithEnterpriseActions.confirmPayment.type, handleConfirmPayment)

  yield takeLatest(
    monthlyTicketWithEnterpriseActions.getCenterMonthlyTicketWithEnterpriseGenaralDetail.type,
    handleGetCenterMonthlyTicketWithEnterpriseGenaralDetail
  )
  yield takeLatest(monthlyTicketWithEnterpriseActions.getHistory.type, handleGetHistory)
}
