import { kratos, findCsrfToken, findError, kratosAdmin } from '@ourUtils'

export const signIn = async (action) => {
  try {
    const f = await kratos.initializeSelfServiceLoginFlowForBrowsers(true)
    const rs = await kratos.submitSelfServiceLoginFlow(f.data.id, undefined, {
      method: 'password',
      csrf_token: findCsrfToken(f.data),
      password: action.params.password,
      password_identifier: action.params.email
    })
    return { isSuccess: true, data: rs.data.session }
  } catch (err) {
    let lock = true
    if (err?.response?.data?.error?.code !== 401) {
      lock = false
    }
    return { isSuccess: false, lock }
  }
}

export const signUp = async (action) => {
  try {
    const f = await kratos.initializeSelfServiceRegistrationFlowForBrowsers(undefined, {
      withCredentials: false
    })
    const rs = await kratos.submitSelfServiceRegistrationFlow(
      f.data.id,
      {
        method: 'password',
        csrf_token: findCsrfToken(f.data),
        password: action.params.password,
        traits: {
          email: action.params.email,
          name: {
            first: action.params.firstname,
            last: action.params.lastname
          }
        }
      },
      {
        withCredentials: false
      }
    )
    return { isSuccess: true }
  } catch (err) {
    console.log(findError(err))
    return { isSuccess: false, message: findError(err).message }
  }
}

export const signOut = async () => {
  try {
    const f = await kratos.createSelfServiceLogoutFlowUrlForBrowsers(undefined, {
      withCredentials: false
    })
    const rs = await kratos.submitSelfServiceLogoutFlow(f.data.logout_token)
    return { isSuccess: true }
  } catch (err) {
    return { isSuccess: false }
  }
}

export const getUser = async () => {
  try {
    const f = await kratos.toSession()
    return { isSuccess: true, data: f.data }
  } catch (err) {
    return { isSuccess: false, data: err }
  }
}

export const verificationEmail = async (email) => {
  try {
    const f = await kratos.initializeSelfServiceVerificationFlowForBrowsers()
    const rs = await kratos.submitSelfServiceVerificationFlow(f.data.id, undefined, {
      csrf_token: findCsrfToken(f.data),
      email: email,
      method: 'link'
    })
    return { isSuccess: true, data: rs.data }
  } catch (err) {
    console.log(findError(err))
    return { isSuccess: false, message: findError(err).message }
  }
}

export const recoveryPassword = async (email) => {
  try {
    const f = await kratos.initializeSelfServiceRecoveryFlowForBrowsers()
    const rs = await kratos.submitSelfServiceRecoveryFlow(f.data.id, undefined, {
      csrf_token: findCsrfToken(f.data),
      email: email,
      method: 'link'
    })
    return { isSuccess: true, data: rs.data }
  } catch (err) {
    console.log(findError(err))
    return { isSuccess: false, message: findError(err).message }
  }
}

export const resetPassword = async (newPassword) => {
  try {
    const f = await kratos.initializeSelfServiceSettingsFlowForBrowsers()
    const rs = await kratos.submitSelfServiceSettingsFlow(f.data.id, undefined, {
      csrf_token: findCsrfToken(f.data),
      password: newPassword,
      method: 'password'
    })
    return { isSuccess: true, data: rs.data }
  } catch (err) {
    console.log(err)
    return { isSuccess: false, message: err }
  }
}

export const getError = async (payload) => {
  try {
    const f = await kratos.getSelfServiceError(payload.id)
    return { isSuccess: true, data: f.data }
  } catch (err) {
    console.log(findError(err))
    return { isSuccess: false, message: findError(err).message }
  }
}

export const signOutAllDevice = async () => {
  try {
    const f = await kratos.toSession()
    const rs = await kratosAdmin.adminDeleteIdentitySessions(f.data.identity.id)
    return { isSuccess: true }
  } catch (err) {
    return { isSuccess: false }
  }
}

export const signInWithOidc = async (provider) => {
  try {
    const f = await kratos.initializeSelfServiceLoginFlowForBrowsers(true)
    const rs = await kratos.submitSelfServiceLoginFlow(f.data.id, undefined, {
      method: 'oidc',
      provider: provider,
      csrf_token: findCsrfToken(f.data),
      traits: {}
    })
  } catch (err) {
    if (err?.response?.data?.error?.code === 422) {
      const redirectUrl = err.response.data.redirect_browser_to
      window.location.href = redirectUrl
      console.log(err.response.data)
      return { isSuccess: true, message: '' }
    } else {
      return { isSuccess: false, message: 'Can not authenticate' }
    }
  }
}
