import { fetch } from '.'

const vinBusApi = {
  getHistory(params) {
    const url = '/v3/vinbus/report/transaction'
    return fetch('post', url, params)
  }
}

export default vinBusApi
