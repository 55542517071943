import { put, call, takeLatest } from '@redux-saga/core/effects'
import { ticketChartActions } from '../slices/ticketChart.slice'
import reportApi from '@ourSrc/api/reportApi'

const totalByCondition = (data, ticketType) => {
  const paymentMethodETicket = [2, 3, 6, 8, 10, 12, 14]
  if (!data) return 0
  return paymentMethodETicket.reduce((acc, current) => {
    return acc + (data[`total_ticket_${current}_${ticketType}`] || 0)
  }, 0)
}

function* handleGetTicketPieChart({ payload }) {
  const { params } = payload
  params.paymentStatus = 2
  try {
    const response = yield call(reportApi.getHistoryTotal, params)

    let data = [
      totalByCondition(response?.data, 4),
      totalByCondition(response?.data, 5),
      totalByCondition(response?.data, 6),
      totalByCondition(response?.data, 9),
      response.data.total_ticket_4_1,
      response.data.total_ticket_4_2,
      response.data.total_ticket_4_3,
      response.data.total_ticket_4_7
    ]

    data = data.map((i) => (!i ? 0 : i))
    data = data.filter((i) => i === 0).length === data.length ? [] : data

    yield put(
      ticketChartActions.getTicketPieChartSuccess({
        pie: data,
        total: {
          cash: response?.data?.total_ticket_cash,
          eTicket: response?.data?.total_success - response?.data?.total_ticket_cash
        }
      })
    )
  } catch (error) {
    yield put(ticketChartActions.getTicketPieChartFailed())
    console.log('error', error)
  }
}

const convertTicketBarChartData = (data, year) => {
  return data.map((month, idx) => ({
    name: `T${idx + 1} ${year}`,
    ve_dien_tu: month.total_ticket_card,
    ve_tien_mat: month.total_ticket_cash,
    total: month.total_ticket_card + month.total_ticket_cash
  }))
}

function* handleGetTicketBarChart({ payload }) {
  const { params } = payload
  params.paymentStatus = 2
  try {
    const response = yield call(reportApi.getHistoryTotalByYear, params)
    let data = convertTicketBarChartData(response.data, params.year || '')
    data = data.filter((i) => i.total !== 0).length !== 0 ? data : []
    yield put(ticketChartActions.getTicketBarChartSuccess(data))
  } catch (error) {
    yield put(ticketChartActions.getTicketBarChartFailed())
    console.log('error', error)
  }
}

export default function* ticketChartSaga() {
  yield takeLatest(ticketChartActions.getTicketPieChart.type, handleGetTicketPieChart)
  yield takeLatest(ticketChartActions.getTicketBarChart.type, handleGetTicketBarChart)
}
