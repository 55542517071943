import {
  cashInTransactionApi,
  cashInUserApi,
  cashOutTransactionApi,
  cashOutUserApi,
  refundByTransactionApi,
  refundByUserApi,
  surplusUserApi
} from '@ourSrc/api/cashFlowManagement'
import { PAYMENT_STATUS, UNIPASS } from '@ourSrc/constants/dataType'
import { CUSTOMER_TYPE } from '@ourSrc/constants/queryCustomer'
import { errorToast, formatVnFullDate } from '@ourSrc/utility/Utils'
import { call, put, takeLatest } from 'redux-saga/effects'
import { cashFlowActions } from './slice'
const customerTypeList = Object.values(CUSTOMER_TYPE)

const formatResponse = (dataObject) => {
  return dataObject.items.map((item, key) => {
    const paymentStatusList = Object.values(PAYMENT_STATUS)
    const f = paymentStatusList.find((i) => i.value === item.status)
    const userType = customerTypeList.find((i) => i.value === item.customerType)
    const refundType = Object.values(UNIPASS.refund.refundType).find(
      (i) => i.value === item.refundType
    )
    const refundStatus = Object.values(UNIPASS.refund.status).find(
      (i) => i.value === item.refundStatus
    )
    item.customerType = userType?.label
    item.index = dataObject.offset + key + 1
    item.chargeDate = formatVnFullDate(item.chargeDate)
    item.statusMessage = item.status === 0 ? 'Thất bại' : f?.label
    item.createdFormat = formatVnFullDate(item?.created)
    item.createDateFormat = formatVnFullDate(item?.createdTime)
    item.refundTypeName = refundType?.label
    item.refundStatusName = refundStatus?.label
    item.partnerText = UNIPASS.paymentGateway.partner?.[item?.partner]?.label
    item.paymentChanelText = UNIPASS.paymentGateway.paymentChanel?.[item?.paymentChanel]?.label
    item.paymentMethodGroupText =
      UNIPASS.paymentGateway.paymentMethodGroup?.[item?.paymentMethodGroup]?.label
    return item
  })
}

function* handleGetRefundByUser({ payload }) {
  const { params } = payload
  try {
    const res = yield call(refundByUserApi.getRefundByUserApi, {
      ...params,
      refundStatus: UNIPASS.refund.status.FINISHED.value
    })
    if (res?.data) {
      const items = formatResponse(res.data)
      yield put(cashFlowActions.getRefundByUserSuccess({ ...res.data, items: items }))
    } else {
      errorToast('Thất bại', res?.error?.messageVN)
      yield put(cashFlowActions.getRefundByUserFailed())
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetUserSumary({ payload }) {
  const { params } = payload
  try {
    const res = yield call(refundByUserApi.getRefundByUserSumaryApi, params)
    if (res?.data) {
      yield put(cashFlowActions.getRefundByUserSumarySuccess(res.data))
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetRefundByTransaction({ payload }) {
  const { params } = payload
  try {
    const res = yield call(refundByTransactionApi.getRefundByTransactionApi, params)
    const resSummary = yield call(refundByTransactionApi.getRefundByTransactionSumaryApi, params)
    if (res?.data && resSummary?.data) {
      const items = formatResponse(res.data)
      yield put(cashFlowActions.getRefundByTransactionSuccess({ ...res.data, items: items }))
      yield put(
        cashFlowActions.getRefundByTransactionSumarySuccess({
          ...resSummary?.data,
          totalTransaction: res?.data?.totalItem
        })
      )
    } else {
      errorToast('Thất bại', res?.error?.messageVN)
      yield put(cashFlowActions.getRefundByTransactionFailed())
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetCashInTransaction({ payload }) {
  const { params } = payload
  try {
    const res = yield call(cashInTransactionApi.getCashInTransactionApi, params)
    if (res?.data) {
      const items = formatResponse(res.data)
      yield put(cashFlowActions.getCashInTransactionSuccess({ ...res.data, items: items }))
    } else {
      errorToast('Thất bại', res?.error?.messageVN)
      yield put(cashFlowActions.getCashInTransactionFailed())
    }
  } catch (error) {
    console.log('error', error)
  }
}
function* handleGetSumary({ payload }) {
  const { params } = payload
  try {
    const res = yield call(cashInTransactionApi.getSumary, params)
    if (res?.data) {
      yield put(cashFlowActions.getSumarySuccess(res.data))
    }
  } catch (error) {
    console.log('error', error)
  }
}
//surplus user
function* handleGetSurplusUser({ payload }) {
  const { params } = payload
  try {
    const res = yield call(surplusUserApi.getSurplusUserList, params)
    if (res?.data) {
      const items = formatResponse(res.data)
      yield put(cashFlowActions.getSurplusUserListSuccess({ ...res.data, items: items }))
    } else {
      yield put(cashFlowActions.getSurplusUserListFailed())
    }
  } catch (error) {
    console.log('error', error)
  }
}
//cash in user
function* handleGetCashInUserList({ payload }) {
  const { params } = payload
  try {
    const res = yield call(cashInUserApi.getCashInUserList, params)
    if (res?.data) {
      const items = formatResponse(res.data)
      yield put(cashFlowActions.getCashInUserListSuccess({ ...res.data, items: items }))
    } else {
      yield put(cashFlowActions.getCashInUserListFailed())
    }
  } catch (error) {
    console.log('error', error)
  }
}
//cash out user
function* handleGetCashOutUserList({ payload }) {
  const { params } = payload
  try {
    const res = yield call(cashOutUserApi.getCashOutUserList, params)
    if (res?.data) {
      const items = formatResponse(res.data)
      yield put(cashFlowActions.getCashOutUserListSuccess({ ...res.data, items: items }))
    } else {
      yield put(cashFlowActions.getCashOutUserListFailed())
    }
  } catch (error) {
    console.log('error', error)
  }
}
//cash out transaction
function* handleGetCashOutTransactionList({ payload }) {
  const { params } = payload
  try {
    const res = yield call(cashOutTransactionApi.getCashOutTransactionList, params)
    if (res?.data) {
      const items = formatResponse(res.data)
      yield put(cashFlowActions.getCashOutTransactionListSuccess({ ...res.data, items: items }))
    } else {
      yield put(cashFlowActions.getCashOutTransactionListFailed())
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleExportExcel({ payload }) {
  const { params, onSuccess, api } = payload
  try {
    const res = yield call(api, params)
    if (res?.data) {
      const items = formatResponse(res.data)
      onSuccess(items)
    }
  } catch (error) {
    console.log('error', error)
  }
}
export default function* cashFlowSaga() {
  //cash in transaction
  yield takeLatest(cashFlowActions.getCashInTransaction.type, handleGetCashInTransaction)
  yield takeLatest(cashFlowActions.getSumary.type, handleGetSumary)
  //refund by transaction
  yield takeLatest(cashFlowActions.getRefundByTransaction.type, handleGetRefundByTransaction)
  //refund in user
  yield takeLatest(cashFlowActions.getRefundByUser.type, handleGetRefundByUser)
  yield takeLatest(cashFlowActions.getRefundByUserSumary.type, handleGetUserSumary)
  //surplus each user
  yield takeLatest(cashFlowActions.getSurplusUserList.type, handleGetSurplusUser)
  //cash in user
  yield takeLatest(cashFlowActions.getCashInUserList.type, handleGetCashInUserList)
  //cash out user
  yield takeLatest(cashFlowActions.getCashOutUserList.type, handleGetCashOutUserList)
  //cash out transaction
  yield takeLatest(cashFlowActions.getCashOutTransactionList.type, handleGetCashOutTransactionList)
  //common
  yield takeLatest(cashFlowActions.exportExcel.type, handleExportExcel)
}
