import _sumBy from 'lodash/sumBy'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import reportApi from '@ourSrc/api/reportApi'
import { periodicReportActions } from './periodicReport.slice'

function* getChart(params) {
  yield put(periodicReportActions.getData({ target: 'chart' }))
  try {
    const response = yield call(reportApi.getRouteGeneral, params)
    const { total_amount, total_ticket } = response.data
    const {
      total_amount_1,
      total_amount_2,
      total_amount_3,
      total_amount_4,
      total_amount_5,
      total_amount_6,
      total_amount_7
    } = total_amount
    const {
      total_ticket_1,
      total_ticket_2,
      total_ticket_3,
      total_ticket_4,
      total_ticket_5,
      total_ticket_6,
      total_ticket_7
    } = total_ticket

    yield put(
      periodicReportActions.getDataSuccess({
        target: 'chart',
        ticket: [
          { name: 'Thẻ UniPass', value: total_ticket_1 },
          { name: 'QR Code', value: total_ticket_2 },
          { name: 'Ngân hàng', value: total_ticket_3 },
          { name: 'ZaloPay', value: total_ticket_5 + total_ticket_6 },
          { name: 'Momo', value: total_ticket_7 },
          { name: 'Tiền mặt', value: total_ticket_4 }
        ],
        amount: [
          { name: 'Thẻ UniPass', value: total_amount_1 },
          { name: 'QR Code', value: total_amount_2 },
          { name: 'Ngân hàng', value: total_amount_3 },
          { name: 'ZaloPay', value: total_amount_5 + total_amount_6 },
          { name: 'Momo', value: total_amount_7 },
          { name: 'Tiền mặt', value: total_amount_4 }
        ]
      })
    )
  } catch (error) {
    console.log('error', error)
  }
}

function* getRegistry(params) {
  yield put(periodicReportActions.getData({ target: 'registry' }))
  try {
    const response = yield call(reportApi.getCustomerRegistry, params)

    const data = [
      { index: 1, card: 'Số lượng thẻ phát hành', card_type_0: 0 },
      { index: 2, card: 'Số lượng thẻ sử dụng', card_type_0: 0 }
    ]
    response.data.forEach((i) => {
      if (i.cardType === 1 || i.cardType === 2 || i.cardType === 5 || i.cardType === 7) {
        data[0][`card_type_${i.cardType}`] = i.totalCard
        data[1][`card_type_${i.cardType}`] = i.totalCardUsed
      } else {
        data[0][`card_type_0`] += i.totalCard
        data[1][`card_type_0`] += i.totalCardUsed
      }
    })

    data.forEach((i) => {
      const { card_type_0, card_type_1 = 0, card_type_2 = 0, card_type_5 = 0, card_type_7 = 0 } = i
      i.total = card_type_0 + card_type_1 + card_type_2 + card_type_5 + card_type_7
    })

    yield put(periodicReportActions.getDataSuccess({ target: 'registry', data }))
  } catch (error) {}
}

function* getPayment(params) {
  yield put(periodicReportActions.getData({ target: 'payment' }))

  try {
    const response = yield call(reportApi.getRouteByCustomer, params)
    const tickets = response.data.map((i) => i.total_ticket)
    // card
    const total_ticket_1_1 = _sumBy(tickets, 'total_ticket_1_1') // phổ thông
    const total_ticket_1_2 = _sumBy(tickets, 'total_ticket_1_2') // sinh viên
    const total_ticket_1_3 = _sumBy(tickets, 'total_ticket_1_3') // người cao tuổi
    const total_ticket_1_4 = _sumBy(tickets, 'total_ticket_1_4') // trẻ em
    const total_ticket_1_5 = _sumBy(tickets, 'total_ticket_1_5') // thương binh
    const total_ticket_1_6 = _sumBy(tickets, 'total_ticket_1_6') // người nghèo
    const total_ticket_1_7 = _sumBy(tickets, 'total_ticket_1_7') // học sinh

    // QR
    const total_ticket_2_1 = _sumBy(tickets, 'total_ticket_2_1')
    const total_ticket_2_2 = _sumBy(tickets, 'total_ticket_2_2')
    const total_ticket_2_3 = _sumBy(tickets, 'total_ticket_2_3')
    const total_ticket_2_4 = _sumBy(tickets, 'total_ticket_2_4')
    const total_ticket_2_5 = _sumBy(tickets, 'total_ticket_2_5')
    const total_ticket_2_6 = _sumBy(tickets, 'total_ticket_2_6')
    const total_ticket_2_7 = _sumBy(tickets, 'total_ticket_2_7')

    // bank
    const total_ticket_3_1 = _sumBy(tickets, 'total_ticket_3_1')
    const total_ticket_3_2 = _sumBy(tickets, 'total_ticket_3_2')
    const total_ticket_3_3 = _sumBy(tickets, 'total_ticket_3_3')
    const total_ticket_3_4 = _sumBy(tickets, 'total_ticket_3_4')
    const total_ticket_3_5 = _sumBy(tickets, 'total_ticket_3_5')
    const total_ticket_3_6 = _sumBy(tickets, 'total_ticket_3_6')
    const total_ticket_3_7 = _sumBy(tickets, 'total_ticket_3_7')

    // cash
    const total_ticket_4_1 = _sumBy(tickets, 'total_ticket_4_1')
    const total_ticket_4_2 = _sumBy(tickets, 'total_ticket_4_2')
    const total_ticket_4_3 = _sumBy(tickets, 'total_ticket_4_3')
    const total_ticket_4_4 = _sumBy(tickets, 'total_ticket_4_4')
    const total_ticket_4_5 = _sumBy(tickets, 'total_ticket_4_5')
    const total_ticket_4_6 = _sumBy(tickets, 'total_ticket_4_6')
    const total_ticket_4_7 = _sumBy(tickets, 'total_ticket_4_7')

    // zalopay (quickpay + payment gateway)
    const total_ticket_zalopay_1 =
      _sumBy(tickets, 'total_ticket_5_1') + _sumBy(tickets, 'total_ticket_6_1')
    const total_ticket_zalopay_2 =
      _sumBy(tickets, 'total_ticket_5_2') + _sumBy(tickets, 'total_ticket_6_2')
    const total_ticket_zalopay_3 =
      _sumBy(tickets, 'total_ticket_5_3') + _sumBy(tickets, 'total_ticket_6_3')
    const total_ticket_zalopay_4 =
      _sumBy(tickets, 'total_ticket_5_4') + _sumBy(tickets, 'total_ticket_6_4')
    const total_ticket_zalopay_5 =
      _sumBy(tickets, 'total_ticket_5_5') + _sumBy(tickets, 'total_ticket_6_5')
    const total_ticket_zalopay_6 =
      _sumBy(tickets, 'total_ticket_5_6') + _sumBy(tickets, 'total_ticket_6_6')
    const total_ticket_zalopay_7 =
      _sumBy(tickets, 'total_ticket_5_7') + _sumBy(tickets, 'total_ticket_6_7')

    // momo (quickpay )
    const total_ticket_momo_1 = _sumBy(tickets, 'total_ticket_7_1')
    const total_ticket_momo_2 = _sumBy(tickets, 'total_ticket_7_2')
    const total_ticket_momo_3 = _sumBy(tickets, 'total_ticket_7_3')
    const total_ticket_momo_4 = _sumBy(tickets, 'total_ticket_7_4')
    const total_ticket_momo_5 = _sumBy(tickets, 'total_ticket_7_5')
    const total_ticket_momo_6 = _sumBy(tickets, 'total_ticket_7_6')
    const total_ticket_momo_7 = _sumBy(tickets, 'total_ticket_7_7')

    let data = [
      {
        index: 1,
        customer: 'Phổ thông',
        unipass: total_ticket_1_1,
        qr: total_ticket_2_1,
        bank: total_ticket_3_1,
        zalopay: total_ticket_zalopay_1,
        momo: total_ticket_momo_1,
        cash: total_ticket_4_1
      },
      {
        index: 2,
        customer: 'Học sinh',
        unipass: total_ticket_1_7,
        qr: total_ticket_2_7,
        bank: total_ticket_3_7,
        zalopay: total_ticket_zalopay_7,
        momo: total_ticket_momo_7,
        cash: total_ticket_4_7
      },
      {
        index: 3,
        customer: 'Sinh viên',
        unipass: total_ticket_1_2,
        qr: total_ticket_2_2,
        bank: total_ticket_3_2,
        zalopay: total_ticket_zalopay_2,
        momo: total_ticket_momo_2,
        cash: total_ticket_4_2
      },
      {
        index: 4,
        customer: 'Khác',
        unipass: total_ticket_1_3 + total_ticket_1_4 + total_ticket_1_5 + total_ticket_1_6,
        qr: total_ticket_2_3 + total_ticket_2_4 + total_ticket_2_5 + total_ticket_2_6,
        bank: total_ticket_3_3 + total_ticket_3_4 + total_ticket_3_5 + total_ticket_3_6,
        zalopay:
          total_ticket_zalopay_3 +
          total_ticket_zalopay_4 +
          total_ticket_zalopay_5 +
          total_ticket_zalopay_6,
        momo: total_ticket_momo_3 + total_ticket_momo_4 + total_ticket_momo_5 + total_ticket_momo_6,
        cash: total_ticket_4_3 + total_ticket_4_4 + total_ticket_4_5 + total_ticket_4_6
      }
    ]

    data = data.map((i) => ({ ...i, total: i.unipass + i.qr + i.bank + i.cash }))
    yield put(periodicReportActions.getDataSuccess({ target: 'payment', data }))
  } catch (error) {}
}

function* getTicket(params) {
  yield put(periodicReportActions.getData({ target: 'ticket' }))
  try {
    const response = yield call(reportApi.getRouteByPayment, params)

    const tickets = response.data.map((i) => i.total_ticket)

    // card
    const total_ticket_1_1 = _sumBy(tickets, 'total_ticket_1_1')
    const total_ticket_1_2 = _sumBy(tickets, 'total_ticket_1_2')
    const total_ticket_1_3 = _sumBy(tickets, 'total_ticket_1_3')
    const total_ticket_1_4 = _sumBy(tickets, 'total_ticket_1_4')
    const total_ticket_1_11 = _sumBy(tickets, 'total_ticket_1_11')
    const total_ticket_1_12 = _sumBy(tickets, 'total_ticket_1_12')
    const total_ticket_1_13 = _sumBy(tickets, 'total_ticket_1_13')
    const total_ticket_1_14 = _sumBy(tickets, 'total_ticket_1_14')
    const total_ticket_1_15 = _sumBy(tickets, 'total_ticket_1_15')
    const total_ticket_1_16 = _sumBy(tickets, 'total_ticket_1_16')

    // QR
    const total_ticket_2_1 = _sumBy(tickets, 'total_ticket_2_1')
    const total_ticket_2_2 = _sumBy(tickets, 'total_ticket_2_2')
    const total_ticket_2_3 = _sumBy(tickets, 'total_ticket_2_3')
    const total_ticket_2_4 = _sumBy(tickets, 'total_ticket_2_4')
    const total_ticket_2_11 = _sumBy(tickets, 'total_ticket_2_11')
    const total_ticket_2_12 = _sumBy(tickets, 'total_ticket_2_12')
    const total_ticket_2_13 = _sumBy(tickets, 'total_ticket_2_13')
    const total_ticket_2_14 = _sumBy(tickets, 'total_ticket_2_14')
    const total_ticket_2_15 = _sumBy(tickets, 'total_ticket_2_15')
    const total_ticket_2_16 = _sumBy(tickets, 'total_ticket_2_16')

    // bank
    const total_ticket_3_1 = _sumBy(tickets, 'total_ticket_3_1')
    const total_ticket_3_2 = _sumBy(tickets, 'total_ticket_3_2')
    const total_ticket_3_3 = _sumBy(tickets, 'total_ticket_3_3')
    const total_ticket_3_4 = _sumBy(tickets, 'total_ticket_3_4')
    const total_ticket_3_11 = _sumBy(tickets, 'total_ticket_3_11')
    const total_ticket_3_12 = _sumBy(tickets, 'total_ticket_3_12')
    const total_ticket_3_13 = _sumBy(tickets, 'total_ticket_3_13')
    const total_ticket_3_14 = _sumBy(tickets, 'total_ticket_3_14')
    const total_ticket_3_15 = _sumBy(tickets, 'total_ticket_3_15')
    const total_ticket_3_16 = _sumBy(tickets, 'total_ticket_3_16')

    // cash
    const total_ticket_4_1 = _sumBy(tickets, 'total_ticket_4_1')
    const total_ticket_4_2 = _sumBy(tickets, 'total_ticket_4_2')
    const total_ticket_4_3 = _sumBy(tickets, 'total_ticket_4_3')
    const total_ticket_4_4 = _sumBy(tickets, 'total_ticket_4_4')
    const total_ticket_4_11 = _sumBy(tickets, 'total_ticket_4_11')
    const total_ticket_4_12 = _sumBy(tickets, 'total_ticket_4_12')
    const total_ticket_4_13 = _sumBy(tickets, 'total_ticket_4_13')
    const total_ticket_4_14 = _sumBy(tickets, 'total_ticket_4_14')
    const total_ticket_4_15 = _sumBy(tickets, 'total_ticket_4_15')
    const total_ticket_4_16 = _sumBy(tickets, 'total_ticket_4_16')

    // zalopay (payment gateway and quickpay)
    const total_ticket_zalopay_1 =
      _sumBy(tickets, 'total_ticket_5_1') + _sumBy(tickets, 'total_ticket_6_1')
    const total_ticket_zalopay_2 =
      _sumBy(tickets, 'total_ticket_5_2') + _sumBy(tickets, 'total_ticket_6_2')
    const total_ticket_zalopay_3 =
      _sumBy(tickets, 'total_ticket_5_3') + _sumBy(tickets, 'total_ticket_6_3')
    const total_ticket_zalopay_4 =
      _sumBy(tickets, 'total_ticket_5_4') + _sumBy(tickets, 'total_ticket_6_4')
    const total_ticket_zalopay_11 =
      _sumBy(tickets, 'total_ticket_5_11') + _sumBy(tickets, 'total_ticket_6_11')
    const total_ticket_zalopay_12 =
      _sumBy(tickets, 'total_ticket_5_12') + _sumBy(tickets, 'total_ticket_6_12')
    const total_ticket_zalopay_13 =
      _sumBy(tickets, 'total_ticket_5_13') + _sumBy(tickets, 'total_ticket_6_13')
    const total_ticket_zalopay_14 =
      _sumBy(tickets, 'total_ticket_5_14') + _sumBy(tickets, 'total_ticket_6_14')
    const total_ticket_zalopay_15 =
      _sumBy(tickets, 'total_ticket_5_15') + _sumBy(tickets, 'total_ticket_6_15')
    const total_ticket_zalopay_16 =
      _sumBy(tickets, 'total_ticket_5_16') + _sumBy(tickets, 'total_ticket_6_16')

    // mono
    const total_ticket_momo_1 = _sumBy(tickets, 'total_ticket_7_1')
    const total_ticket_momo_2 = _sumBy(tickets, 'total_ticket_7_2')
    const total_ticket_momo_3 = _sumBy(tickets, 'total_ticket_7_3')
    const total_ticket_momo_4 = _sumBy(tickets, 'total_ticket_7_4')
    const total_ticket_momo_11 = _sumBy(tickets, 'total_ticket_7_11')
    const total_ticket_momo_12 = _sumBy(tickets, 'total_ticket_7_12')
    const total_ticket_momo_13 = _sumBy(tickets, 'total_ticket_7_13')
    const total_ticket_momo_14 = _sumBy(tickets, 'total_ticket_7_14')
    const total_ticket_momo_15 = _sumBy(tickets, 'total_ticket_7_15')
    const total_ticket_momo_16 = _sumBy(tickets, 'total_ticket_7_16')

    const cash_popular_ticket =
      total_ticket_4_1 + //4 ve tien mat, 1 ve thuong tien mat
      total_ticket_4_11 +
      total_ticket_4_12 +
      total_ticket_4_13 +
      total_ticket_4_14 +
      total_ticket_4_15 +
      total_ticket_4_16

    const zalopay_popular_ticket =
      total_ticket_zalopay_1 +
      total_ticket_zalopay_11 +
      total_ticket_zalopay_12 +
      total_ticket_zalopay_13 +
      total_ticket_zalopay_14 +
      total_ticket_zalopay_15 +
      total_ticket_zalopay_16

    const momo_popular_ticket =
      total_ticket_momo_1 +
      total_ticket_momo_11 +
      total_ticket_momo_12 +
      total_ticket_momo_13 +
      total_ticket_momo_14 +
      total_ticket_momo_15 +
      total_ticket_momo_16

    const data = [
      {
        index: 1,
        payment: 'Vé lượt',
        unipass: total_ticket_1_1,
        qr: total_ticket_2_1,
        bank: total_ticket_3_1,
        zalopay: zalopay_popular_ticket,
        momo: momo_popular_ticket,
        cash: cash_popular_ticket,
        total:
          total_ticket_1_1 +
          total_ticket_2_1 +
          total_ticket_3_1 +
          cash_popular_ticket +
          momo_popular_ticket +
          zalopay_popular_ticket
      },

      {
        index: 2,
        payment: 'Vé học sinh, sinh viên',
        unipass: total_ticket_1_2,
        qr: total_ticket_2_2,
        bank: total_ticket_3_2,
        cash: total_ticket_4_2,
        zalopay: total_ticket_zalopay_2,
        momo: total_ticket_momo_2,
        total:
          total_ticket_1_2 +
          total_ticket_2_2 +
          total_ticket_3_2 +
          total_ticket_4_2 +
          total_ticket_zalopay_2 +
          total_ticket_momo_2
      },

      {
        index: 3,
        payment: 'Vé tập',
        unipass: total_ticket_1_4,
        qr: total_ticket_2_4,
        bank: total_ticket_3_4,
        cash: total_ticket_4_4,
        zalopay: total_ticket_zalopay_4,
        momo: total_ticket_momo_4,
        total:
          total_ticket_1_4 +
          total_ticket_2_4 +
          total_ticket_3_4 +
          total_ticket_4_4 +
          total_ticket_zalopay_4 +
          total_ticket_momo_4
      },

      {
        index: 4,
        payment: 'Vé miễn',
        unipass: total_ticket_1_3,
        qr: total_ticket_2_3,
        bank: total_ticket_3_3,
        cash: total_ticket_4_3,
        zalopay: total_ticket_zalopay_3,
        momo: total_ticket_momo_3,
        total:
          total_ticket_1_3 +
          total_ticket_2_3 +
          total_ticket_3_3 +
          total_ticket_4_3 +
          total_ticket_zalopay_3 +
          total_ticket_momo_3
      }
    ]

    yield put(periodicReportActions.getDataSuccess({ target: 'ticket', data }))
  } catch (error) {}
}

function* handleGetPeriodicReport({ payload }) {
  const { params } = payload
  yield all([getChart(params), getRegistry(params), getPayment(params), getTicket(params)])
}

export default function* periodicReportSaga() {
  yield takeLatest(periodicReportActions.getPeriodicReport.type, handleGetPeriodicReport)
}
