import { createSlice } from '@reduxjs/toolkit'

const formatData = (data) =>
  data.map((car, idx) => {
    const tickets = {}
    for (const [ticket, params] of Object.entries(car.data)) {
      for (const [key, value] of Object.entries(params)) {
        tickets[[key, ticket].join(':')] = value
      }
    }

    return {
      index: idx + 1,
      ...car,
      ...tickets
    }
  })

const initialState = {
  data: [],
  adding: false,
  updating: false,
  loading: false,
  error: false,
  tickets: [],
  getTicketLoading: false
}

const carListSlice = createSlice({
  name: 'carList',
  initialState,
  reducers: {
    getCarList: (state) => {
      state.loading = true
      state.error = false
      state.data = []
    },

    getCarListSuccess: (state, action) => {
      state.loading = false
      state.data = formatData(action.payload)
    },

    getCarListFailed: (state) => {
      state.loading = false
      state.error = true
    },

    addCar: (state) => {
      state.adding = true
      state.data = []
    },

    addCarSuccess: (state, action) => {
      state.adding = false
      state.data = formatData([action.payload, ...state.data])
    },

    addCarFailed: (state) => {
      state.adding = false
    },

    updateCar: (state) => {
      state.updating = true
    },

    updateCarSuccess: (state, action) => {
      state.updating = false
      state.data = formatData(
        state.data.map((car) => {
          if (action.payload.car_id !== car.car_id) return car
          return { ...car, ...action.payload }
        })
      )
    },

    updateCarFailed: (state) => {
      state.updating = false
    },

    changeCarStatus: (state) => {
      state.updating = true
    },

    changeCarStatusSuccess: (state, action) => {
      state.updating = false
      state.data = formatData(
        state.data.map((car) => {
          if (action.payload.car_id !== car.car_id) return car
          return { ...car, ...action.payload }
        })
      )
    },

    changeCarStatusFailed: (state) => {
      state.updating = false
    },
    changeMaxTicketNumber: (state) => {
      state.updating = true
    },
    changeMaxTicketNumberSuccess: (state, action) => {
      state.updating = false
      state.data = formatData(
        state.data.map((car) => {
          if (action.payload.car_id !== car.car_id) return car
          return { ...car, ...action.payload }
        })
      )
    },
    changeMaxTicketNumberFailed: (state) => {
      state.updating = false
    },
    getTicketByRoute: (state) => {
      state.tickets = []
      state.getTicketLoading = true
    },
    getTicketByRouteSuccess: (state, action) => {
      state.tickets = action.payload
      state.getTicketLoading = false
    }
  }
})

export const carListActions = carListSlice.actions

const carListReducer = carListSlice.reducer
export default carListReducer
