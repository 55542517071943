import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  loading: false,
  error: false
}

const stationReportSlice = createSlice({
  name: 'stationReport',
  initialState,
  reducers: {
    getStationReport: (state) => {
      state.data = []
      state.loading = true
      state.error = false
    },
    getStationReportSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    getStationReportFailed: (state) => {
      state.loading = false
      state.error = true
    }
  }
})

export const stationReportActions = stationReportSlice.actions

const stationReportReducer = stationReportSlice.reducer
export default stationReportReducer
