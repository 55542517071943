import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  bos: {
    data: [],
    loading: false,
    error: false,
    total: 0,
    currentPage: 1,
    selected: [],
    loadingExecute: false,
    loadingCreateOrUpdate: false,
    isShowPopup: false,
    progressData: { value: 0, total: 0, itemSuccess: 0, itemProcessed: 0, loading: false },
    filter: { limit: 10 }
  },
  zalopay: {
    data: [],
    loading: false,
    error: false,
    currentPage: 1,
    total: 0,
    selected: [],
    loadingExecute: false,
    filter: { limit: 10 }
  }
}

const errorSystemSlice = createSlice({
  name: 'errorSystem',
  initialState,
  reducers: {
    setFilterZalopay: (state, action) => {
      state.zalopay.filter = action.payload
    },
    initialDataErrorZalopay: (state) => {
      state.zalopay.data = []
      state.zalopay.loading = false
      state.zalopay.currentPage = 1
      state.zalopay.error = false
      state.zalopay.total = 0
      state.zalopay.selected = []
    },
    getBlacklistZalopay: (state) => {
      state.zalopay.data = []
      state.zalopay.currentPage = 1
      state.zalopay.loading = true
      state.zalopay.error = false
      state.zalopay.total = 0
    },
    getBlacklistZalopaySuccess: (state, action) => {
      state.zalopay.data = action.payload.items
      state.zalopay.currentPage = action.payload.offset / action.payload.limit + 1
      state.zalopay.loading = false
      state.zalopay.error = false
      state.zalopay.total = action.payload.totalItem
    },
    getBlacklistZalopayFailed: (state) => {
      state.zalopay.data = []
      state.zalopay.loading = false
      state.zalopay.error = true
      state.zalopay.currentPage = 1
      state.zalopay.total = 0
    },
    deleteBlacklistZalopay: (state) => {
      state.zalopay.loadingExecute = true
      state.zalopay.error = false
    },
    deleteBlacklistZalopayFinish: (state) => {
      state.zalopay.loadingExecute = false
      state.zalopay.error = false
    },
    setListCheckedZalopay: (state, action) => {
      state.zalopay.selected = action.payload
    },
    repayZalopay: (state) => {
      state.zalopay.loadingExecute = true
      state.zalopay.error = false
    },
    repayZalopayFinish: (state) => {
      state.zalopay.loadingExecute = false
      state.zalopay.error = false
    },
    //bos
    setFilter: (state, action) => {
      state.bos.filter = action.payload
    },
    setListCheckedBos: (state, action) => {
      state.bos.selected = action.payload
    },
    initialDataErrorBos: (state) => {
      state.bos.data = []
      state.bos.loading = false
      state.bos.currentPage = 1
      state.bos.error = false
      state.bos.total = 0
      state.bos.selected = []
    },
    getListErrorBos: (state) => {
      state.bos.data = []
      state.bos.currentPage = 1
      state.bos.loading = true
      state.bos.error = false
      state.bos.total = 0
    },
    getListErrorBosSuccess: (state, action) => {
      state.bos.data = action.payload.items
      state.bos.currentPage = action.payload.offset + 1
      state.bos.loading = false
      state.bos.error = false
      state.bos.total = action.payload.totalItem
    },
    getListErrorBosFailed: (state) => {
      state.bos.data = []
      state.bos.loading = false
      state.bos.currentPage = 1
      state.bos.error = true
      state.bos.total = 0
    },
    deleteInvoiceErrorBos: (state) => {
      state.bos.loadingExecute = true
    },
    deleteInvoiceErrorBosFinish: (state) => {
      state.bos.loadingExecute = false
    },
    createInvoiceBos: (state) => {
      state.bos.loadingCreateOrUpdate = true
      state.bos.isShowPopup = true
    },
    createInvoiceBosSuccess: (state) => {
      state.bos.isShowPopup = false
    },
    createInvoiceBosFinish: (state) => {
      state.bos.loadingCreateOrUpdate = false
    },
    createAndSyncInvoiceBos: (state) => {
      state.bos.isShowPopup = true
      state.bos.loadingCreateOrUpdate = true
    },
    createAndSyncInvoiceBosFinish: (state) => {
      state.bos.loadingCreateOrUpdate = false
    },
    updateInvoiceBos: (state) => {
      state.bos.loadingCreateOrUpdate = true
      state.bos.isShowPopup = true
    },
    updateInvoiceBosSuccess: (state) => {
      state.bos.isShowPopup = false
    },
    updateInvoiceBosFinish: (state, action) => {
      state.bos.loadingCreateOrUpdate = false
    },
    updateAndSyncInvoiceBos: (state) => {
      state.bos.loadingCreateOrUpdate = true
      state.bos.isShowPopup = true
    },
    syncInvoiceBos: (state, action) => {
      state.bos.progressData = {
        ...state.bos.progressData,
        value: 0,
        itemProcessed: 0,
        total: 0,
        itemSuccess: 0,
        loading: true
      }
    },
    syncInvoiceBosSuccess: (state, action) => {
      state.bos.progressData = {
        ...state.bos.progressData,
        value: Number.parseFloat(
          (action.payload?.itemProcessed / action?.payload?.total) * 100
        ).toFixed(2),
        itemProcessed: action.payload.itemProcessed,
        total: action?.payload?.total,
        itemSuccess: action.payload.itemSuccess
      }
    },
    syncInvoiceBosFinish: (state) => {
      state.bos.progressData = {
        ...state.bos.progressData,
        loading: false
      }
    },
    handleExportBosError: () => {},
    handleExportZalopayError: () => {}
  }
})

export const errorSystemActions = errorSystemSlice.actions

const errorSystemReducer = errorSystemSlice.reducer
export default errorSystemReducer
