import unipassApi from '@ourSrc/api/unipass'
import { UNIPASS } from '@ourSrc/constants/dataType'
import { CUSTOMER_TYPE } from '@ourSrc/constants/queryCustomer'
import {
  errorToast,
  formatVnFullDate,
  getSevenDaysBeforeToday,
  successToast,
  warningToast
} from '@ourSrc/utility/Utils'
import { call, takeLatest, put, select } from '@redux-saga/core/effects'
import { unipassActions } from './slice'

const formatData = (data) =>
  data?.items?.map((item, key) => {
    const userType = Object.values(CUSTOMER_TYPE).find((i) => i.value === item.customerType)
    const refundType = Object.values(UNIPASS.refund.refundType).find(
      (i) => i.value === item.refundType
    )
    const refundStatus = Object.values(UNIPASS.refund.status).find(
      (i) => i.value === item.refundStatus
    )
    item.index = data?.offset + key + 1
    item.customerTypeName = userType?.label
    item.refundTypeName = refundType?.label
    item.createDateFormat = formatVnFullDate(item.createdTime)
    item.confirmedTimeFormat = formatVnFullDate(item.confirmedTime)
    item.finishedTimeFormat = formatVnFullDate(item.finishedTime)
    item.failedTimeFormat = formatVnFullDate(item?.failedTime)
    item.canceledTimeFormat = formatVnFullDate(item?.canceledTime)
    item.refundStatusName = refundStatus?.label
    return item
  })

function* handleGetRefundRequests({ payload }) {
  const { params } = payload
  try {
    const body = { ...params }
    if (!body?.toDate) {
      const { fromDate, toDate } = getSevenDaysBeforeToday()
      body.fromDate = fromDate
      body.toDate = toDate
    }
    const refundRequests = yield call(unipassApi.getRefundRequests, body)
    const summary = yield call(unipassApi.getRefundRequestsSummary, body)
    if (refundRequests?.error?.code === 1 && summary?.error?.code === 1) {
      yield put(
        unipassActions.getRefundRequestsDone({
          data: { ...refundRequests?.data, items: formatData(refundRequests?.data) },
          summary: summary?.data
        })
      )
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleValidatePhone({ payload }) {
  const { phone, form, setAmount } = payload
  try {
    const refundRequests = yield call(unipassApi.getCustomerInfo, { phone })
    const customerRequests = yield call(unipassApi.getRefundRequests, { accountName: phone })
    if (refundRequests?.error?.code !== 1) {
      form.setError('phone', { type: 'custom', message: 'Tài khoản không tồn tại' })
    } else {
      form.setValue('owner', refundRequests?.data?.customer_name)
      if (refundRequests?.data?.balance) {
        setAmount(refundRequests?.data?.balance)
      } else {
        warningToast('Cảnh báo', 'Số tiền không đủ để thực hiện hoàn tiền')
      }
      if (customerRequests?.data?.items.length) {
        const f = customerRequests.data.items.filter((item) =>
          [UNIPASS.refund.status.CONFIRMED.value, UNIPASS.refund.status.PENDING.value].includes(
            item.refundStatus
          )
        )
        if (f?.length)
          form.setError('phone', { type: 'custom', message: 'Số điện thoại đã đăng ký hoàn tiền' })
      }
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleCreateRefundRequest({ payload }) {
  const { body, setLoading, toggle, refetch } = payload
  try {
    setLoading(true)
    const { email } = yield select((state) => state.auth.user.identity.traits)
    console.log('email', email)
    const refundRequest = yield call(unipassApi.createRefundRequest, {
      ...body,
      requester: email,
      refundType: UNIPASS.refund.refundType.admin.value
    })

    if (refundRequest?.error?.code === 1) {
      refetch()
      toggle()
      successToast(
        'Tạo yêu cầu hoàn tiền thành công',
        <span>
          Đã tạo thành công yêu cầu hoàn tiền <b>{`"${body.phone}"`}</b>
        </span>
      )
    } else {
      errorToast('Tạo yêu cầu hoàn tiền không thành công', 'Đã có lỗi xảy ra, vui lòng thử lại.')
    }
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoading(false)
  }
}

function* handleExportExcel({ payload }) {
  const { params, onSuccess, setLoadingExport } = payload
  try {
    setLoadingExport(true)
    const refundRequests = yield call(unipassApi.getRefundRequests, params)
    onSuccess(formatData(refundRequests?.data))
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoadingExport(false)
  }
}

function* handleCancelRequest({ payload }) {
  try {
    const { note, cancelRefundRequests, setLoadingConfirm, onSuccess, onFaied, refetch, type } =
      payload
    setLoadingConfirm(true)
    const { email } = yield select((state) => state.auth.user.identity.traits)
    function* process(data) {
      yield* data.map((x) => x)
    }
    const failedItems = []
    for (const n of process(cancelRefundRequests)) {
      const body = {
        note,
        requester: email,
        refundStatus: type,
        refundId: n.refundId
      }

      const response = yield call(unipassApi.updateStatus, body)
      if (response.error.code === 1) {
      } else {
        failedItems.push(n)
      }
    }
    setLoadingConfirm(false)
    refetch()
    if (!failedItems?.length) {
      yield put(unipassActions.setRefundCheck([]))
      onSuccess(cancelRefundRequests.length)
    } else {
      onFaied({
        note,
        cancelRefundRequests: failedItems,
        total: cancelRefundRequests.length
      })
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleUpdateRequest({ payload }) {
  try {
    const { status, updateStatusRequests, setLoadingConfirm, onSuccess, onFaied, refetch } = payload
    setLoadingConfirm(true)
    const { email } = yield select((state) => state.auth.user.identity.traits)
    function* process(data) {
      yield* data.map((x) => x)
    }
    const failedItems = []
    for (const n of process(updateStatusRequests)) {
      const body = {
        requester: email,
        refundStatus: status,
        refundId: n.refundId
      }

      const response = yield call(unipassApi.updateStatus, body)
      if (response.error.code === 1) {
      } else {
        failedItems.push(n)
      }
    }
    setLoadingConfirm(false)
    refetch()
    if (!failedItems?.length) {
      yield put(unipassActions.setRefundCheck([]))
      onSuccess(updateStatusRequests.length)
    } else {
      onFaied({
        updateStatusRequests: failedItems,
        total: updateStatusRequests.length
      })
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleImport({ payload }) {
  const { data, setLoadingImport, refetch } = payload
  try {
    setLoadingImport(true)
    const { email } = yield select((state) => state.auth.user.identity.traits)
    let formatData = [...data]
    formatData.forEach((element, indexRoot) => {
      Object.entries(element).forEach(([key, value], index) => {
        Object.entries(UNIPASS.refund.importField).some(([keyTrans, valueTrans]) => {
          if (valueTrans === key) {
            formatData[indexRoot][keyTrans] = value
            delete formatData[indexRoot][key]
            return true
          }
        })
      })
    })
    formatData = formatData.map((d) => {
      d.refundStatus =
        d.refundStatus === 'Thất bại'
          ? UNIPASS.refund.status.FAILED.value
          : d.refundStatus === 'Thành công'
          ? UNIPASS.refund.status.FINISHED.value
          : null
      return d
    })
    function* process(data) {
      yield* data.map((x) => x)
    }

    for (const n of process(formatData)) {
      const body = {
        ...n,
        requester: email
      }
      const response = yield call(unipassApi.updateStatusWhenImport, body)
      if (response.error.code === 1) {
        successToast('Thành công', `${n.accountName} cập nhật trạng thái thành công`)
      } else {
        errorToast(
          'Thất bại',
          `${n.accountName} cập nhật trạng thái thất bại, ${response?.error?.messageVN}`
        )
      }
    }
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoadingImport(false)
    refetch()
  }
}

export default function* unipassSaga() {
  //refund
  yield takeLatest(unipassActions.getRefundRequests.type, handleGetRefundRequests)
  yield takeLatest(unipassActions.validatePhone.type, handleValidatePhone)
  yield takeLatest(unipassActions.createRefundRequest.type, handleCreateRefundRequest)
  yield takeLatest(unipassActions.exportExcel.type, handleExportExcel)
  yield takeLatest(unipassActions.cancelRequest.type, handleCancelRequest)
  yield takeLatest(unipassActions.updateRequest.type, handleUpdateRequest)
  yield takeLatest(unipassActions.import.type, handleImport)
}
