import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pieLoading: false,
  pieError: false,
  pie: [],
  total: {},

  barLoading: false,
  barError: false,
  bar: []
}

const revenueChartSlice = createSlice({
  name: 'revenueChart',
  initialState,
  reducers: {
    getRevenuePieChart: (state) => {
      state.pieLoading = true
      state.pieError = false
    },
    getRevenuePieChartSuccess: (state, action) => {
      state.pieLoading = false
      state.pie = action.payload.pie
      state.total = action.payload.total
    },
    getRevenuePieChartFailed: (state) => {
      state.pieLoading = false
      state.pieError = true
    },

    getRevenueBarChart: (state) => {
      state.barLoading = true
      state.barError = false
    },
    getRevenueBarChartSuccess: (state, action) => {
      state.barLoading = false
      state.bar = action.payload
    },
    getRevenueBarChartFailed: (state) => {
      state.barLoading = false
      state.barError = true
    }
  }
})

export const revenueChartActions = revenueChartSlice.actions

const revenueChartReducer = revenueChartSlice.reducer
export default revenueChartReducer
