import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  total: 0,
  loading: false,
  error: false
}

const inventoryReportSlice = createSlice({
  name: 'inventoryReport',
  initialState,
  reducers: {
    getInventoryReport: (state) => {
      state.data = []
      state.loading = true
      state.error = false
    },

    getInventoryReportSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload.data
      state.total = action.payload.total
    },

    getInventoryReportFailed: (state) => {
      state.loading = false
      state.error = true
    }
  }
})

export const inventoryReportActions = inventoryReportSlice.actions

const inventoryReportReducer = inventoryReportSlice.reducer
export default inventoryReportReducer
