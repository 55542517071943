import { all, call, fork, put, take, takeLatest } from '@redux-saga/core/effects'

import {
  signIn,
  signUp,
  signOut,
  getUser,
  verificationEmail,
  recoveryPassword,
  resetPassword,
  getError,
  signOutAllDevice,
  signInWithOidc
} from '@ourSrc/api/authApi'
import { authActions } from '@store/slices/auth.slice'
import { apiErrorToast, diffMinutes, successToast, errorToast } from '@ourUtils'
import { Redirect } from 'react-router-dom'
import settingApi from '@ourSrc/api/settingApi'

function* handleLogin({ payload }) {
  const { isSuccess, lock, data } = yield call(signIn, payload)
  if (lock) payload.onLoginFail()
  if (isSuccess) {
    const verified = data.identity.verifiable_addresses[0].verified
    const email = data.identity.traits.email
    const response = yield call(settingApi.getUserByUserId, data.identity.id)
    let roles = []
    const permissions = []
    if (response.data) {
      roles = response.data?.rolesResponse || []
      roles.map((r) => {
        if (r.permissions && r.permissions.length > 0) {
          Array.prototype.push.apply(permissions, r.permissions)
        }
      })
      const permissionsUnique = [...new Map(permissions.map((item) => [item['id'], item])).values()]
      const params = {
        roles,
        permissions: permissionsUnique,
        verified,
        isRoot: response.data.root
      }
      payload.onSuccess(email, verified)
      yield put(authActions.loginSuccess(params))
    } else {
      yield put(authActions.loginFailed({ lock }))
    }
  } else {
    yield put(authActions.loginFailed({ lock }))
  }
}

function* handleRegister({ payload }) {
  const { isSuccess, message } = yield call(signUp, payload)
  if (isSuccess) {
    yield put(authActions.registerSuccess())
    payload.onSuccess(payload.params.email)
  } else {
    if (message.includes('exists already')) {
      payload.onFaied({
        name: 'email',
        message: 'Email này đã có người sử dụng, vui lòng sử dụng email khác'
      })
      yield put(
        authActions.registerFailed({
          email: 'Email này đã có người sử dụng, vui lòng sử dụng email khác',
          password: ''
        })
      )
    } else {
      payload.onFaied({
        name: 'password',
        message:
          'Vui lòng chọn một mật khẩu có tính bảo mật cao hơn. Hãy thử kết hợp các chữ cái, số và ký tự.'
      })
      yield put(
        authActions.registerFailed({
          email: '',
          password:
            'Vui lòng chọn một mật khẩu có tính bảo mật cao hơn. Hãy thử kết hợp các chữ cái, số và ký tự.'
        })
      )
    }
  }
}

function* handleLogout() {
  yield call(signOut)
  yield put(authActions.logoutSuccess())
  return <Redirect to="/login" />
}

function* handleGetInfoUser({ payload }) {
  const { onFinish } = payload
  const { isSuccess, data } = yield call(getUser)
  if (isSuccess) {
    const response = yield call(settingApi.getUserByUserId, data.identity.id)
    let roles = []
    const permissions = []
    if (response.data) {
      roles = response.data?.rolesResponse || []
      roles.map((r) => {
        if (r.permissions && r.permissions.length > 0) {
          Array.prototype.push.apply(permissions, r.permissions)
        }
      })
      const permissionsUnique = [...new Map(permissions.map((item) => [item['id'], item])).values()]
      const params = {
        roles,
        permissions: permissionsUnique,
        verified: true,
        isRoot: response.data.root
      }
      yield put(authActions.loginSuccess(params))
      yield put(authActions.getInfoSuccess(data))
    }
  }
  if (onFinish) onFinish()
}

function* handleVerifyEmail({ payload }) {
  try {
    const time = window.localStorage.getItem('timeRequestVerifyEmail')
    if (time) {
      const minutes = diffMinutes(new Date(), new Date(time))
      if (minutes > 3) {
        successToast('Thông báo', 'Đã gửi lại mail xác nhận, vui lòng kiểm tra email của bạn')
        const { isSuccess, data } = yield call(verificationEmail, payload)
        if (isSuccess) {
          window.localStorage.setItem('timeRequestVerifyEmail', new Date())
        }
      } else {
        errorToast('Thông báo', `Vui lòng thử lại sau ${3 - minutes} phút`)
      }
    } else {
      window.localStorage.setItem('timeRequestVerifyEmail', new Date())
      successToast('Thông báo', 'Đã gửi lại mail xác nhận, vui lòng kiểm tra email của bạn')
      const { isSuccess, data } = yield call(verificationEmail, payload)
    }
  } catch (err) {
    console.log('Error:', err)
  }
}

function* handleRecovery({ payload }) {
  try {
    const time = window.localStorage.getItem('timeRequestVerifyEmail')
    if (time) {
      const minutes = diffMinutes(new Date(), new Date(time))
      if (minutes > 3) {
        const { isSuccess, data } = yield call(recoveryPassword, payload.email)
        if (isSuccess) {
          window.localStorage.setItem('timeRequestVerifyEmail', new Date())
          payload.onSuccess()
          yield put(authActions.recoveryPasswordSuccess())
        } else {
          yield put(authActions.recoveryPasswordFailed())
        }
      } else {
        yield put(authActions.recoveryPasswordFailed())
        errorToast('Thông báo', `Vui lòng thử lại sau ${3 - minutes} phút`)
      }
    } else {
      window.localStorage.setItem('timeRequestVerifyEmail', new Date())
      const { isSuccess, data } = yield call(recoveryPassword, payload.email)
      if (isSuccess) {
        window.localStorage.setItem('timeRequestVerifyEmail', new Date())
        payload.onSuccess()
        yield put(authActions.recoveryPasswordSuccess())
      } else {
        yield put(authActions.recoveryPasswordFailed())
      }
    }
  } catch (err) {
    console.log('Error:', err)
  }
}

function* handleReset({ payload }) {
  const { isSuccess, data } = yield call(resetPassword, payload.newPassword)
  if (isSuccess) {
    yield call(signOut)
    payload.onSuccess()
  } else {
    yield put(authActions.resetPasswordFailed())
  }
}

function* handleGetError({ payload }) {
  const { isSuccess, data } = yield call(getError, payload)
  if (isSuccess) {
    yield put(authActions.getErrorSuccess(data.error))
    payload.onLoginFail()
  }
}

function* handleLogoutAllDevice() {
  const data = yield call(signOutAllDevice)
  if (data.isSuccess) {
    yield put(authActions.logoutSuccess())
    return <Redirect to="/login" />
  }
}

function* handleLoginWithOidc({ payload: { provider } }) {
  console.log('handle login with: ', provider)
  const { isSuccess, message, data } = yield call(signInWithOidc, provider)
  if (isSuccess) {
    // yield put(authActions.loginSuccess(data))
  } else {
    yield put(authActions.loginFailed())
    apiErrorToast(message)
  }
}
export default function* authSaga() {
  yield takeLatest(authActions.register.type, handleRegister)
  yield takeLatest(authActions.login.type, handleLogin)
  yield takeLatest(authActions.logout.type, handleLogout)
  yield takeLatest(authActions.getInfo.type, handleGetInfoUser)
  yield takeLatest(authActions.verifyEmail.type, handleVerifyEmail)
  yield takeLatest(authActions.recoveryPassword.type, handleRecovery)
  yield takeLatest(authActions.resetPassword.type, handleReset)
  yield takeLatest(authActions.getError.type, handleGetError)
  yield takeLatest(authActions.logoutAllDevice.type, handleLogoutAllDevice)
  yield takeLatest(authActions.loginWithOidc, handleLoginWithOidc)
}
