import { fetch } from '.'

const carApi = {
  getCarList() {
    const url = '/v3/car/list'
    return fetch('get', url, {})
  },

  getCarListByRoute(routeId) {
    const url = `/v3/car/list-by-route/${routeId}`
    return fetch('get', url, {})
  },

  getCarListByRouteNumber(routeNumber) {
    const url = `/v3/car/list-by-route-number/${routeNumber}`
    return fetch('get', url, {})
  },

  getCarListByEnterpriseCodeAndRouteNumber(enterpriseCode, routeNumber) {
    const url = `/v3/car/list-by-route-number/${enterpriseCode}/${routeNumber}`
    return fetch('get', url, {})
  },

  getCarsByRoute(epCode, routeNumber, status) {
    const url = `/v3/car/cars-by-route/${epCode}/${routeNumber}?status=${status}`
    return fetch('get', url, {})
  },

  getCarListByEnterprise(enterpriseId) {
    const url = `/v3/car/list-by-enterprise/${enterpriseId}`
    return fetch('get', url, {})
  },

  getCars(params) {
    const url = `/v3/car/cars`
    return fetch('post', url, params)
  },

  addCar(body) {
    const url = `/v3/car/add`
    return fetch('post', url, body)
  },

  updateCar(carId, body) {
    const url = `/v3/car/update/${carId}`
    return fetch('put', url, body)
  },

  changeCarStatus(carId) {
    const url = `/v3/car/change-status/${carId}`
    return fetch('put', url, {})
  },
  changeMaxTicketNumber(carId, body) {
    const url = `/v3/car/update/max-ticket-number/${carId}`
    return fetch('put', url, body)
  },
  getTicketByRoute(routeNumber) {
    const url = `/v3/ticket/list-by-route-number/${routeNumber}`
    return fetch('get', url, null)
  }
}

export default carApi
