import { fetch } from '.'

const unipassApi = {
  getRefundRequests(params) {
    const url = '/v3/unipass-wallet/refund/get-list'
    return fetch('post', url, params)
  },
  getRefundRequestsSummary(params) {
    const url = '/v3/unipass-wallet/refund/get-summary'
    return fetch('post', url, params)
  },
  getCustomerInfo(params) {
    const url = '/v3/customer/detail'
    return fetch('post', url, params)
  },
  createRefundRequest(params) {
    const url = '/v3/unipass-wallet/refund'
    return fetch('post', url, params)
  },
  updateStatus(params) {
    const url = '/v3/unipass-wallet/refund/status'
    return fetch('put', url, params)
  },
  updateStatusWhenImport(params) {
    const url = '/v3/unipass-wallet/refund/status-zlp'
    return fetch('put', url, params)
  },
  REGISTER_BECOME_PARTNER: {
    url: '/v3/landing-page/become-partner-registers',
    method: 'POST'
  },
  PAYMENT_GATEWAY_TRANSACTIONS: {
    url: '/v3/payment-gateway/transactions',
    method: 'POST'
  },
  PAYMENT_GATEWAY_SUMMARY: {
    url: '/v3/payment-gateway/transactions/summary',
    method: 'POST'
  },
  GET_DETAIL_PAYMENT_INVOICE: {
    url: '/v3/report/history/transaction/:id',
    method: 'GET'
  },
  GET_DETAIL_RECHARGE_INVOICE: {
    url: 'v3/unipass-wallet/bos/cashin-transactions/:id',
    method: 'GET'
  }
}

export default unipassApi
