import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  registry: { data: [], loading: false, error: false },
  payment: { data: [], loading: false, error: false },
  ticket: { data: [], loading: false, error: false },
  chart: { ticket: [], amount: [], loading: false, error: false }
}

const periodicReportSlice = createSlice({
  name: 'periodicReport',
  initialState,
  reducers: {
    getPeriodicReport: (state) => {},

    getData: (state, action) => {
      if (action.payload.target === 'chart') {
        state[action.payload.target].ticket = []
        state[action.payload.target].amount = []
      } else {
        state[action.payload.target].data = []
      }
      state[action.payload.target].loading = true
      state[action.payload.target].error = false
    },
    getDataSuccess: (state, action) => {
      state[action.payload.target].loading = false
      if (action.payload.target === 'chart') {
        state[action.payload.target].ticket = action.payload.ticket
        state[action.payload.target].amount = action.payload.amount
      } else {
        state[action.payload.target].data = action.payload.data
      }
    },
    getDataFailed: (state, action) => {
      state[action.payload.target].loading = false
      state[action.payload.target].error = true
    }
  }
})

export const periodicReportActions = periodicReportSlice.actions

const periodicReportReducer = periodicReportSlice.reducer
export default periodicReportReducer
