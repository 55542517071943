import { call, put, takeLatest } from '@redux-saga/core/effects'
import carApi from '@ourSrc/api/carApi'
import { carListActions } from './carList.slice'
import { successToast, apiErrorToast, currencyFormat, errorToast } from '@ourSrc/utility/Utils'
import { TICKET_TYPE } from '@ourSrc/constants/ticketType'
function* handleGetCarList({ payload: { params } }) {
  try {
    const res = yield call(carApi.getCars, params)
    const formatData = res.data.map((item) => {
      item.statusMessage = item.status === 1 ? 'Đang hoạt động' : 'Ngừng hoạt động'
      return item
    })
    yield put(carListActions.getCarListSuccess(formatData))
  } catch (error) {
    yield put(carListActions.getCarListFailed())
  }
}

function* handleAddCar({ payload: { body } }) {
  try {
    const res = yield call(carApi.addCar, body)
    if (res && res.error.code === 1) {
      successToast('Thông báo', 'Tạo xe thành công')
      yield put(carListActions.addCarSuccess(res.data))
    } else {
      apiErrorToast(res.error.messageVN)
      yield put(carListActions.addCarFailed())
    }
  } catch (error) {
    apiErrorToast('Đã có lỗi xảy ra')
    yield put(carListActions.addCarFailed())
  }
}

function* handleUpdateCar({ payload: { carId, body } }) {
  try {
    const res = yield call(carApi.updateCar, carId, body)
    if (res && res.error.code === 1) {
      successToast('Thông báo', 'Cập nhật thành công')
      yield put(carListActions.updateCarSuccess(res.data))
    } else {
      apiErrorToast(res.error.messageVN)
      yield put(carListActions.updateCarFailed())
    }
  } catch (error) {
    apiErrorToast('Đã có lỗi xảy ra')
    yield put(carListActions.updateCarFailed())
  }
}

function* handleChangeCarStatus({ payload: { carId } }) {
  try {
    const res = yield call(carApi.changeCarStatus, carId)
    if (res?.error?.code === 1) {
      successToast('Thành công', 'Cập nhật trạng thái thành công')
      yield put(
        carListActions.changeCarStatusSuccess({
          ...res.data,
          statusMessage: res.data.status === 1 ? 'Đang hoạt động' : 'Ngừng hoạt động'
        })
      )
    } else {
      errorToast('Thất bại', res?.error?.messageVN)
    }
  } catch (error) {
    yield put(carListActions.changeCarStatusFailed())
    errorToast('Thất bại', 'Đã có lỗi xảy ra')
  }
}
function* handleChangeMaxTicketNumber({ payload: { carId, body } }) {
  try {
    const res = yield call(carApi.changeMaxTicketNumber, carId, { data: body })
    if (res && res.error.code === 1) {
      successToast('Thông báo', 'Cập nhật thành công')
      yield put(carListActions.changeMaxTicketNumberSuccess(res.data))
    } else {
      apiErrorToast(res.error.messageVN)
      yield put(carListActions.changeMaxTicketNumberFailed())
    }
  } catch (error) {
    yield put(carListActions.changeMaxTicketNumberFailed())
  }
}
function* handleGetTicketByRoute({ payload: { routeNumber } }) {
  try {
    const res = yield call(carApi.getTicketByRoute, routeNumber)
    if (res && res.error.code === 1) {
      const unexpectTickets = [
        TICKET_TYPE[4],
        TICKET_TYPE[5],
        TICKET_TYPE[6],
        TICKET_TYPE[8],
        TICKET_TYPE[9]
      ].map((m) => m.value)
      const formatData = res.data
        .map((m) => {
          m.value = m.ticketTypeId
          m.name = `${m.ticketTypeName} - ${m?.ticketTypeLongDesc ?? ''} - ${currencyFormat(
            m?.ticketPrice
          )}đ`
          return m
        })
        .filter((f) => !unexpectTickets.includes(f.value))
      yield put(carListActions.getTicketByRouteSuccess(formatData))
    }
  } catch (error) {
    console.log('error', error)
  }
}
export default function* carListSaga() {
  yield takeLatest(carListActions.getCarList.type, handleGetCarList)
  yield takeLatest(carListActions.addCar.type, handleAddCar)
  yield takeLatest(carListActions.updateCar.type, handleUpdateCar)
  yield takeLatest(carListActions.changeCarStatus.type, handleChangeCarStatus)

  yield takeLatest(carListActions.changeMaxTicketNumber, handleChangeMaxTicketNumber)
  yield takeLatest(carListActions.getTicketByRoute, handleGetTicketByRoute)
}
