import { call, put, takeLatest } from '@redux-saga/core/effects'

import carApi from '@ourSrc/api/carApi'
import { carActions } from '@ourSrc/store/slices/car.slice'

function* handleGetCars() {
  try {
    const response = yield call(carApi.getCarsByEp, {})
    yield put(carActions.getCarsSuccess(response.dt))
  } catch (error) {
    yield put(carActions.getCarsFailed())
  }
}

export default function* carSaga() {
  yield takeLatest(carActions.getCars.type, handleGetCars)
}
