import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  loading: false,
  error: false
}

const routeReportSlice = createSlice({
  name: 'routeReport',
  initialState,
  reducers: {
    getRouteReport: (state) => {
      state.data = []
      state.loading = true
      state.error = false
    },
    getRouteReportSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    getRouteReportFailed: (state) => {
      state.loading = false
      state.error = true
    }
  }
})

export const routeReportActions = routeReportSlice.actions

const routeReportReducer = routeReportSlice.reducer
export default routeReportReducer
