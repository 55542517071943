import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  loading: false,
  childLoading: false,
  error: false
}

const carReportSlice = createSlice({
  name: 'carReport',
  initialState,
  reducers: {
    getRootData: (state) => {
      state.data = []
      state.loading = true
      state.childLoading = false
      state.error = false
    },
    getRootDataSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    getRootDataFailed: (state) => {
      state.loading = false
      state.error = true
    },

    getLv1Data: (state) => {
      state.childLoading = true
    },
    getLv1DataSuccess: (state, action) => {
      state.childLoading = false
      state.data = action.payload
    },
    getLv1DataFailed: (state) => {
      state.childLoading = false
    },

    getLv2Data: (state) => {
      state.childLoading = true
    },
    getLv2DataSuccess: (state, action) => {
      state.childLoading = false
      state.data = action.payload
    },
    getLv2DataFailed: (state) => {
      state.childLoading = false
    }
  }
})

export const carReportActions = carReportSlice.actions

const carReportReducer = carReportSlice.reducer
export default carReportReducer
