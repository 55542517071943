import { fetch } from '.'

const monthlyTicketApi = {
  getSaleList(params) {
    const url = '/v3/monthly-ticket/bos/transaction'
    return fetch('get', url, params)
  },

  getSaleDetail(id) {
    const url = `/v3/monthly-ticket/bos/transaction/${id}`
    return fetch('get', url, {})
  },

  getTotalSummary(params) {
    const url = '/v3/monthly-ticket/bos/summary'
    return fetch('get', url, params)
  },

  createMonthlyTicket(params) {
    const url = '/v3/monthly-ticket/bos/create'
    return fetch('post', url, params)
  },

  getManageList(params) {
    const url = `/v3/monthly-ticket/bos/list${params ? '/?' + params : ''}`
    return fetch('get', url, {})
  },

  deleteSku(id, data) {
    const url = `/v3/monthly-ticket/bos/delete/${id}`
    return fetch('delete', url, data)
  },

  updateMonthlyTicket(params, id) {
    const url = `/v3/monthly-ticket/bos/update/${id}`
    return fetch('put', url, params)
  },

  getSkuDetail(params) {
    const url = `/v3/monthly-ticket/bos/detail/${params}`
    return fetch('get', url, {})
  }
}

export default monthlyTicketApi
