import { fetch } from '.'

const eInvoiceApi = {
  getEInvoiceList(params) {
    const url = '/v3/bill/status/get-list'
    return fetch('post', url, params)
  },
  getSumaryApi(params) {
    const url = '/v3/bill/status/get-summary'
    return fetch('post', url, params)
  },
  cancelInvoiceApi(billGuid) {
    const url = `/v3/bill/transaction/${billGuid}`
    return fetch('delete', url, null)
  },
  resendInvoiceApi(invoiceStatusId) {
    const url = `/v3/bill/status/sync/${invoiceStatusId}`
    return fetch('post', url, null)
  },
  refreshStatusInvoiceApi(invoiceStatusId) {
    const url = `/v3/bill/status/refresh/${invoiceStatusId}`
    return fetch('post', url, null)
  }
}

export default eInvoiceApi
