import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  loading: false,
  loadingExecute: false,
  selected: [],
  summary: { totalAmount: 0, totalCount: 0 }
}

const eInvoiceSlice = createSlice({
  name: 'eInvoice',
  initialState,
  reducers: {
    getEInvoiceList: (state) => {
      state.loading = true
      state.data = {}
      state.summary = { totalAmount: 0, totalCount: 0 }
    },
    getEInvoiceListDone: (state, action) => {
      state.loading = false
      state.data = action.payload.data
      state.summary = action.payload.summary
    },
    handleExport: (state) => {},
    cancelInvoice: (state) => {},
    resendInvoice: (state) => {},
    setListCheckedBos: (state, action) => {
      state.selected = action.payload
    },
    resendMultipleInvoice: (state) => {},
    refreshStatusInvoice: (state) => {},
    refreshStatusMultipleInvoice: (state) => {}
  }
})

export const eInvoiceActions = eInvoiceSlice.actions

const eInvoiceReducer = eInvoiceSlice.reducer
export default eInvoiceReducer
