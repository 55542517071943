import { call, put, takeLatest } from '@redux-saga/core/effects'
import routeApi from '@ourSrc/api/routeApi'
import { routeActions } from '@ourSrc/store/slices/route.slice'

function* handleGetRoutes({ payload: { enterpriseCode, routeNumber } }) {
  const api = enterpriseCode
    ? routeNumber
      ? routeApi.getRouteByEnterpriseAndRouteNumber
      : routeApi.getRouteByEnterprise
    : routeApi.getRouteList

  try {
    const response = yield call(api, enterpriseCode, routeNumber)
    yield put(routeActions.getRoutesSuccess(response.data))
  } catch (error) {
    yield put(routeActions.getRoutesFailed())
  }
}

export default function* routeSaga() {
  yield takeLatest(routeActions.getRoutes.type, handleGetRoutes)
}
