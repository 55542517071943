import { fetch } from '.'
import { kratosAdmin, findError } from '@ourUtils'

const settingApi = {
  getPermissionListWithRole(param) {
    const url = '/v3/rbac/permission/get-with-role'
    return fetch('get', url, param)
  },
  getPermissionList(param) {
    const url = '/v3/rbac/permission'
    return fetch('get', url, param)
  },
  addNewPermission(param) {
    const url = '/v3/rbac/permission'
    return fetch('post', url, param)
  },
  deletePermission(path) {
    const url = `/v3/rbac/permission/${path}`
    return fetch('delete', url, null)
  },
  getPermissionById(path) {
    const url = `/v3/rbac/permission/${path}`
    return fetch('get', url, null)
  },
  updatePermission(path, param) {
    const url = `/v3/rbac/permission/${path}`
    return fetch('put', url, param)
  },
  getRoleList(param) {
    const url = '/v3/rbac/role'
    return fetch('get', url, param)
  },
  getRoleById(path) {
    const url = `/v3/rbac/role/${path}`
    return fetch('get', url, null)
  },
  addNewRole(param) {
    const url = '/v3/rbac/role'
    return fetch('post', url, param)
  },
  deleteRole(path) {
    const url = `/v3/rbac/role/${path}`
    return fetch('delete', url, null)
  },
  updateRole(path, param) {
    const url = `/v3/rbac/role/${path}`
    return fetch('put', url, param)
  },
  getUserList(param) {
    const url = '/v3/rbac/user'
    return fetch('get', url, param)
  },
  getUserByUserId(path) {
    const url = `/v3/rbac/user/${path}`
    return fetch('get', url, null)
  },
  addNewUser(param) {
    const url = '/v3/rbac/user'
    return fetch('post', url, param)
  },
  deleteUser(path) {
    const url = `/v3/rbac/user/${path}`
    return fetch('delete', url, null)
  },
  updateUser(path, param) {
    const url = `/v3/rbac/user/${path}`
    return fetch('put', url, param)
  },
  async getAllUserFromKratos() {
    try {
      const f = await kratosAdmin.adminListIdentities(500, 0) //max perPage :500 and page: 0 kratos
      return { isSuccess: true, data: f.data }
    } catch (err) {
      console.log(findError(err))
      return { isSuccess: false, message: findError(err).message }
    }
  },
  async createUserFromKratos(params) {
    try {
      const f = await kratosAdmin.adminCreateIdentity({
        schema_id: 'default',
        state: params.state,
        traits: {
          name: {
            first: params.first,
            last: params.last
          },
          company: params.company,
          email: params.email
        }
      })
      return { isSuccess: true, userId: f.data.id }
    } catch (err) {
      let message = ''
      if (err?.response?.data?.error?.code === 409) {
        message = 'Email đã được sử dụng'
      }
      return { isSuccess: false, message }
    }
  },
  async deleteUserFromKratos(userId) {
    try {
      const f = await kratosAdmin.adminDeleteIdentity(userId)
      return { isSuccess: true }
    } catch (err) {
      console.log(findError(err))
      return { isSuccess: false, message: findError(err).message }
    }
  },
  async getUserByUserIdFromKratos(userId) {
    try {
      const f = await kratosAdmin.adminGetIdentity(userId)
      return { isSuccess: true, data: f.data }
    } catch (err) {
      console.log(findError(err))
      return { isSuccess: false, message: findError(err).message }
    }
  },
  async updateUserFromKratos(params) {
    try {
      const f = await kratosAdmin.adminUpdateIdentity(params.id, {
        schema_id: 'default',
        state: params.state,
        traits: {
          name: {
            first: params.first,
            last: params.last
          },
          company: params.company,
          email: params.email
        }
      })
      return { isSuccess: true, userId: f.data.id }
    } catch (err) {
      console.log(findError(err))
      return { isSuccess: false, message: findError(err).message }
    }
  }
}

export default settingApi
