import { createSlice } from '@reduxjs/toolkit'
import { formatVnDate } from '@ourUtils'
const initialState = {
  general: { data: [], loading: false, error: false, total: 0 },
  transactionDetail: { data: [], loading: false, error: false },
  historyDetail: { data: [], loading: false, error: false },
  history: { data: [], loading: false, error: false, total: 0 }
}

const monthlyTicketWithEnterpriseSlice = createSlice({
  name: 'monthlyTicketWithEnterprise',
  initialState,
  reducers: {
    getCenterMonthlyTicketWithEnterpriseGenaralReport: (state) => {
      state.general = { data: [], loading: true, error: false, total: 0 }
    },
    getCenterMonthlyTicketWithEnterpriseGenaralReportSuccess: (state, action) => {
      state.general = { ...state.general, data: action.payload, loading: false }
    },
    getCenterMonthlyTicketWithEnterpriseGenaralReportFailed: (state) => {
      state.general = { ...state.general, loading: false, error: true }
    },
    setCenterMonthlyTicketWithEnterpriseGenaralReportTotal: (state, action) => {
      state.general = { ...state.general, total: action.payload }
    },
    getCenterMonthlyTicketWithEnterpriseGenaralDetail: (state) => {
      state.transactionDetail = { data: [], loading: true, error: false }
    },
    getCenterMonthlyTicketWithEnterpriseGenaralDetailSuccess: (state, action) => {
      state.transactionDetail = { ...state.transactionDetail, data: action.payload, loading: false }
    },
    getCenterMonthlyTicketWithEnterpriseGenaralDetailFailed: (state) => {
      state.transactionDetail = { ...state.transactionDetail, loading: false, error: true }
    },
    confirmPayment: (state, action) => {},
    confirmPaymentSuccess: (state, action) => {
      const formatNewItem = {
        ...action.payload,
        reconcileCode: `#${action?.payload?.reconcileCode}-${action?.payload?.enterpriseCode}`,
        createdDate: formatVnDate(action.payload.createdDate),
        status_message: action.payload.status === 0 ? 'Chờ xử lý' : 'Thành công'
      }
      const updateData = state.general.data
      const findIndexById = updateData.findIndex((item) => item.id === formatNewItem.id)
      updateData[findIndexById] = formatNewItem
      state.general = { ...state.general, data: updateData, loading: false }
    },
    getHistory: (state, action) => {
      state.history = { data: [], loading: true, error: false, total: 0 }
    },
    getHistorySuccess: (state, action) => {
      state.history = { ...state.history, data: action.payload, loading: false }
    },
    setHistoryTotal: (state, action) => {
      state.history = { ...state.history, total: action.payload }
    },
    getHistoryFailed: (state, action) => {
      state.history = { ...state.history, data: [], loading: false, error: true }
    }
  }
})

export const monthlyTicketWithEnterpriseActions = monthlyTicketWithEnterpriseSlice.actions

const monthlyTicketWithEnterpriseReducer = monthlyTicketWithEnterpriseSlice.reducer
export default monthlyTicketWithEnterpriseReducer
