import reportApi from '@ourSrc/api/reportApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import _sumBy from 'lodash/sumBy'
import _groupBy from 'lodash/groupBy'

import { stationReportActions } from './stationReport.slice'
import { apiErrorToast, totalAmoutByPaymentMethod, totalWithCondition } from '@ourUtils'
import { NORMAL_TICKET_ID, PAYMENT_METHOD_E_TICKET } from '@ourSrc/constants/dataType'

const totalAmount = (i, paymentMethod) => {
  const prefix = `total_amount_${paymentMethod}_`
  if (paymentMethod === 4)
    return i[`${prefix}1`] + i[`${prefix}2`] + i[`${prefix}7`] + i[`${prefix}3`]
  else if (paymentMethod === 2)
    return i[`${prefix}4`] + i[`${prefix}5`] + i[`${prefix}9`] + i[`${prefix}6`]
  else if (paymentMethod === 6) return i[`${prefix}4`]
}

const convertData = (data) => {
  return data.map((i) => {
    const { total_ticket, total_amount, ...tmpI } = i

    const newItem = {
      ...tmpI,
      ...total_ticket,
      ...total_amount,
      total_normal_ticket_cash: totalWithCondition(total_ticket, [4], NORMAL_TICKET_ID),
      total_normal_ticket_unipass: totalWithCondition(
        total_ticket,
        PAYMENT_METHOD_E_TICKET,
        NORMAL_TICKET_ID
      )
    }

    newItem.total_ve_thuong = newItem.total_normal_ticket_cash + newItem.total_normal_ticket_unipass

    newItem.total_ticket_discount_e_ticket = totalWithCondition(
      total_ticket,
      PAYMENT_METHOD_E_TICKET,
      [2]
    )

    newItem.total_ticket_free_e_ticket = totalWithCondition(total_ticket, PAYMENT_METHOD_E_TICKET, [
      3
    ])

    newItem.total_ticket_package_e_ticket = totalWithCondition(
      total_ticket,
      PAYMENT_METHOD_E_TICKET,
      [4]
    )

    newItem.total_ve_giam = newItem.total_ticket_discount_e_ticket + newItem.total_ticket_4_2
    newItem.total_ve_mien = newItem.total_ticket_free_e_ticket + newItem.total_ticket_4_3
    newItem.total_ve_tap = newItem.total_ticket_package_e_ticket + newItem.total_ticket_4_4

    newItem.total_amount_ve_dien_tu = totalAmoutByPaymentMethod(1, newItem)
    newItem.total_amount_ve_tien_mat = totalAmoutByPaymentMethod(4, newItem)

    newItem.total_ticket_except_ve_mien = newItem.total_tickets - newItem.total_ve_mien
    return newItem
  })
}

function* handleGetStationReport({ payload }) {
  const { params } = payload

  try {
    const response = yield call(reportApi.getStationReport, params)
    const data = convertData(response.data)
    yield put(stationReportActions.getStationReportSuccess(data))
  } catch (error) {
    console.log(`error`, error)
    apiErrorToast('Error. Please try again')
    yield put(stationReportActions.getStationReportFailed())
  }
}

export default function* stationReportSagac() {
  yield takeLatest(stationReportActions.getStationReport.type, handleGetStationReport)
}
