export const STATUS_VALIDATE_TICKET = {
  1: {
    value: 1,
    label: 'Không lệch',
    colorText: '#6E6B7B'
  },
  2: {
    value: 2,
    label: 'Lệch số liệu',
    color: 'danger',
    backgroundColor: '#EA5455',
    colorText: 'white'
  },
  3: {
    value: 3,
    label: 'Đã chỉnh sửa',
    color: 'success',
    backgroundColor: '#28C76F',
    colorText: 'white'
  },
  4: {
    value: 4,
    label: 'Chấp nhận lệch',
    color: 'warning',
    backgroundColor: '#FF9F43',
    colorText: 'white'
  }
}
export const DEFAULTDATA = [
  {
    key: 'Vé tiền mặt'
  },
  {
    key: 'Vé điện tử'
  },
  {
    key: 'Vé Vietbank'
  },
  {
    key: 'Tổng'
  }
]
