import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  errors: { changePassword: '' }
}

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    changePassword: (state, action) => {
      state.loading = true
    },
    changePasswordSuccess: (state, action) => {
      state.loading = false
    },
    changePasswordFailed: (state) => {
      state.loading = false
      state.errors = {
        ...state.errors,
        changePassword:
          'Vui lòng chọn một mật khẩu có tính bảo mật cao hơn. Hãy thử kết hợp các chữ cái, số và ký hiệu.'
      }
    },
    clearErrorsChangePassword: (state) => {
      state.errors = { ...state.errors, changePassword: '' }
    }
  }
})

export const changePasswordActions = changePasswordSlice.actions

const changePasswordReducer = changePasswordSlice.reducer
export default changePasswordReducer
