import { put, call, takeLatest } from '@redux-saga/core/effects'

import { revenueChartActions } from '../slices/revenueChart.slice'
import reportApi from '@ourSrc/api/reportApi'

const totalByCondition = (data, ticketType) => {
  const paymentMethodETicket = [2, 3, 6, 8, 10, 12, 14]
  if (!data) return 0
  return paymentMethodETicket.reduce((acc, current) => {
    return acc + (data[`total_amount_${current}_${ticketType}`] || 0)
  }, 0)
}

function* handleGetRevenuePieChart({ payload }) {
  const { params } = payload
  params.paymentStatus = 2
  try {
    const response = yield call(reportApi.getHistoryTotal, params)
    let data = [
      totalByCondition(response?.data, 4),
      totalByCondition(response?.data, 5),
      totalByCondition(response?.data, 6),
      totalByCondition(response?.data, 9),
      response.data.total_amount_4_1,
      response.data.total_amount_4_2,
      response.data.total_amount_4_3,
      response.data.total_amount_4_7
    ]

    data = data.map((i) => (!i ? 0 : i))
    data = data.filter((i) => i === 0).length === data.length ? [] : data

    yield put(
      revenueChartActions.getRevenuePieChartSuccess({
        pie: data,
        total: {
          cash: response?.data?.total_amount_cash,
          eTicket: response?.data?.total_amount - response?.data?.total_amount_cash
        }
      })
    )
  } catch (error) {
    yield put(revenueChartActions.getRevenuePieChartFailed())
    console.log('error', error)
  }
}

const convertRevenueBarChartData = (data, year) => {
  return data.map((month, idx) => ({
    name: `T${idx + 1} ${year}`,
    ve_dien_tu: month.total_amount_card,
    ve_tien_mat: month.total_amount_cash,
    total: month.total_amount_card + month.total_amount_cash
  }))
}

function* handleGetRevenueBarChart({ payload }) {
  const { params } = payload
  params.paymentStatus = 2
  try {
    const response = yield call(reportApi.getHistoryTotalByYear, params)
    let data = convertRevenueBarChartData(response.data, params.year || '')
    data = data.filter((i) => i.total !== 0).length !== 0 ? data : []
    yield put(revenueChartActions.getRevenueBarChartSuccess(data))
  } catch (error) {
    yield put(revenueChartActions.getRevenueBarChartFailed())
    console.log('error', error)
  }
}

export default function* ticketChartSaga() {
  yield takeLatest(revenueChartActions.getRevenuePieChart.type, handleGetRevenuePieChart)
  yield takeLatest(revenueChartActions.getRevenueBarChart.type, handleGetRevenueBarChart)
}
