import { put, takeLatest, call } from '@redux-saga/core/effects'
import settingApi from '@ourSrc/api/settingApi'
import { permissionListActions } from './permissionList.slice'
import _ from 'lodash'

function* handleGetPermissionListWithRole() {
  try {
    const response = yield call(settingApi.getPermissionListWithRole, null)
    if (response.data.length !== 0) {
      const states = [
        'light-success',
        'light-danger',
        'light-warning',
        'light-info',
        'light-primary',
        'light-secondary',
        'light-success',
        'light-danger',
        'light-warning',
        'light-info',
        'light-primary',
        'light-secondary'
      ]
      const processed = response.data.reduce((acc, curr) => {
        if (curr.name_role) {
          const find = acc.find((f) => f.name_role === curr.name_role)
          if (find) {
            curr.color = find.color
            acc.push(curr)
          } else {
            curr.color = states.pop()
            acc.push(curr)
          }
        } else {
          acc.push(curr)
        }
        return acc
      }, [])
      const data = _.chain(processed)
        .groupBy('id')
        .map((value, key) => ({
          id: key,
          permissions: value,
          sort: value[0].parent === 0 ? `${value[0].id}` : `${value[0].parent}_p`
        }))
        .sortBy('sort')
        .value()
      yield put(permissionListActions.getPermissionListWithRoleSuccess(data))
    } else yield put(permissionListActions.getPermissionListWithRoleFailed())
  } catch (error) {
    console.log(`error`, error)
    yield put(permissionListActions.getPermissionListWithRoleFailed())
  }
}

function* handleGetPermissionList() {
  try {
    const response = yield call(settingApi.getPermissionList, null)
    if (response.data.length !== 0) {
      const processed = _.chain(response.data)
        .map((value) => {
          value.sort = value.parent === 0 ? `${value.id}` : `${value.parent}_p`
          return value
        })
        .sortBy('sort')
        .value()
      yield put(permissionListActions.getPermissionsSuccess(processed))
    }
  } catch (error) {
    console.log(`error`, error)
  }
}

function* handleAddNewPermission({ payload }) {
  try {
    const response = yield call(settingApi.addNewPermission, payload.params)
    if (response.error.code === 1) {
      yield handleGetPermissionListWithRole()
    }
  } catch (error) {
    console.log(`error`, error)
  }
}

function* handleDeletePermission({ payload }) {
  try {
    const response = yield call(settingApi.deletePermission, payload)
    if (response.error.code === 1) {
      yield handleGetPermissionListWithRole()
    }
  } catch (error) {
    console.log(`error`, error)
  }
}

function* handleUpdatePermission({ payload }) {
  try {
    const response = yield call(settingApi.updatePermission, payload.id, payload.params)
    if (response.error.code === 1) {
      yield put(permissionListActions.updatePermissionSuccess())
      yield handleGetPermissionListWithRole()
    }
  } catch (error) {
    console.log(`error`, error)
  }
}

function* handleInitialUpdatePermission({ payload }) {
  try {
    if (payload.id) {
      const response = yield call(settingApi.getPermissionById, payload.id)
      if (response.data) {
        yield put(permissionListActions.initialUpdatePermissionSuccess(response.data))
      }
    }
  } catch (error) {
    console.log(`error`, error)
  }
}
export default function* permissionListSaga() {
  yield takeLatest(
    permissionListActions.getPermissionListWithRole.type,
    handleGetPermissionListWithRole
  )
  yield takeLatest(permissionListActions.getPermissionList.type, handleGetPermissionList)
  yield takeLatest(permissionListActions.addNewPermission.type, handleAddNewPermission)
  yield takeLatest(permissionListActions.deletePermission.type, handleDeletePermission)
  yield takeLatest(permissionListActions.updatePermission.type, handleUpdatePermission)
  yield takeLatest(
    permissionListActions.initialUpdatePermission.type,
    handleInitialUpdatePermission
  )
}
