import reportApi from '@ourSrc/api/reportApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import { monthlyTicketWithZionActions } from './monthlyTicketWithZion.slice'
import { getLastDayOfMonth } from '@ourSrc/utility/Utils'

function* handleGetCenterMonthlyTicketWithZionGenaralReport({ payload }) {
  const { params } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketCenterGeneralReport, params)
    if (res?.data) {
      const data = res?.data.map((item, key) => {
        const createdDate = item.created_date.split('-')
        item.idx =
          '#' +
          createdDate[1].slice(2, createdDate[1].length) +
          createdDate[0] +
          getLastDayOfMonth(createdDate[1], createdDate[0])
        item.status_message = item.status === 0 ? 'Chờ xử lý' : 'Thành công'
        return item
      })
      yield put(
        monthlyTicketWithZionActions.getCenterMonthlyTicketWithZionGenaralReportSuccess(data)
      )
    } else {
      yield put(monthlyTicketWithZionActions.getCenterMonthlyTicketWithZionGenaralReportFailed())
    }
  } catch (error) {
    console.log('error: ', error)
  }
}
function* handleGetCenterMonthlyTicketWithZionGenaralDetail({ payload }) {
  const { id } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketCenterZionGeneralDetail, id)
    if (res?.data) {
      const createdDate = res?.data.created_date.split('-')
      const formatDataForTable = res.data?.details.map((item) => {
        item.amount = item.totalAmount / item.quantity
        return item
      })
      const data = {
        ...res.data,
        publicDay: `${getLastDayOfMonth(createdDate[1], createdDate[0])}/${createdDate[0]}/${
          createdDate[1]
        }`,
        idx:
          '#' +
          createdDate[1].slice(2, createdDate[1].length) +
          createdDate[0] +
          getLastDayOfMonth(createdDate[1], createdDate[0]),
        status: res?.data?.status === 0 ? 'Chờ xử lý' : 'Thành công',
        details: formatDataForTable
      }
      yield put(
        monthlyTicketWithZionActions.getCenterMonthlyTicketWithZionGenaralDetailSuccess(data)
      )
    } else {
      yield put(monthlyTicketWithZionActions.getCenterMonthlyTicketWithZionGenaralReportFailed())
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

function* handleGetCenterMonthlyTicketWithZionHistoryDetail({ payload }) {
  const { id } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketCenterZionHistoryDetail, id)
    if (res?.data) {
      const formatDataForTable = res.data.map((item) => {
        item.amountPerSku = item.amount / item.quantity
        return item
      })
      yield put(
        monthlyTicketWithZionActions.getCenterMonthlyTicketWithZionHistoryDetailSuccess(
          formatDataForTable
        )
      )
    } else {
      yield put(monthlyTicketWithZionActions.getCenterMonthlyTicketWithZionHistoryDetailFailed())
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

export default function* monthlyTicketWithZionSaga() {
  yield takeLatest(
    monthlyTicketWithZionActions.getCenterMonthlyTicketWithZionGenaralReport.type,
    handleGetCenterMonthlyTicketWithZionGenaralReport
  )
  yield takeLatest(
    monthlyTicketWithZionActions.getCenterMonthlyTicketWithZionGenaralDetail.type,
    handleGetCenterMonthlyTicketWithZionGenaralDetail
  )
  yield takeLatest(
    monthlyTicketWithZionActions.getCenterMonthlyTicketWithZionHistoryDetail.type,
    handleGetCenterMonthlyTicketWithZionHistoryDetail
  )
}
