import { USER_TYPE } from '@ourSrc/constants/queryCustomer'
import { formatVnDate, getAscii } from './Utils'
import { getTextFromURL } from './XML'
import { download } from './FileUtils'
import ReactDOM from 'react-dom'
import QRCode from 'qrcode.react'
import { convertFromFile } from './Base64Utils'
import domToImage from 'dom-to-image'
import sha1 from 'js-sha1'
import moment from 'moment'

const TMP_CARD_REGEX = /^tmp_/

export const isTmpCard = (cardNo) => TMP_CARD_REGEX.test(cardNo)

export const formatCardSeri = (cardSeri) => (cardSeri ? String(cardSeri).padStart(7, '0') : '')

export const cardTypes = Object.values(USER_TYPE)

export const getCardTypeLabel = (value) =>
  cardTypes.find((cardType) => cardType.value === value)?.label

const CARD_XML_URL = {
  card: '/xmls/card/card_2.xml',
  card_with_bg: '/xmls/card/card_background_2.xml',
  card_no_avatar: '/xmls/card/card_no_avatar.xml',
  card_no_avatar_with_bg: '/xmls/card/card_no_avatar_background.xml'
}

const getQRCodeBase64 = async (seri) => {
  const container = document.createElement('div')
  container.style.height = 0
  container.style.overflow = 'auto'

  ReactDOM.render(
    <QRCode
      value={formatCardSeri(seri)}
      size={600}
      style={{
        padding: 50,
        borderRadius: 4
      }}
    />,
    container
  )
  document.body.appendChild(container)

  const file = await domToImage.toBlob(container.firstChild)
  const result = await convertFromFile(file)

  document.body.removeChild(container)
  ReactDOM.unmountComponentAtNode(container)

  return result
}

const getAvatarBase64 = async (avatar, cardType) => {
  if (!avatar) return ''
  if (cardType === 1) return ''
  const blob = await fetch(avatar, {
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Origin: new URL(window.location.href).origin
    }
  }).then((res) => res.blob())
  if (!blob.type.match(/image\/.*/)) return ''

  const url = URL.createObjectURL(blob)

  const container = document.createElement('div')
  container.style.height = 0
  container.style.overflow = 'auto'

  ReactDOM.render(
    <img
      src={url}
      style={{
        width: 272,
        height: 272,
        border: '2px solid rgba(255, 255, 255, 0.6)',
        borderRadius: '50%',
        objectFit: 'cover'
      }}
    />,
    container
  )
  document.body.appendChild(container)

  const file = await domToImage.toBlob(container.firstChild)
  const result = await convertFromFile(file)

  document.body.removeChild(container)
  ReactDOM.unmountComponentAtNode(container)
  URL.revokeObjectURL(url)

  return result
}

export const downloadCardXML = async (data, hasBackground = false) => {
  const [avatar, qrcode] = await Promise.all([
    getAvatarBase64(data.avatar, data.card_type),
    getQRCodeBase64(data.card_seri)
  ])

  let url
  if (avatar) url = CARD_XML_URL[hasBackground ? 'card_with_bg' : 'card']
  else url = CARD_XML_URL[hasBackground ? 'card_no_avatar_with_bg' : 'card_no_avatar']

  const xml = await getTextFromURL(url)

  const result = xml
    .replace('__CustomerFullName', data.customer_name.toUpperCase())
    .replace('__CustomerCardType', getCardTypeLabel(data.card_type))
    .replace('__CustomerCardSeri', formatCardSeri(data.card_seri))
    .replace('__CustomerCardEXP', `EXP: ${formatVnDate(data.expire_date)}`)
    .replace('__CustomerAvatar', avatar)
    .replace('__CustomerQRCode', qrcode)

  const blob = new Blob([result], { type: 'application/xml' })
  const fileName = getAscii(data.customer_name).replace(/\s+/g, '_').toLowerCase().trim() + '.xml'

  download(blob, fileName)
}

export const hashCardNo = (cardNoOrigin) => sha1(cardNoOrigin)

export const genExpireDate = (graduationYear) =>
  graduationYear
    ? moment(graduationYear).endOf('month').format('YYYY-MM-DD')
    : moment().add(5, 'years').format('YYYY-MM-DD')

export const isExpired = (date) => moment().isAfter(date)
