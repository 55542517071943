import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cashInTransaction: {
    data: {},
    loading: false,
    sumary: { totalSucceedTransaction: 0, totalSucceedAmount: 0 }
  },
  refundByUser: {
    data: {},
    loading: false,
    sumary: { totalAmount: 0, totalUser: 0 }
  },
  refundByTransaction: {
    data: {},
    loading: false,
    sumary: { totalTransaction: 0, totalAmount: 0 }
  },
  surplusUser: {
    data: {},
    loading: false
  },
  cashInUser: {
    data: {},
    loading: false
  },
  cashOutUser: {
    data: {},
    loading: false
  },
  cashOutTransaction: {
    data: {},
    loading: false
  },
  currentTabData: {
    reducer: '',
    fileName: '',
    title: '',
    columns: []
  },
  filter: {}
}

const cashFlowSlice = createSlice({
  name: 'cashFlow',
  initialState,
  reducers: {
    getCashInTransaction: (state) => {
      state.cashInTransaction.loading = true
    },
    getCashInTransactionSuccess: (state, action) => {
      state.cashInTransaction.loading = false
      state.cashInTransaction.data = action.payload
    },
    getCashInTransactionFailed: (state) => {
      state.cashInTransaction.loading = false
      state.cashInTransaction.data = {}
    },
    getSumary: (state) => {
      state.cashInTransaction.sumary = { totalSucceedTransaction: 0, totalSucceedAmount: 0 }
    },
    getSumarySuccess: (state, action) => {
      state.cashInTransaction.sumary = action.payload
    },
    //
    getRefundByUser: (state) => {
      state.refundByUser.loading = true
    },
    getRefundByUserSuccess: (state, action) => {
      state.refundByUser.loading = false
      state.refundByUser.data = action.payload
    },
    getRefundByUserFailed: (state) => {
      state.refundByUser.loading = false
      state.refundByUser.data = {}
    },
    getRefundByUserSumary: (state) => {
      state.refundByUser.sumary = { totalSucceedTransaction: 0, totalSucceedAmount: 0 }
    },
    getRefundByUserSumarySuccess: (state, action) => {
      state.refundByUser.sumary = action.payload
    },
    //
    getRefundByTransaction: (state) => {
      state.refundByTransaction.loading = true
    },
    getRefundByTransactionSuccess: (state, action) => {
      state.refundByTransaction.loading = false
      state.refundByTransaction.data = action.payload
    },
    getRefundByTransactionFailed: (state) => {
      state.refundByTransaction.loading = false
      state.refundByTransaction.data = {}
    },
    getRefundByTransactionSumary: (state) => {
      state.refundByTransaction.sumary = { totalSucceedTransaction: 0, totalSucceedAmount: 0 }
    },
    getRefundByTransactionSumarySuccess: (state, action) => {
      state.refundByTransaction.sumary = action.payload
    },
    //
    getSurplusUserList: (state) => {
      state.surplusUser.loading = true
    },
    getSurplusUserListSuccess: (state, action) => {
      state.surplusUser.loading = false
      state.surplusUser.data = action.payload
    },
    getSurplusUserListFailed: (state) => {
      state.surplusUser.loading = false
      state.surplusUser.data = {}
    },
    getCashInUserList: (state) => {
      state.cashInUser.loading = true
    },
    getCashInUserListSuccess: (state, action) => {
      state.cashInUser.loading = false
      state.cashInUser.data = action.payload
    },
    getCashInUserListFailed: (state) => {
      state.cashInUser.loading = false
      state.cashInUser.data = {}
    },
    getCashOutUserList: (state) => {
      state.cashOutUser.loading = true
    },
    getCashOutUserListSuccess: (state, action) => {
      state.cashOutUser.loading = false
      state.cashOutUser.data = action.payload
    },
    getCashOutUserListFailed: (state) => {
      state.cashOutUser.loading = false
      state.cashOutUser.data = {}
    },
    getCashOutTransactionList: (state) => {
      state.cashOutTransaction.loading = true
    },
    getCashOutTransactionListSuccess: (state, action) => {
      state.cashOutTransaction.loading = false
      state.cashOutTransaction.data = action.payload
    },
    getCashOutTransactionListFailed: (state) => {
      state.cashOutTransaction.loading = false
      state.cashOutTransaction.data = {}
    },
    setCurrentTab: (state, action) => {
      state.currentTabData = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    exportExcel: (state) => {}
  }
})

export const cashFlowActions = cashFlowSlice.actions

const cashFlowReducer = cashFlowSlice.reducer
export default cashFlowReducer
