import { call, put, select, takeLatest } from '@redux-saga/core/effects'
import _cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import reportApi from '@ourSrc/api/reportApi'
import { apiErrorToast, totalAmoutByPaymentMethod, totalWithCondition } from '@ourSrc/utility/Utils'
import { carReportActions } from './carReport.slice'
import { NORMAL_TICKET_ID, PAYMENT_METHOD_E_TICKET } from '@ourSrc/constants/dataType'

const convertData = (data, keyField, index) => {
  return data.map((i) => {
    const {
      created_date,
      car_number,
      car_id,
      date_time_start,
      date_time_finish,
      station_name_start,
      turn_number,

      total_turn,
      total_ticket,
      total_tickets,
      total_amount,
      total_amounts
    } = i

    const newItem = {
      ...total_ticket,
      ...total_amount,
      total_turn,
      total_tickets,
      total_amounts,
      created_date,
      total_normal_ticket_cash: totalWithCondition(total_ticket, [4], NORMAL_TICKET_ID),
      total_normal_amount_cash: totalWithCondition(total_amount, [4], NORMAL_TICKET_ID, 'amount')
    }

    newItem.total_normal_amount_unipass = totalWithCondition(
      total_amount,
      PAYMENT_METHOD_E_TICKET,
      NORMAL_TICKET_ID,
      'amount'
    )

    newItem.total_ticket_normal_e_ticket = totalWithCondition(
      total_ticket,
      PAYMENT_METHOD_E_TICKET,
      NORMAL_TICKET_ID
    )

    newItem.total_amount_discount_e_ticket = totalWithCondition(
      total_amount,
      PAYMENT_METHOD_E_TICKET,
      [2],
      'amount'
    )

    newItem.total_ticket_discount_e_ticket = totalWithCondition(
      total_ticket,
      PAYMENT_METHOD_E_TICKET,
      [2]
    )

    newItem.total_ticket_free_e_ticket = totalWithCondition(total_ticket, PAYMENT_METHOD_E_TICKET, [
      3
    ])

    newItem.total_ticket_package_e_ticket = totalWithCondition(
      total_ticket,
      PAYMENT_METHOD_E_TICKET,
      [4]
    )

    newItem.total_ticket_ve_dien_tu =
      newItem.total_ticket_normal_e_ticket +
      newItem.total_ticket_discount_e_ticket +
      newItem.total_ticket_free_e_ticket +
      newItem.total_ticket_package_e_ticket

    newItem.total_ticket_ve_tien_mat =
      newItem.total_normal_ticket_cash +
      newItem.total_ticket_4_2 +
      newItem.total_ticket_4_3 +
      newItem.total_ticket_4_4

    newItem.total_amount_ve_dien_tu =
      newItem?.total_normal_amount_unipass + newItem?.total_amount_discount_e_ticket

    newItem.total_amount_ve_tien_mat = totalAmoutByPaymentMethod(4, newItem)

    if (typeof index === 'number') newItem.parentIndex = index

    if (keyField === 'created_date') newItem.key = moment(created_date).format('DD/MM/YYYY')
    else if (keyField === 'car_number') {
      newItem.key = car_number
      newItem.car_id = car_id
    } else if (keyField === 'turn') {
      newItem.key = `Chuyến ${turn_number}`
      newItem.station_name_start = station_name_start
      newItem.station_start_time = date_time_start ? moment(date_time_start).format('HH:mm') : null
      newItem.station_finish_time = date_time_finish
        ? moment(date_time_finish).format('HH:mm')
        : null
    }
    return newItem
  })
}

function* handleGetRootData({ payload }) {
  const { params } = payload
  try {
    const response = yield call(reportApi.getCarReportByMonth, params)

    let data = response.data.sort((a, b) => {
      if (moment(a.created_date) > moment(b.created_date)) return 1
      else if (moment(a.created_date) < moment(b.created_date)) return -1
      return 0
    })

    data = convertData(data, 'created_date')
    yield put(carReportActions.getRootDataSuccess(data))
  } catch (error) {}
}

function* handleGetLv1Data({ payload }) {
  const carReport = yield select((state) => state.carReport)
  const { params, index } = payload

  try {
    const response = yield call(reportApi.getCarReportByMonthAndCar, params)
    const data = convertData(response.data, 'car_number', index)

    if (data.length === 0) {
      apiErrorToast(
        `Không tìm thấy dữ liệu vào ngày ${moment(new Date(params.fromDate)).format('DD/MM/YYYY')}`
      )
    }

    const carReportNewData = _cloneDeep(carReport.data)
    carReportNewData[index].expand = data.length === 0 ? null : data
    yield put(carReportActions.getLv1DataSuccess(carReportNewData))
  } catch (error) {
    yield put(carReportActions.getLv1DataFailed())
  }
}

function* handleGetLv2Data({ payload }) {
  const carReport = yield select((state) => state.carReport)
  const { lv0Idx, lv1Idx, params, key } = payload

  try {
    const response = yield call(reportApi.getTurnReport, params)
    console.log('response', response)
    const data = convertData(response.data, 'turn')

    if (data.length === 0) {
      apiErrorToast(
        `Không tìm thấy dữ liệu vào ngày ${moment(new Date(params.fromDate)).format(
          'DD/MM/YYYY'
        )} của xe ${key}`
      )
    }

    const carReportNewData = _cloneDeep(carReport.data)

    carReportNewData[lv0Idx].expand[lv1Idx] = {
      ...carReportNewData[lv0Idx].expand[lv1Idx],
      expand: data.length === 0 ? null : data
    }

    yield put(carReportActions.getLv2DataSuccess(carReportNewData))
  } catch (error) {
    yield put(carReportActions.getLv2DataFailed())
  }
}

export default function* carReportSaga() {
  yield takeLatest(carReportActions.getRootData.type, handleGetRootData)
  yield takeLatest(carReportActions.getLv1Data.type, handleGetLv1Data)
  yield takeLatest(carReportActions.getLv2Data.type, handleGetLv2Data)
}
