import { createSlice } from '@reduxjs/toolkit'
import { getLastDayOfMonth } from '@ourUtils'
const initialState = {
  general: { data: [], loading: false, error: false, total: 0 },
  transactionDetail: { data: [], loading: false, error: false },
  historyDetail: { data: [], loading: false, error: false }
}

const monthlyTicketAccountingSlice = createSlice({
  name: 'monthlyTicketAccounting',
  initialState,
  reducers: {
    getMonthlyTicketAccountingGenaralReport: (state) => {
      state.general = { data: [], loading: true, error: false, total: 0 }
    },
    getMonthlyTicketAccountingGenaralReportSuccess: (state, action) => {
      state.general = { ...state.general, data: action.payload, loading: false }
    },
    getMonthlyTicketAccountingGenaralReportFailed: (state) => {
      state.general = { ...state.general, loading: false, error: true }
    },
    // setMonthlyTicketAccountingGenaralReportTotal: (state, action) => {
    //   state.general = { ...state.general, total: action.payload }
    // },
    getMonthlyTicketAccountingGenaralDetail: (state) => {
      state.transactionDetail = { data: [], loading: true, error: false }
    },
    getMonthlyTicketAccountingGenaralDetailSuccess: (state, action) => {
      state.transactionDetail = { ...state.transactionDetail, data: action.payload, loading: false }
    },
    getMonthlyTicketAccountingGenaralDetailFailed: (state) => {
      state.transactionDetail = { ...state.transactionDetail, loading: false, error: true }
    },
    getMonthlyTicketAccountingHistoryDetail: (state) => {
      state.historyDetail = { data: [], loading: true, error: false }
    },
    getMonthlyTicketAccountingHistoryDetailSuccess: (state, action) => {
      state.historyDetail = { ...state.historyDetail, data: action.payload, loading: false }
    },
    getMonthlyTicketAccountingHistoryDetailFailed: (state) => {
      state.historyDetail = { ...state.historyDetail, loading: false, error: true }
    },
    setDiscountAndTax: (state, action) => {},
    setDiscountAndTaxSuccess: (state, action) => {
      const createdDate = action.payload.created_date.split('-')
      const formatNewItem = {
        ...action.payload,
        status_message: action.payload.status === 0 ? 'Chờ xử lý' : 'Thành công',
        idx:
          '#' +
          createdDate[1].slice(2, createdDate[1].length) +
          createdDate[0] +
          getLastDayOfMonth(createdDate[1], createdDate[0])
      }

      const updateData = state.general.data
      const findIndexById = updateData.findIndex((item) => item.id === formatNewItem.id)
      updateData[findIndexById] = formatNewItem
      state.general = { ...state.general, data: updateData, loading: false }
    },
    confirmTransferTransaction: (state, action) => {},
    confirmTransferTransactionSuccess: (state, action) => {
      const createdDate = action.payload.created_date.split('-')
      const formatNewItem = {
        ...action.payload,
        status_message: action.payload.status === 0 ? 'Chờ xử lý' : 'Thành công',
        idx:
          '#' +
          createdDate[1].slice(2, createdDate[1].length) +
          createdDate[0] +
          getLastDayOfMonth(createdDate[1], createdDate[0])
      }

      const updateData = state.general.data
      const findIndexById = updateData.findIndex((item) => item.id === formatNewItem.id)
      updateData[findIndexById] = formatNewItem
      state.general = { ...state.general, data: updateData, loading: false }
    }
  }
})

export const monthlyTicketAccountingActions = monthlyTicketAccountingSlice.actions

const monthlyTicketAccountingReducer = monthlyTicketAccountingSlice.reducer
export default monthlyTicketAccountingReducer
