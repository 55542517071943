import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  suggestions: [],
  bookmarks: [],
  query: ''
}

const navSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    handleSearchQuery: (state, action) => {
      state.query = action.val
    },

    getBookmarks: (state, action) => {
      console.log('123')
      state.suggestions = action.data
      state.bookmarks = [123]
    },

    updateBookmarked: (state, action) => {
      let objectToUpdate

      // ** find & update object
      state.suggestions.find((item) => {
        if (item.id === action.id) {
          item.isBookmarked = !item.isBookmarked
          objectToUpdate = item
        }
      })

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex((x) => x.id === action.id)

      if (bookmarkIndex === -1) state.bookmarks.push(objectToUpdate)
      else state.bookmarks.splice(bookmarkIndex, 1)
    }
  }
})

export const navActions = navSlice.actions

const navReducer = navSlice.reducer
export default navReducer
