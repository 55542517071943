export const TICKET_TYPE = {
  1: {
    value: 1, // 4_1
    name: 'Vé thường',
    label: 've_pho_thong'
  },
  2: {
    value: 2, // 4_2
    name: 'Vé giảm',
    label: 've_giam'
  },
  3: {
    value: 3, // 4_3
    name: 'Vé miễn',
    label: 've_mien'
  },
  4: {
    value: 4, // 2_4
    name: 'Vé phổ thông điện tử',
    label: 've_pho_thong_dien_tu'
  },
  5: {
    value: 5, // 2_5
    name: 'Vé giảm điện tử',
    label: 've_giam_dien_tu'
  },
  6: {
    value: 6, // 2_6
    name: 'Vé miễn điện tử',
    label: 've_mien_dien_tu'
  },
  7: {
    value: 7, // 4_7
    name: 'Vé tập',
    label: 've_tap'
  },
  8: {
    value: 8,
    name: 'Vé vé kiểm soát',
    label: 've_kiem_soat'
  },
  9: {
    value: 9, // 2_9
    name: 'Vé tập điện tử',
    label: 've_tap_dien_tu'
  }
}
