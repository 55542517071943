import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  loading: false,
  error: false,
  isOpenModal: false,
  role: {}
}

const roleListSlice = createSlice({
  name: 'roleList',
  initialState,
  reducers: {
    getRoleList: (state) => {
      state.loading = true
      state.error = false
      state.data = []
    },

    getRoleListSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },

    getRoleListFailed: (state) => {
      state.loading = false
      state.error = true
      state.data = []
    },
    getRoleByIdSuccess: (state, action) => {
      state.loading = false
      state.role = action.payload
    },
    addNewRole: (state, action) => {
      state.error = false
      state.isOpenModal = !state.isOpenModal
    },
    deleteRole: (state, action) => {},

    updateRole: (state, action) => {},
    updateRoleSuccess: (state, action) => {
      state.isOpenModal = false
    },
    initialUpdateRole: (state, action) => {
      state.isOpenModal = action.payload.isOpenModal
    }
  }
})

export const roleListActions = roleListSlice.actions

const roleListReducer = roleListSlice.reducer
export default roleListReducer
