import { toast, Slide } from 'react-toastify'
import { Bell } from 'react-feather'
import Avatar from '@components/avatar'
import moment from 'moment'
import { Configuration, V0alpha2Api } from '@ory/kratos-client'
import {
  REACT_APP_DASHBOARD_KRATOS_URL,
  REACT_APP_DASHBOARD_KRATOS_ADMIN_URL
} from '../constants/env'
import _ from 'lodash'
import React, { Fragment } from 'react'

import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState } from 'draft-js'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const getUserData = () => JSON.parse(localStorage.getItem('user'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

const TOAST_OPTIONS = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

export const showToast = (type, msg, opts = TOAST_OPTIONS) => {
  const show = toast[type]
  if (show) show(msg, opts)
  else console.error(`${type} is not a valid toast's method`)
}

// ** toast alert api error
export const apiErrorToast = (msg = 'Error. Please try again') => {
  toast.error(msg, TOAST_OPTIONS)
}

// ** toast alert api success
export const successToast = (title, msg) => {
  toast.success(
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color="success" icon={<Bell size={12} />} />
          <h6 className="toast-title font-weight-bold">{title}</h6>
        </div>
      </div>
      <div className="toastify-body">
        <span>{msg}</span>
      </div>
    </Fragment>,
    {
      transition: Slide,
      hideProgressBar: true,
      autoClose: 5000
    }
  )
}

// ** toast alert api custome
export const customToast = (props) => {
  const { Icon = <Bell size={12} />, title, msg } = props
  toast.success(
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color="success" icon={Icon} />
          <h6 className="toast-title font-weight-bold">{title}</h6>
        </div>
      </div>
      <div className="toastify-body">{msg}</div>
    </Fragment>,
    {
      transition: Slide,
      hideProgressBar: true,
      autoClose: 100000000,
      style: {
        width: 400,
        marginLeft: -90
      }
    }
  )
}

// ** toast alert api error
export const errorToast = (title, msg) => {
  toast.error(
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color="danger" icon={<Bell size={12} />} />
          <h6 className="toast-title font-weight-bold">{title}</h6>
        </div>
      </div>
      <div className="toastify-body">
        <span>{msg}</span>
      </div>
    </Fragment>,
    {
      transition: Slide,
      hideProgressBar: true,
      autoClose: 5000
    }
  )
}
// ** toast alert api error
export const warningToast = (title, msg) => {
  toast.warning(
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color="warning" icon={<Bell size={12} />} />
          <h6 className="toast-title font-weight-bold">{title}</h6>
        </div>
      </div>
      <div className="toastify-body">
        <span>{msg}</span>
      </div>
    </Fragment>,
    {
      transition: Slide,
      hideProgressBar: true,
      autoClose: 5000
    }
  )
}

// ** format currency
export const currencyFormat = (val) => {
  return new Intl.NumberFormat('vi-VN', {}).format(val)
}

// ** get start and end of week
export const getCurrentWeekStartEnd = () => {
  const today = moment()
  const startWeek = today.startOf('isoWeek').toDate()
  const endWeek = today.endOf('isoWeek').toDate()
  return { startWeek, endWeek }
}

export const getCurrentMonthStartEnd = () => {
  const today = moment()
  const startMonth = today.startOf('month').toDate()
  const endMonth = today.endOf('month').toDate()
  return { startMonth, endMonth }
}

export const getSevenDaysBeforeToday = () => {
  const today = moment()
  const toDate = today.endOf('date').toDate()
  const fromDate = today.subtract(7, 'day').startOf('date').toDate()
  return { fromDate, toDate }
}

// ** config kratos
export const kratos = new V0alpha2Api(
  new Configuration({
    basePath: REACT_APP_DASHBOARD_KRATOS_URL
  })
)

export const kratosAdmin = new V0alpha2Api(
  new Configuration({
    basePath: REACT_APP_DASHBOARD_KRATOS_ADMIN_URL
  })
)

export const findCsrfToken = (data) => {
  for (const n of data.ui.nodes) {
    if (n.type === 'input' && n.attributes.name === 'csrf_token') {
      return n.attributes.value
    }
  }
  return ''
}
export const findError = (error) => {
  const ui = error.response.data.ui
  let msg = error.message
  if (ui?.messages) {
    msg = ui.messages[0].text
  } else if (ui?.nodes) {
    for (const n of ui.nodes) {
      if (n.messages && n.messages.length) {
        msg = n.messages[0].text
        break
      }
    }
  }
  return { name: error.name, message: msg }
}

export const formatVnDate = (date) => moment(date).format('DD-MM-YYYY')

export const formatVnFullDate = (date) => {
  if (!date) return ''
  return moment(date).format('DD/MM/YYYY - HH:mm')
}

export const getAscii = (string) =>
  typeof string.normalize !== 'undefined'
    ? string
        .normalize('NFD')
        .replaceAll('Đ', 'D')
        .replaceAll('đ', 'd')
        .replace(/[\u0300-\u036f]/g, '')
    : string

export const isFile = (file) => file instanceof File

export const getResourceURL = (path) =>
  path ? new URL(path, 'https://admin.unipass.vn').toString() : path

export const formatVnTime = (time) => moment(time).format('DD-MM-YYYY HH:mm:ss')
export const pickFiles = (obj) => _.pickBy(obj, isFile)
export const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
export const paymentTransactionCodeRegExp = /^[A-Za-z0-9,.'-]+$/i
export const getLastDayOfMonth = (y, m) => {
  return new Date(y, m + 1, 0).getDate()
}
export const yearsGeneration = () => {
  const year = new Date().getFullYear()
  const years = [year - 2, year - 1, year, year + 1, year + 2]
  const data = { data: years.map((i) => ({ value: i, label: i })) }
  return data
}
export const diffMinutes = (date, otherDate) => {
  return parseInt((Math.abs(otherDate.getTime() - date.getTime()) / (1000 * 60)) % 60)
}

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
export const camelToSnakeCaseForObject = (obj) => {
  if (typeof obj !== 'object') return obj

  for (const oldName in obj) {
    const newName = oldName.replace(/([A-Z])/g, function ($1) {
      return '_' + $1.toLowerCase()
    })

    if (newName !== oldName) {
      if (obj.hasOwnProperty(oldName)) {
        obj[newName] = obj[oldName]
        delete obj[oldName]
      }
    }

    // Recursion
    if (typeof obj[newName] === 'object') {
      obj[newName] = camelToSnakeCaseForObject(obj[newName])
    }
  }
  return obj
}
export const snakeCaseToCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}
export const snakeCaseToCamelForObject = (obj) => {
  if (typeof obj !== 'object') return obj

  for (const oldName in obj) {
    const newName = snakeCaseToCamel(oldName)
    if (newName !== oldName) {
      if (obj.hasOwnProperty(oldName)) {
        obj[newName] = obj[oldName]
        delete obj[oldName]
      }
    }
    // Recursion
    if (typeof obj[newName] === 'object') {
      obj[newName] = snakeCaseToCamelForObject(obj[newName])
    }
  }
  return obj
}
export const addLeadingZeros = (num, totalLength) => {
  return String(num).padStart(totalLength, '0')
}

export const formatDataSearch = (data) => {
  const filters = {}
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      if (key === 'date') {
        if (value?.length !== 0) {
          filters.fromDate = value[0].toISOString()
          filters.toDate = moment(value[value.length - 1])
            .endOf('date')
            .toISOString()
        }
      } else if (key === 'dateTime') {
        if (value?.length !== 0) {
          filters.fromDate = value[0].toISOString()
          filters.toDate = moment(value[value.length - 1]).toISOString()
        }
      } else if (key === 'enterprise') {
        if (value instanceof Array) {
          filters.enterpriseCodes = value.map((i) => i.value)
        } else {
          filters.enterpriseCode = value?.code ?? value?.value
        }
      } else if (key === 'route') {
        filters.routeIds = value?.routeIds
        filters.routeNumber = value?.value
      } else {
        filters[key] =
          value instanceof Object
            ? Array.isArray(value)
              ? value.map((m) => m?.value)
              : value.value
            : value
      }
    }
  }
  return filters
}

export const formatFormData = (data) => {
  const filters = {}
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      filters[key] = value instanceof Object ? value?.value : value
    }
  }
  return filters
}

export const convertHtmlToDraft = (html) => {
  if (html) {
    const contentBlock = htmlToDraft(html)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)
      return editorState
    }
  }
  return null
}

export const isAsyncFunction = (_function) => {
  return _function?.constructor?.name === 'AsyncFunction'
}
export const totalAmoutByPaymentMethod = (_key, _data) => {
  const filterData = Object.keys(_data)
    .filter((key) => key.includes(`total_amount_${_key}`))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: _data[key] })
    }, {})

  return _.isEmpty(filterData)
    ? 0
    : Object.values(filterData)?.reduce((total, value) => total + value)
}

export const currentPath = window.location.pathname + window.location.hash

export const isObj = (obj) => obj && typeof obj === 'object' && !Array.isArray(obj)

export const deepMerge = (obj1, obj2) => {
  if (!isObj(obj1) || !isObj(obj2)) return obj2

  const obj = { ...obj1 }

  for (const [key, value2] of Object.entries(obj2)) {
    const value1 = obj1[key]
    obj[key] = deepMerge(value1, value2)
  }

  return obj
}

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

export const totalWithCondition = (data, methodIds = [], ticketTypes = [], type = 'ticket') => {
  if (!data || typeof data !== 'object') return 0
  return methodIds.reduce((acc, currentMethod) => {
    return (
      acc +
      ticketTypes.reduce((accTicket, currentTicket) => {
        const key = `total_${type}_${currentMethod}_${currentTicket}`
        return accTicket + (data[key] || 0)
      }, 0)
    )
  }, 0)
}

export const isLatitude = (num) => Number(num) && isFinite(num) && Math.abs(num) <= 90
export const isLongitude = (num) => Number(num) && isFinite(num) && Math.abs(num) <= 180
