import reportApi from '@ourSrc/api/reportApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import { monthlyTicketAccountingActions } from './monthlyTicketAccounting.slice'
import { getLastDayOfMonth, errorToast, successToast } from '@ourSrc/utility/Utils'

function* handleGetMonthlyTicketAccountingGenaralReport({ payload }) {
  const { params } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketCenterGeneralReport, params)
    if (res?.data) {
      const data = res?.data.map((item, key) => {
        const createdDate = item.created_date.split('-')
        item.idx =
          '#' +
          createdDate[1].slice(2, createdDate[1].length) +
          createdDate[0] +
          getLastDayOfMonth(createdDate[1], createdDate[0])
        item.status_message = item.status === 0 ? 'Chờ xử lý' : 'Thành công'
        return item
      })
      yield put(monthlyTicketAccountingActions.getMonthlyTicketAccountingGenaralReportSuccess(data))
    } else {
      yield put(monthlyTicketAccountingActions.getMonthlyTicketAccountingGenaralReportFailed())
    }
  } catch (error) {
    console.log('error: ', error)
  }
}
function* handleGetMonthlyTicketAccountingGenaralDetail({ payload }) {
  const { id } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketCenterZionGeneralDetail, id)
    if (res?.data) {
      const createdDate = res?.data.created_date.split('-')
      const formatDataForTable = res.data?.details.map((item) => {
        item.amount = item.totalAmount / item.quantity
        return item
      })
      const data = {
        ...res.data,
        publicDay: `${getLastDayOfMonth(createdDate[1], createdDate[0])}/${createdDate[0]}/${
          createdDate[1]
        }`,
        idx:
          '#' +
          createdDate[1].slice(2, createdDate[1].length) +
          createdDate[0] +
          getLastDayOfMonth(createdDate[1], createdDate[0]),
        status: res?.data?.status === 0 ? 'Chờ xử lý' : 'Thành công',
        details: formatDataForTable
      }
      yield put(monthlyTicketAccountingActions.getMonthlyTicketAccountingGenaralDetailSuccess(data))
    } else {
      yield put(monthlyTicketAccountingActions.getMonthlyTicketAccountingGenaralReportFailed())
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

function* handleGetMonthlyTicketAccountingHistoryDetail({ payload }) {
  const { id } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketCenterZionHistoryDetail, id)
    if (res?.data) {
      const formatDataForTable = res.data.map((item) => {
        item.amountPerSku = item.amount / item.quantity
        return item
      })
      yield put(
        monthlyTicketAccountingActions.getMonthlyTicketAccountingHistoryDetailSuccess(
          formatDataForTable
        )
      )
    } else {
      yield put(monthlyTicketAccountingActions.getMonthlyTicketAccountingHistoryDetailFailed())
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

function* handleSetDiscountAndTax({ payload }) {
  const { id, data, onSuccess } = payload
  try {
    const res = yield call(reportApi.updateDiscountAndTax, id, data)
    if (res?.data) {
      onSuccess()
      successToast('Khấu trừ - thuế thành công', 'Đã xác nhận khấu trừ - thuế thành công.')
      yield put(monthlyTicketAccountingActions.setDiscountAndTaxSuccess(res?.data))
    } else {
      errorToast('Khấu trừ - thuế thất bại', res?.error.messageVN)
    }
  } catch (error) {
    errorToast(
      'Khấu trừ - thuế thất bại',
      'Đã có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên để được hỗ trợ.'
    )
    console.log('error: ', error)
  }
}

function* handleConfirmTransferTransaction({ payload }) {
  const { id, data, onSuccess } = payload
  try {
    const res = yield call(reportApi.confirmTransferTransaction, id, data)
    if (res?.data) {
      onSuccess()
      successToast('Khấu trừ - thuế thành công', 'Đã xác nhận khấu trừ - thuế thành công.')
      yield put(monthlyTicketAccountingActions.confirmTransferTransactionSuccess(res?.data))
      yield handleGetMonthlyTicketAccountingGenaralDetail({ payload: { id } })
    } else {
    }
  } catch (error) {
    errorToast(
      'Khấu trừ - thuế thất bại',
      'Đã có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên để được hỗ trợ.'
    )
    console.log('error: ', error)
  }
}

export default function* monthlyTicketAccounting() {
  yield takeLatest(
    monthlyTicketAccountingActions.getMonthlyTicketAccountingGenaralReport.type,
    handleGetMonthlyTicketAccountingGenaralReport
  )
  yield takeLatest(
    monthlyTicketAccountingActions.getMonthlyTicketAccountingGenaralDetail.type,
    handleGetMonthlyTicketAccountingGenaralDetail
  )
  yield takeLatest(
    monthlyTicketAccountingActions.getMonthlyTicketAccountingHistoryDetail.type,
    handleGetMonthlyTicketAccountingHistoryDetail
  )
  yield takeLatest(monthlyTicketAccountingActions.setDiscountAndTax.type, handleSetDiscountAndTax)
  yield takeLatest(
    monthlyTicketAccountingActions.confirmTransferTransaction.type,
    handleConfirmTransferTransaction
  )
}
