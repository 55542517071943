import { fetch } from '.'

export const cashInTransactionApi = {
  getCashInTransactionApi(params) {
    const url = '/v3/unipass-wallet/bos/cashin-transactions'
    return fetch('post', url, params)
  },
  getSumary(body) {
    const url = '/v3/unipass-wallet/bos/cashin-transaction-summary'
    return fetch('post', url, body)
  }
}

export const surplusUserApi = {
  getSurplusUserList(params) {
    const url = '/v3/unipass-wallet/bos/balances'
    return fetch('post', url, params)
  }
}

export const cashInUserApi = {
  getCashInUserList(params) {
    const url = '/v3/unipass-wallet/bos/cashin-customers'
    return fetch('post', url, params)
  }
}

export const cashOutUserApi = {
  getCashOutUserList(params) {
    const url = '/v3/unipass-wallet/bos/cashout-customers'
    return fetch('post', url, params)
  }
}

export const cashOutTransactionApi = {
  getCashOutTransactionList(params) {
    const url = '/v3/unipass-wallet/bos/cashout-transactions'
    return fetch('post', url, params)
  }
}

export const refundByTransactionApi = {
  getRefundByTransactionApi(params) {
    const url = '/v3/unipass-wallet/refund/get-list'
    return fetch('post', url, params)
  },
  getRefundByTransactionSumaryApi(body) {
    const url = '/v3/unipass-wallet/refund/get-summary'
    return fetch('post', url, body)
  }
}

export const refundByUserApi = {
  getRefundByUserApi(params) {
    const url = '/v3/unipass-wallet/refund/customer/get-list'
    return fetch('post', url, params)
  },
  getRefundByUserSumaryApi(body) {
    const url = '/v3/unipass-wallet/refund/customer/get-summary'
    return fetch('post', url, body)
  }
}
