import reportApi from '@ourSrc/api/reportApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import _sumBy from 'lodash/sumBy'
import _groupBy from 'lodash/groupBy'

import { routeReportActions } from './routeReport.slice'
import { apiErrorToast } from '@ourUtils'

const convertRouteReportData = (data) => {
  return data.map((i, idx) => {
    const {
      route_name,
      route_number,
      total_car,
      total_turn,
      total_amounts,
      total_ticket,
      total_tickets
    } = i

    const newItem = {
      route_name,
      route_number,
      total_car,
      total_turn,
      total_tickets,
      total_amounts,
      index: idx + 1,
      route_direction: 'Cả hai',
      ...total_ticket,
      //{method-ticketTypeId}
      total_normal_ticket_cash:
        total_ticket.total_ticket_4_1 +
        total_ticket.total_ticket_4_11 +
        total_ticket.total_ticket_4_12 +
        total_ticket.total_ticket_4_13 +
        total_ticket.total_ticket_4_14 +
        total_ticket.total_ticket_4_15 +
        total_ticket.total_ticket_4_16
    }
    newItem.total_normal_amount_e_ticket =
      newItem?.total_amount_1_1 +
      newItem?.total_amount_1_11 +
      newItem?.total_amount_1_12 +
      newItem?.total_amount_1_13 +
      newItem?.total_amount_1_14 +
      newItem?.total_amount_1_15 +
      newItem?.total_amount_1_16 +
      newItem?.total_amount_2_1 +
      newItem?.total_amount_2_11 +
      newItem?.total_amount_2_12 +
      newItem?.total_amount_2_13 +
      newItem?.total_amount_2_14 +
      newItem?.total_amount_2_15 +
      newItem?.total_amount_2_16 +
      newItem?.total_amount_5_1 +
      newItem?.total_amount_5_11 +
      newItem?.total_amount_5_12 +
      newItem?.total_amount_5_13 +
      newItem?.total_amount_5_14 +
      newItem?.total_amount_5_15 +
      newItem?.total_amount_5_16 +
      newItem?.total_amount_6_1 +
      newItem?.total_amount_6_11 +
      newItem?.total_amount_6_12 +
      newItem?.total_amount_6_13 +
      newItem?.total_amount_6_14 +
      newItem?.total_amount_6_15 +
      newItem?.total_amount_6_16 +
      newItem?.total_amount_7_1 +
      newItem?.total_amount_7_11 +
      newItem?.total_amount_7_12 +
      newItem?.total_amount_7_13 +
      newItem?.total_amount_7_14 +
      newItem?.total_amount_7_15 +
      newItem?.total_amount_7_16

    newItem.total_ticket_normal_e_ticket =
      newItem?.total_ticket_1_1 +
      newItem?.total_ticket_1_11 +
      newItem?.total_ticket_1_12 +
      newItem?.total_ticket_1_13 +
      newItem?.total_ticket_1_14 +
      newItem?.total_ticket_1_15 +
      newItem?.total_ticket_1_16 +
      newItem?.total_ticket_2_1 +
      newItem?.total_ticket_2_11 +
      newItem?.total_ticket_2_12 +
      newItem?.total_ticket_2_13 +
      newItem?.total_ticket_2_14 +
      newItem?.total_ticket_2_15 +
      newItem?.total_ticket_2_16 +
      newItem?.total_ticket_5_1 +
      newItem?.total_ticket_5_11 +
      newItem?.total_ticket_5_12 +
      newItem?.total_ticket_5_13 +
      newItem?.total_ticket_5_14 +
      newItem?.total_ticket_5_15 +
      newItem?.total_ticket_5_16 +
      newItem?.total_ticket_6_1 +
      newItem?.total_ticket_6_11 +
      newItem?.total_ticket_6_12 +
      newItem?.total_ticket_6_13 +
      newItem?.total_ticket_6_14 +
      newItem?.total_ticket_6_15 +
      newItem?.total_ticket_6_16 +
      newItem?.total_ticket_7_1 +
      newItem?.total_ticket_7_11 +
      newItem?.total_ticket_7_12 +
      newItem?.total_ticket_7_13 +
      newItem?.total_ticket_7_14 +
      newItem?.total_ticket_7_15 +
      newItem?.total_ticket_7_16

    newItem.total_amount_discount_e_ticket =
      newItem?.total_amount_1_2 +
      newItem?.total_amount_2_2 +
      newItem?.total_amount_5_2 +
      newItem?.total_amount_6_2 +
      newItem?.total_amount_7_2

    newItem.total_ticket_discount_e_ticket =
      newItem?.total_ticket_1_2 +
      newItem?.total_ticket_2_2 +
      newItem?.total_ticket_5_2 +
      newItem?.total_ticket_6_2 +
      newItem?.total_ticket_7_2

    newItem.total_ticket_free_e_ticket =
      newItem?.total_ticket_1_3 +
      newItem?.total_ticket_2_3 +
      newItem?.total_ticket_5_3 +
      newItem?.total_ticket_6_3 +
      newItem?.total_ticket_7_3

    newItem.total_ticket_package_e_ticket =
      newItem?.total_ticket_1_4 +
      newItem?.total_ticket_2_4 +
      newItem?.total_ticket_5_4 +
      newItem?.total_ticket_6_4 +
      newItem?.total_ticket_7_4

    newItem.total_ve_thuong =
      newItem.total_normal_ticket_cash + newItem.total_ticket_normal_e_ticket

    newItem.total_ve_giam = newItem.total_ticket_4_2 + newItem?.total_ticket_discount_e_ticket

    newItem.total_ve_mien = newItem.total_ticket_4_3 + newItem?.total_ticket_free_e_ticket

    newItem.total_ve_tap = newItem.total_ticket_4_4 + newItem?.total_ticket_package_e_ticket

    newItem.total_except_ve_mien = newItem.total_tickets - newItem.total_ve_mien

    return newItem
  })
}

function* handleGetRouteReport({ payload }) {
  const { params } = payload

  try {
    const response = yield call(reportApi.getRouteReport, params)
    const data = convertRouteReportData(response.data)
    yield put(routeReportActions.getRouteReportSuccess(data))
  } catch (error) {
    console.log(`error`, error)
    apiErrorToast('Error. Please try again')
    yield put(routeReportActions.getRouteReportFailed())
  }
}

export default function* routeReportSaga() {
  yield takeLatest(routeReportActions.getRouteReport.type, handleGetRouteReport)
}
