import { fetch } from '.'

const routeApi = {
  getRouteList(params = {}) {
    const url = '/v3/route/list'
    return fetch('get', url, params)
  },

  getRouteByEnterprise(enterpriseCode) {
    const url = `/v3/route/list-by-enterprise/${enterpriseCode}`
    return fetch('get', url, {})
  },
  getRouteOptsByAmountAndTicketTypeIdApi(params) {
    const qs = '?' + new URLSearchParams(params).toString()
    const url = `/v3/route/list-menu${qs}`
    return fetch('get', url, {})
  },
  getRouteByEnterprises(listEnterprises) {
    const url = `/v3/route/list-by-enterprise?${listEnterprises}`
    return fetch('get', url, {})
  },
  getRouteByEnterpriseAndRouteNumber(enterpriseCode, routeNumber) {
    const url = `/v3/route/list-by-enterprise/${enterpriseCode}/${routeNumber}`
    return fetch('get', url, {})
  },
  GET_LIST: {
    url: `/v3/route/list`,
    method: 'POST'
  },
  CHANGE_ROUTE_STATUS: {
    url: '/v3/route/change-status/:routeId',
    method: 'PATCH'
  },
  ROUTE_DETAIL: {
    url: '/v3/route/detail/:routeId',
    method: 'GET'
  },
  DETAIL_STATION: {
    url: '/v3/route/detail-station/:routeId',
    method: 'GET'
  },
  ADD: {
    url: '/v3/route/add',
    method: 'POST'
  },
  UPDATE: {
    url: '/v3/route/update/:routeId',
    method: 'put'
  },
  UPDATE_STATION: {
    url: '/v3/route-station/update-order-number/:routeId',
    method: 'PUT'
  },
  REMOVE_STATION: {
    url: '/v3/route/remove-station/:routeId/:stationId',
    method: 'POST'
  }
}

export default routeApi
