import { fetch } from '.'

export default {
  uploadImages: (_images) => {
    const images = Array.isArray(_images) ? _images : [_images]
    const formData = new FormData()

    for (const image of images) {
      formData.append('images', image)
    }
    return fetch('post', '/v3/upload/upload-images', formData, false, {
      'Content-type': 'multipart/form-data'
    })
  }
}
