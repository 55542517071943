import { fetch } from '.'

const eventApi = {
  getCampaignListForFilter() {
    const url = '/v3/promotion/campaign/bos/filter-list'
    return fetch('get', url, null)
  },
  getCampaignList(params) {
    const url = '/v3/promotion/campaign/bos/list'
    return fetch('post', url, params)
  },
  getRecipientsList(params) {
    const url = '/v3/promotion/get_all_transactions'
    return fetch('post', url, params)
  },
  createCampaign(params) {
    const url = '/v3/promotion/campaign/add'
    return fetch('post', url, params)
  },
  getCampaignDetail(id) {
    const url = `/v3/promotion/campaign/bos/detail/${id}`
    return fetch('get', url, null)
  },
  updateCampaign(id, body) {
    const url = `/v3/promotion/campaign/edit/${id}`
    return fetch('put', url, body)
  },
  getRoute() {
    const url = `/v3/promotion/campaign/edit/${id}`
    return fetch('put', url, body)
  },
  changeStatusApi(body, id) {
    const url = `/v3/promotion/campaign/bos/change-status/${id}`
    return fetch('put', url, body)
  }
}

export default eventApi
