import monthlyTicketApi from '@ourSrc/api/monthlyTicket'
import routeApi from '@ourSrc/api/routeApi'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { monthlyTicketActions } from './slice'

function* handleGetRoutes({ payload }) {
  const { company } = payload

  try {
    const response = yield call(
      company?.length ? routeApi.getRouteByEnterprise : routeApi.getRouteList,
      company?.length ? company || {} : { active: 1 }
    )
    const data = response.data.map((item) => {
      const { enterprise_id, route_trend_from, route_trend_to, route_number, route_id, distance } =
        item

      const route_name = `${route_trend_from} - ${route_trend_to}`
      const filter = distance < 15000 ? 1 : distance >= 15000 && distance < 25000 ? 2 : 3

      return { enterprise_id, route_number, route_id, route_name, filter }
    })
    const arrayUniqueByKey = [...new Map(data.map((item) => [item['route_number'], item])).values()]
    yield put(monthlyTicketActions.getRoutesSuccess({ arrayUniqueByKey, data }))
  } catch (error) {
    yield put(monthlyTicketActions.getRouteFailed())
  }
}

function* handleGetManageList({ payload }) {
  try {
    const param = new URLSearchParams(payload.params).toString()
    const response = yield call(monthlyTicketApi.getManageList, param)
    if (response?.error?.code === 1) {
      const data = response.data.map((item, key) => {
        item.idx = key + 1
        item.import = item.max_offset * item.tickets_in_batch
        item.inventory = item.max_offset * item.tickets_in_batch - item.total_ticket_sold
        return item
      })
      yield put(monthlyTicketActions.getManageListSuccess(data))
    } else {
      yield put(monthlyTicketActions.getManageListFailed())
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleDelete({ payload }) {
  const user = yield select((state) => state.auth.user)
  const { id, onFinish, onFaied } = payload
  const data = { deleter_domain: user?.identity?.traits?.email }
  try {
    const response = yield call(monthlyTicketApi.deleteSku, id, data)
    if (response && response.error.code !== 1) {
      onFaied(response.error.messageVN)
    }
    onFinish()
  } catch (error) {
    console.log('error', error)
  } finally {
    yield put(monthlyTicketActions.deleteFinish())
  }
}

function* handleGetSaleList({ payload }) {
  const { params } = payload
  try {
    const response = yield call(monthlyTicketApi.getSaleList, params)
    const data = response.data.items.map((item, idx) => {
      const [year, month, date] = item.created_date.split('-')
      return {
        ...item,
        index: params.offset + idx + 1,
        order_code: `MUA-${item.order_code}`,
        // created_date: `${date}/${month}/${year}`,
        // completed_date: item.completed_date.split('-').reverse().join('/'),
        // payment_date: item.payment_date.split('-').reverse().join('/'),
        month: `Tháng ${month}/${year}`
      }
    })
    yield put(
      monthlyTicketActions.getSaleListSuccess({ list: data, total: response.data.totalItem })
    )
  } catch (error) {
    console.log('error', error)
    yield put(monthlyTicketActions.getSaleListFailed())
  }
}

function* handleGetSaleDetail({ payload }) {
  const { id } = payload
  try {
    const response = yield call(monthlyTicketApi.getSaleDetail, id)
    const data = response.data.map((item, idx) => {
      return {
        ...item,
        skuId: `VETAP-${item.monthly_ticket_id}`
      }
    })
    yield put(monthlyTicketActions.getSaleDetailSuccess(data))
  } catch (error) {
    console.log('error', error)
    yield put(monthlyTicketActions.getSaleDetailFailed())
  }
}
function* handleGetTotalSummary() {
  try {
    const response = yield call(monthlyTicketApi.getTotalSummary, null)
    yield put(monthlyTicketActions.getTotalSummarySuccess(response.data))
  } catch (error) {
    console.log('error', error)
    yield put(monthlyTicketActions.getTotalSummaryFailed())
  }
}
function* handleCreateMonthlyTicket({ payload }) {
  try {
    const fullRoute = yield select((state) => state.monthlyTicket.fullRoute)
    const user = yield select((state) => state.auth.user)
    const routeIds = []
    payload.routes.map((item) => {
      const find = fullRoute.filter((f) => item.route_number === f.route_number)
      if (find) {
        find.map((i) => {
          routeIds.push(i.route_id)
        })
      }
    })
    const request = {
      ...payload.form,
      route_ids: routeIds,
      expiry_date: new Date(payload.form.expiry_date).getTime(),
      price: Number.parseFloat(payload.form.price.replaceAll('.', '')),
      max_offset: Number.parseFloat(payload.form.max_offset.replaceAll('.', '')),
      tickets_in_batch: Number.parseFloat(payload.form.tickets_in_batch.replaceAll('.', '')),
      status: payload.form.status.value,
      creator_domain: user?.identity.traits.email,
      creator_name: `${user?.identity.traits?.name?.last} ${user?.identity.traits?.name?.first}`,
      distance_from: Number.parseFloat(payload.form.distance_from) * 1000,
      distance_to: Number.parseFloat(payload.form.distance_to) * 1000
    }
    const response = yield call(monthlyTicketApi.createMonthlyTicket, request)
    if (response?.error?.code === 1) {
      payload.onHandleNotice(true, null)
    } else {
      payload.onHandleNotice(false, response?.error?.code)
    }
  } catch (error) {
    console.log('error', error)
  }
}
function* handleGetSkuById({ payload }) {
  try {
    const response = yield call(monthlyTicketApi.getSkuDetail, payload)
    if (response?.error?.code === 1) {
      yield put(monthlyTicketActions.getSkuByIdSuccess(response.data))
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleUpdateMonthlyTicket({ payload }) {
  try {
    const fullRoute = yield select((state) => state.monthlyTicket.fullRoute)
    const user = yield select((state) => state.auth.user)
    const routeIds = []
    payload.routes.map((item) => {
      const find = fullRoute.filter((f) => item.route_number === f.route_number)
      if (find) {
        find.map((i) => {
          routeIds.push(i.route_id)
        })
      }
    })
    const request = {
      ...payload.form,
      route_ids: routeIds,
      expiry_date: new Date(payload.form.expiry_date).getTime(),
      price: Number.parseFloat(payload.form.price.replaceAll('.', '')),
      max_offset: Number.parseFloat(payload.form.max_offset.replaceAll('.', '')),
      tickets_in_batch: Number.parseFloat(payload.form.tickets_in_batch.replaceAll('.', '')),
      status: payload.form.status.value,
      updater_domain: user?.identity.traits.email,
      updater_name: `${user?.identity.traits?.name?.last} ${user?.identity.traits?.name?.first}`,
      distance_from: Number.parseFloat(payload.form.distance_from) * 1000,
      distance_to: Number.parseFloat(payload.form.distance_to) * 1000
    }
    const response = yield call(monthlyTicketApi.updateMonthlyTicket, request, payload.idSkuUpdate)
    if (response?.error?.code === 1) {
      payload.onHandleNotice(true, null)
    } else {
      payload.onHandleNotice(false, response?.error?.messageVN)
    }
  } catch (error) {
    console.log('error', error)
  }
}
export default function* montlyTicketSaga() {
  yield takeLatest(monthlyTicketActions.getRoutes.type, handleGetRoutes)
  yield takeLatest(monthlyTicketActions.getManageList.type, handleGetManageList)
  yield takeLatest(monthlyTicketActions.getSaleList.type, handleGetSaleList)
  yield takeLatest(monthlyTicketActions.delete.type, handleDelete)
  yield takeLatest(monthlyTicketActions.getSaleDetail.type, handleGetSaleDetail)
  yield takeLatest(monthlyTicketActions.getTotalSummary.type, handleGetTotalSummary)
  yield takeLatest(monthlyTicketActions.createMonthlyTicket.type, handleCreateMonthlyTicket)
  yield takeLatest(monthlyTicketActions.getSkuById.type, handleGetSkuById)
  yield takeLatest(monthlyTicketActions.updateMonthlyTicket.type, handleUpdateMonthlyTicket)
}
