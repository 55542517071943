import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  loading: false,
  childLoading: false,
  error: false,
  detailDay: [],
  loadingUpdate: false,
  history: {
    loading: false,
    data: {}
  },
  checkDeviceStatus: {
    setting: {},
    data: {},
    summary: { active: 0, disconnect: 0, inactive: 0, repair: 0, total: 0, unavailable: 0 },
    loading: false,
    selected: [],
    loadingExecute: false
  }
}

const syncDataSlice = createSlice({
  name: 'syncData',
  initialState,
  reducers: {
    getListData: (state) => {
      state.data = []
      state.loading = true
      state.childLoading = false
      state.error = false
    },
    getListDataSuccess: (state, action) => {
      state.data = action.payload
      state.loading = false
      state.childLoading = false
      state.error = false
    },
    getLv1Data: (state) => {
      state.childLoading = true
    },
    getLv1DataSuccess: (state, action) => {
      state.childLoading = false
      state.data = action.payload
    },
    getLv1DataFailed: (state) => {
      state.childLoading = false
    },
    getLv2Data: (state) => {
      state.childLoading = true
    },
    getLv2DataSuccess: (state, action) => {
      state.childLoading = false
      state.data = action.payload
    },
    getLv2DataFailed: (state) => {
      state.childLoading = false
    },
    getReportDetailDay: (state) => {},
    getReportDetailDaySuccess: (state, action) => {
      state.detailDay = action.payload
    },
    updateStatusValidate: (state) => {},
    updateStatusValidateSuccess: (state, action) => {
      state.data = action.payload
    },
    execUpdateNote: (state) => {
      state.loadingUpdate = true
    },
    execUpdateNoteSuccess: (state, action) => {
      state.loadingUpdate = false
      state.data = action.payload
    },
    execUpdateNoteFail: (state) => {
      state.loadingUpdate = false
    },
    //checkDeviceStatus
    getSettings: (state) => {
      state.checkDeviceStatus.setting = {}
    },
    getSettingsDone: (state, action) => {
      state.checkDeviceStatus.setting = action.payload
    },
    getDeviceStatus: (state) => {
      state.checkDeviceStatus.selected = []
      state.checkDeviceStatus.data = {}
      state.checkDeviceStatus.summary = {}
      state.checkDeviceStatus.loading = true
    },
    getDeviceStatusDone: (state, action) => {
      state.checkDeviceStatus.data = action.payload.data
      state.checkDeviceStatus.summary = action.payload.summary
      state.checkDeviceStatus.loading = false
    },
    updateStatus: () => {},
    exportDeviceStatus: () => {},
    deviceStatusSelected: (state, action) => {
      state.checkDeviceStatus.selected = action.payload
    },
    settingDelay: () => {},
    //history
    getHistory: (state) => {
      state.history.loading = true
      state.history.data = {}
    },
    getHistoryDone: (state, action) => {
      state.history.loading = false
      state.history.data = action.payload
    },
    exportHistory: () => {}
  }
})

export const syncDataActions = syncDataSlice.actions

const syncDataReducer = syncDataSlice.reducer
export default syncDataReducer
