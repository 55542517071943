import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: []
}

const carSlice = createSlice({
  name: 'car',
  initialState,
  reducers: {
    getCars: (state) => {
      state.loading = true
    },

    getCarsSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },

    getCarsFailed: (state) => {
      state.loading = false
    }
  }
})

export const carActions = carSlice.actions

const carReducer = carSlice.reducer
export default carReducer
