import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  general: { data: [], loading: false, error: false, total: 0 },
  transactionDetail: { data: [], loading: false, error: false },
  historyDetail: { data: [], loading: false, error: false },
  history: { data: [], loading: false, error: false, total: 0 }
}

const monthlyTicketWithEnterpriseA1Slice = createSlice({
  name: 'monthlyTicketWithEnterpriseA1',
  initialState,
  reducers: {
    getCenterMonthlyTicketWithEnterpriseA1GenaralReport: (state) => {
      state.general = { data: [], loading: true, error: false, total: 0 }
    },
    getCenterMonthlyTicketWithEnterpriseA1GenaralReportSuccess: (state, action) => {
      state.general = { ...state.general, data: action.payload, loading: false }
    },
    getCenterMonthlyTicketWithEnterpriseA1GenaralReportFailed: (state) => {
      state.general = { ...state.general, loading: false, error: true }
    },
    setCenterMonthlyTicketWithEnterpriseA1GenaralReportTotal: (state, action) => {
      state.general = { ...state.general, total: action.payload }
    },
    getCenterMonthlyTicketWithEnterpriseA1GenaralDetail: (state) => {
      state.transactionDetail = { data: [], loading: true, error: false }
    },
    getCenterMonthlyTicketWithEnterpriseA1GenaralDetailSuccess: (state, action) => {
      state.transactionDetail = { ...state.transactionDetail, data: action.payload, loading: false }
    },
    getCenterMonthlyTicketWithEnterpriseA1GenaralDetailFailed: (state) => {
      state.transactionDetail = { ...state.transactionDetail, loading: false, error: true }
    },
    confirmPayment: (state, action) => {},
    confirmPaymentSuccess: (state, action) => {},
    getHistory: (state, action) => {
      state.history = { data: [], loading: true, error: false, total: 0 }
    },
    getHistorySuccess: (state, action) => {
      state.history = { ...state.history, data: action.payload, loading: false }
    },
    setHistoryTotal: (state, action) => {
      state.history = { ...state.history, total: action.payload }
    },
    getHistoryFailed: (state, action) => {
      state.history = { ...state.history, data: [], loading: false, error: true }
    }
  }
})

export const monthlyTicketWithEnterpriseA1Actions = monthlyTicketWithEnterpriseA1Slice.actions

const monthlyTicketWithEnterpriseA1Reducer = monthlyTicketWithEnterpriseA1Slice.reducer
export default monthlyTicketWithEnterpriseA1Reducer
