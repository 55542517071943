import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  loading: false,
  error: false,
  total: {}
}

const vietbankHistorySlice = createSlice({
  name: 'vietbankHistory',
  initialState,
  reducers: {
    getVietbankHistory: (state) => {
      state.data = []
      state.loading = true
      state.error = false
    },
    getVietbankHistorySuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    getVietbankHistoryFailed: (state) => {
      state.loading = false
      state.error = true
    },
    setVietbankHistoryTotal: (state, action) => {
      state.total = action.payload
    },
    exportExcel: () => {}
  }
})

export const vietbankHistoryActions = vietbankHistorySlice.actions

const vietbankHistoryReducer = vietbankHistorySlice.reducer
export default vietbankHistoryReducer
