import { call, put, takeLatest } from '@redux-saga/core/effects'
import enterpriseApi from '@ourSrc/api/enterpriseApi'
import { enterpriseListActions } from './enterpriseList.slice'
import { successToast, apiErrorToast } from '@ourSrc/utility/Utils'
function* handleGetEnterpriseList({ payload }) {
  try {
    const result = yield call(enterpriseApi.getEnterpriseAllData)
    yield put(enterpriseListActions.getEnterpriseListSuccess(result))
  } catch (error) {
    console.error(error)
  }
}

function* handleUpdateEnterprise({ payload: { enterpriseId, body } }) {
  try {
    const res = yield call(enterpriseApi.updateEnterprise, enterpriseId, body)
    if (res && res.data && res.error.code === 1) {
      successToast('Thông báo', 'Bạn đã cập nhật doanh nghiệp thành công')
      yield put(enterpriseListActions.updateEnterpriseSuccess(res.data))
    } else {
      apiErrorToast(res.error.messageVN)
      yield put(enterpriseListActions.updateEnterpriseFailed())
    }
  } catch (error) {
    apiErrorToast('Đã có lỗi xảy ra')
    yield put(enterpriseListActions.updateEnterpriseFailed())
  }
}

function* handleAddEnterprise({ payload: { body } }) {
  try {
    const res = yield call(enterpriseApi.addEnterprise, body)
    if (res && res.data && res.error.code === 1) {
      successToast('Thông báo', 'Bạn đã thêm doanh nghiệp thành công')
      yield put(enterpriseListActions.addEnterpriseSuccess(res.data))
    } else {
      apiErrorToast(res.error.messageVN)
      yield put(enterpriseListActions.addEnterpriseFailed())
    }
  } catch (error) {
    yield put(enterpriseListActions.addEnterpriseFailed())
    apiErrorToast('Đã có lỗi xảy ra')
  }
}

export default function* enterpriseListSaga() {
  yield takeLatest(enterpriseListActions.getEnterpriseList.type, handleGetEnterpriseList)
  yield takeLatest(enterpriseListActions.updateEnterprise.type, handleUpdateEnterprise)
  yield takeLatest(enterpriseListActions.addEnterprise.type, handleAddEnterprise)
}
