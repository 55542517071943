import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  loading: false,
  detail: {},
  routes: [],
  routesFull: []
}

const eventListSlice = createSlice({
  name: 'eventList',
  initialState,
  reducers: {
    getEventList: (state) => {
      state.loading = true
    },
    getEventListSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    getEventListFail: (state, action) => {
      state.loading = false
      state.data = {}
    },
    handleExport: (state) => {},
    createCampaign: () => {},
    getDetail: (state) => {
      state.detail = {}
    },
    getDetailSuccess: (state, action) => {
      state.detail = action.payload
    },
    updateCampaign: () => {},
    getRoute: () => {},
    getRouteDone: (state, action) => {
      state.routes = action.payload.routes
      state.routesFull = action.payload.routesFull
    },
    changeStatusEvent: (state) => {}
  }
})

export const eventListActions = eventListSlice.actions

const eventListReducer = eventListSlice.reducer
export default eventListReducer
