import { put, takeLatest, call } from '@redux-saga/core/effects'
import settingApi from '@ourSrc/api/settingApi'
import enterpriseApi from '@ourSrc/api/enterpriseApi'
import { userListActions } from './userList.slice'
import { USER_STATUS } from '@ourSrc/constants/dataType'
import { errorToast, successToast } from '@ourSrc/utility/Utils'

const formatParams = (data, currentData = null) => {
  const roles = data?.roles?.length
    ? data.roles.map((item) => {
        return item.value
      })
    : []
  return {
    roles,
    first: data.firstName,
    last: data.lastName,
    state:
      data?.status?.value === USER_STATUS.pending.value
        ? USER_STATUS.active.value
        : data?.status?.value,
    email: data.email,
    company: data?.enterprise instanceof Array ? data.enterprise.map((i) => i.value) : [],
    id: currentData?.id,
    userId: currentData?.id
  }
}
function* handleUpdateUser({ payload }) {
  const { data, onSuccess, setLoading, currentData } = payload
  try {
    const params = formatParams(data, currentData)
    const responseKratos = yield call(settingApi.updateUserFromKratos, params)
    if (responseKratos.userId) {
      const response = yield call(settingApi.updateUser, responseKratos.userId, params)
      successToast('Thành công', 'Cập nhật thành công')
      onSuccess()
    } else {
      errorToast('Thất bại', 'Đã có lỗi xảy ra, vui lòng kiểm tra lại')
    }
  } catch (error) {
    console.log(`error`, error)
  } finally {
    setLoading(false)
  }
}

function* handleAddNewUserKratos({ payload }) {
  const { data, onSuccess, setLoading } = payload
  try {
    const params = formatParams(data)
    const responseKrotos = yield call(settingApi.createUserFromKratos, params)
    const { isSuccess, userId, message } = responseKrotos
    if (isSuccess) {
      const response = yield call(settingApi.addNewUser, { ...params, id: userId, userId: userId })
      onSuccess()
      successToast('Thành công', 'Đã cập nhật user thành công')
    } else {
      errorToast('Thất bại', 'Đã có lỗi xảy ra')
    }
  } catch (error) {
    console.log(`error`, error)
  } finally {
    setLoading(false)
  }
}

function* handleDeleteUser({ payload }) {
  try {
    const { userId, onSuccess, setLoading } = payload
    const responseKrotos = yield call(settingApi.deleteUserFromKratos, userId)
    const { isSuccess } = responseKrotos
    if (isSuccess) {
      const response = yield call(settingApi.deleteUser, userId)
      onSuccess()
      successToast('Thành công', 'Xóa tài khoản thành công')
    } else {
      errorToast('Thất bại', 'Đã có lỗi xảy ra')
    }
    setLoading(false)
  } catch (error) {
    console.log(`error`, error)
  }
}

function* handleGetUserList() {
  try {
    const responseKrotos = yield call(settingApi.getAllUserFromKratos) //From Kratos
    const response = yield call(settingApi.getUserList) //from BE
    let totalActive = 0
    let totalPending = 0
    if (responseKrotos?.data?.length) {
      const data = responseKrotos.data.map((m, index) => {
        const f = response.data.find((f) => f.userId === m.id)
        m.roles =
          f?.roles.map((i) => {
            i.value = i.id
            i.label = i.name
            return i
          }) || []
        m.email = m.traits.email
        m.idx = index + 1
        m.company = m.traits?.company
        m.fullName = `${m.traits.name?.first || ''} ${m.traits.name?.last || ''}`
        m.status =
          m.state === USER_STATUS.active.value
            ? !f?.roles?.length
              ? USER_STATUS.pending.value
              : USER_STATUS.active.value
            : USER_STATUS.inactive.value
        totalActive = m.state === 'active' ? totalActive + 1 : totalActive
        totalPending = !f?.roles?.length ? totalPending + 1 : totalPending
        return m
      })
      const summary = {
        total: responseKrotos.data.length,
        totalActive,
        totalPending
      }
      yield put(userListActions.getUserListSuccess({ items: data, summary: summary }))
    } else yield put(userListActions.getUserListFailed())
  } catch (error) {
    console.log(`error`, error)
  }
}

function* handedGetListRoles() {
  try {
    const response = yield call(settingApi.getRoleList, null)
    if (response.data) {
      const formatData = response.data.map((i) => ({ value: i.id, label: i.name }))
      yield put(userListActions.getListRolesDone(formatData))
    }
  } catch (error) {
    console.log(`error`, error)
  }
}
function* handleGetListEnterprise() {
  try {
    const response = yield call(enterpriseApi.getEnterprise)
    if (response.data) {
      const formatData = response.data.map((i) => ({
        value: i.enterprise_code,
        label: i.enterprise_code
      }))
      yield put(userListActions.getListEnterpriseDone(formatData))
    }
  } catch (error) {
    console.log(`error`, error)
  }
}
export default function* userListSaga() {
  yield takeLatest(userListActions.deleteUser.type, handleDeleteUser)
  yield takeLatest(userListActions.updateUser.type, handleUpdateUser)
  yield takeLatest(userListActions.addNewUserKratos.type, handleAddNewUserKratos)
  yield takeLatest(userListActions.getUserList.type, handleGetUserList)
  yield takeLatest(userListActions.getListRoles.type, handedGetListRoles)
  yield takeLatest(userListActions.getListEnterprise.type, handleGetListEnterprise)
}
