import { call, put, takeLatest, select } from 'redux-saga/effects'
import moment from 'moment'

import { historyReportA2Actions } from './historyReportA2.slice'
import vinbusApi from '@ourSrc/api/vinbusApi'
import { TICKET_TYPE } from '@ourSrc/constants/ticketType'
import { USER_TYPE } from '@ourSrc/constants/queryCustomer'
import { formatVnFullDate } from '@ourSrc/utility/Utils'
const customers = Object.values(USER_TYPE)

export const convertHistoryData = (data, params) => {
  return data.map((i, idx) => ({
    ...i,
    index: params.offset + idx + 1,
    payment_date: formatVnFullDate(i.completed_date),
    payment_status_name: i.payment_status === 2 ? 'Thành công' : 'Thất bại',
    enterprise_code: 'VINBUS'
    // ticket_type: TICKET_TYPE[i.ticket_type_id].name
    // denominator: `${i.route_denominator}/${i.ticket_code}`,
    // symbol: `${i.route_symbol}/${i.car_code}`,
    // customer: customers.find((c) => c.value === i.card_type)?.label || '',
    // turn: i.turn.split('_').splice(-1)
  }))
}

function* handleGetHistoryA2Report({ payload }) {
  try {
    const { params } = payload
    const response = yield call(vinbusApi.getHistory, params)

    if (!response.data || response.data.items.length === 0) {
      yield put(historyReportA2Actions.getHistoryReportA2Success([]))
    } else {
      const data = convertHistoryData(response.data.items, params)
      yield put(historyReportA2Actions.getHistoryReportA2Success(data))
      yield put(
        historyReportA2Actions.getHistoryReportA2Total({
          total_item: response.data.totalItem,
          total_amount: response.data.total_amount,
          total_page: response.data.totalPage
        })
      )
    }
  } catch (error) {
    console.log(`error`, error)
  }
}

export default function* historyReportSaga() {
  yield takeLatest(historyReportA2Actions.getHistoryReportA2.type, handleGetHistoryA2Report)
}
