import { call, put, takeLatest } from '@redux-saga/core/effects'
import customerApi from '@ourSrc/api/customerApi'
import { queryCustomerActions } from './queryCustomer.slice'
import { uploadImagesFromObject } from '@ourSrc/utility/Upload'
import { successToast, apiErrorToast, getSevenDaysBeforeToday } from '@ourSrc/utility/Utils'

function* handleGetQueryCustomer({ payload: { body } }) {
  try {
    const res = yield call(customerApi.getCustomerDetail, body)
    yield put(queryCustomerActions.getQueryCustomerSuccess(res.data))
  } catch (error) {
    yield put(queryCustomerActions.getQueryCustomerFailed())
  }
}

function* handleUpdateCustomer({ payload: { body } }) {
  try {
    const fileUrls = yield call(uploadImagesFromObject, body)
    const params = { ...body, ...fileUrls }
    const res = yield call(customerApi.updateCustomer, params)

    if (res.data) {
      yield put(queryCustomerActions.updateCustomerSuccess(res.data))
      successToast('Thông báo', 'Cập nhật khách hàng thành công!')
    } else {
      apiErrorToast(res.error.messageVN)
      yield put(queryCustomerActions.updateCustomerFailed())
    }
  } catch (error) {
    apiErrorToast('Đã có lỗi xảy ra')
    yield put(queryCustomerActions.updateCustomerFailed())
  }
}

function* handleGetPaymentHistory({ payload: { params } }) {
  try {
    const body = { ...params }
    if (!body?.toDate) {
      const { fromDate, toDate } = getSevenDaysBeforeToday()
      body.fromDate = fromDate
      body.toDate = toDate
    }
    const res = yield call(customerApi.getPaymentHistory, body)
    if (res?.data) {
      if (body.transaction_type === 1) {
        yield put(queryCustomerActions.getRechargeHistorySuccess(res.data))
      } else if (body.transaction_type === 3) {
        yield put(queryCustomerActions.getPaymentHistorySuccess(res.data))
      }
    } else {
      yield put(queryCustomerActions.getPaymentHistoryFailed())
    }
  } catch (error) {
    yield put(queryCustomerActions.getPaymentHistoryFailed())
  }
}
function* handleConfirmRequestCustomer({ payload: { body } }) {
  try {
    const res = yield call(customerApi.updateCustomer, body)
    if (res.data) {
      yield put(queryCustomerActions.updateCustomerSuccess(res.data))
      successToast('Thông báo', 'Cập nhật khách hàng thành công!')
    } else {
      apiErrorToast(res.error.messageVN)
      yield put(queryCustomerActions.updateCustomerFailed())
    }
  } catch (error) {
    apiErrorToast('Đã có lỗi xảy ra')
    yield put(queryCustomerActions.updateCustomerFailed())
  }
}

export default function* queryCustomerSaga() {
  yield takeLatest(queryCustomerActions.getQueryCustomer.type, handleGetQueryCustomer)
  yield takeLatest(queryCustomerActions.updateCustomer.type, handleUpdateCustomer)
  yield takeLatest(queryCustomerActions.getPaymentHistory.type, handleGetPaymentHistory)
  yield takeLatest(queryCustomerActions.confirmRequestCustomer.type, handleConfirmRequestCustomer)
  yield takeLatest(queryCustomerActions.rejectRequestCustomer.type, handleConfirmRequestCustomer)
}
