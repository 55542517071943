export const EVENT_STATUS = {
  waiting: {
    value: -1,
    label: 'Sắp diễn ra',
    color: '#FF9F43'
  },
  active: {
    value: 1,
    label: 'Đang diễn ra',
    color: '#28C76F'
  },
  inactive: {
    value: 2,
    label: 'Tạm ngưng',
    color: '#EA5455'
  },
  expired: {
    value: 3,
    label: 'Kết thúc',
    color: '#82868B'
  }
}
export const TOTAL_SELECT = { value: 'all', label: 'Tất cả' }
