import { put, takeLatest, call, select } from '@redux-saga/core/effects'
import _cloneDeep from 'lodash/cloneDeep'
import _groupBy from 'lodash/groupBy'
import _sumBy from 'lodash/sumBy'
import _pickBy from 'lodash/pickBy'
import eventApi from '@ourSrc/api/event'
import { eventListActions } from './slice'
import uploadApi from '@ourSrc/api/uploadApi'
import {
  isFile,
  successToast,
  errorToast,
  currencyFormat,
  convertHtmlToDraft
} from '@ourSrc/utility/Utils'
import { EVENT_STATUS, TOTAL_SELECT } from '@ourSrc/constants/event'
import { PROMOTION_TYPES } from '@ourSrc/constants/promotion'
import moment from 'moment'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import routeApi from '@ourSrc/api/routeApi'

const formatData = (data, params) =>
  data.map((i, key) => {
    i.stt = key + params.offset + 1
    i.remainingBudget = i.totalBudget - i.totalUsedAmount
    return i
  })
const formatBody = (data) => {
  const { started, expired, giftExpired, totalBudget, totalGift, giftsPerCustomer, status } = data
  return {
    ...data,
    started: Array.isArray(started) ? started?.[0] : started,
    expired: Array.isArray(expired) ? expired?.[0] : expired,
    giftExpired: Array.isArray(giftExpired) ? giftExpired?.[0] : giftExpired,
    totalBudget: totalBudget?.includes('.')
      ? Number(totalBudget.replaceAll('.', ''))
      : Number(totalBudget),
    totalGift: Number(totalGift),
    giftsPerCustomer: Number(giftsPerCustomer),
    status: typeof status === 'object' ? status.value : status
  }
}
function* handleGetEventList({ payload }) {
  const { params } = payload
  try {
    const response = yield call(eventApi.getCampaignList, params)
    if (response?.data) {
      const data = formatData(response.data?.items, params)
      yield put(eventListActions.getEventListSuccess({ ...response.data, items: data }))
    } else {
      yield put(eventListActions.getEventListFail())
    }
  } catch (error) {
    console.log('error', error)
    yield put(eventListActions.getEventListFail())
  }
}

function* handleCreateCampaign({ payload }) {
  const { data, onSuccess, setLoading } = payload
  try {
    if (isFile(data?.imageBackground)) {
      const uploadRes = yield call(uploadApi.uploadImages, data?.imageBackground)
      if (uploadRes?.data?.length) {
        const body = formatBody({ ...data, imageBackground: uploadRes.data[0]?.image_big })
        const response = yield call(eventApi.createCampaign, body)
        if (response?.data) {
          onSuccess()
          successToast('Thành công', 'Tạo sự kiện thành công')
        } else {
          errorToast('Thất bại', response?.error?.messageVN)
        }
      } else {
        errorToast('Thất bại', 'Tải hình ảnh không thành công')
      }
      setLoading(false)
    } else {
      errorToast('Thất bại', 'Vui lòng kiểm tra lại hình ảnh')
    }
  } catch (error) {
    console.log('error', error)
  }
}
function* handleUpdateCampaign({ payload }) {
  const { data, onSuccess, setLoading, detail } = payload
  try {
    let body = formatBody({ ...detail, ...data })
    if (data?.description || detail?.description) {
      body = {
        ...body,
        description: draftToHtml(
          convertToRaw(
            data?.description
              ? data.description.getCurrentContent()
              : detail?.description?.getCurrentContent()
          )
        )
      }
    }
    if (data?.giftGuildline || detail?.giftGuildline) {
      body = {
        ...body,
        giftGuildline: draftToHtml(
          convertToRaw(
            data?.giftGuildline
              ? data.giftGuildline.getCurrentContent()
              : detail?.giftGuildline?.getCurrentContent()
          )
        )
      }
    }
    if (data?.giftTerm || detail?.giftTerm) {
      body = {
        ...body,
        giftTerm: draftToHtml(
          convertToRaw(
            data?.giftTerm
              ? data.giftTerm.getCurrentContent()
              : detail?.giftTerm?.getCurrentContent()
          )
        )
      }
    }

    if (data?.routeIds) {
      const { routesFull } = yield select((state) => state.eventList)
      if (data?.routeIds.find((f) => f.value === TOTAL_SELECT.value)) {
        body = {
          ...body,
          routeIds: routesFull.map((i) => i.route_id)
        }
      } else {
        const routeIds = []
        data.routeIds.forEach((r) => {
          const f = routesFull.filter((f) => f.value === r.value)
          routeIds.push.apply(routeIds, f)
        })
        body = {
          ...body,
          routeIds: routeIds.map((i) => i.route_id)
        }
      }
    }
    if (data?.paymentMethod?.length) {
      body = {
        ...body,
        paymentMethod: data.paymentMethod.map((i) => i.value)
      }
    }
    if (isFile(data?.icon)) {
      const uploadIcon = yield call(uploadApi.uploadImages, data?.icon)
      if (uploadIcon?.data?.length) {
        body = { ...body, icon: uploadIcon.data[0]?.image_big }
      } else {
        errorToast('Thất bại', 'Tải icon không thành công')
      }
    }
    if (isFile(data?.image)) {
      const uploadRes = yield call(uploadApi.uploadImages, data?.image)
      if (uploadRes?.data?.length) {
        body = { ...body, image: uploadRes.data[0]?.image_big }
      } else {
        errorToast('Thất bại', 'Tải hình ảnh không thành công')
      }
    }
    if (isFile(data?.imageBackground)) {
      const uploadRes = yield call(uploadApi.uploadImages, data?.imageBackground)
      if (uploadRes?.data?.length) {
        body = { ...body, imageBackground: uploadRes.data[0]?.image_big }
      } else {
        errorToast('Thất bại', 'Tải hình ảnh không thành công')
      }
    }
    body = _pickBy(body, (v) => v !== undefined && !Number.isNaN(v))
    const response = yield call(eventApi.updateCampaign, detail?.id, body)
    if (response?.data) {
      onSuccess()
      successToast('Thành công', 'Chỉnh sửa sự kiện thành công')
    } else {
      errorToast('Thất bại', response?.error?.messageVN)
    }
    setLoading(false)
  } catch (error) {
    console.log('error', error)
  }
}
function* handleGetDetail({ payload }) {
  const { id } = payload
  try {
    const response = yield call(eventApi.getCampaignDetail, id)
    if (response?.data) {
      let rules = {}
      if (response.data?.rules?.length) {
        const promotionTypeList = Object.values(PROMOTION_TYPES)
        response.data.rules.forEach((element) => {
          for (const [key, value] of Object.entries(element)) {
            const findPromotionType = promotionTypeList.find((f) => f.method === key && value)
            if (findPromotionType) {
              rules = {
                ...rules,
                [`promotionType_${element?.ticketTypeId}`]: findPromotionType,
                [`promotionValue_${element?.ticketTypeId}`]: element[findPromotionType.value]
              }
            }
          }
        })
      }
      const formatData = {
        ...response.data,
        ...rules,
        started: moment(response.data?.started).toISOString(),
        expired: moment(response.data?.expired).toISOString(),
        giftExpired: moment(response.data?.giftExpired).toISOString(),
        totalBudget: currencyFormat(response.data?.totalBudget),
        status: Object.values(EVENT_STATUS).find((i) => i.value === response.data?.status),
        description: convertHtmlToDraft(response.data?.description),
        giftGuildline: convertHtmlToDraft(response.data?.giftGuildline),
        giftTerm: convertHtmlToDraft(response.data?.giftTerm)
      }
      yield put(eventListActions.getDetailSuccess(formatData))
    } else {
      errorToast('Thất bại', 'Tải dữ liệu không thành công')
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetRoute() {
  try {
    const response = yield call(routeApi.getRouteList)
    if (response?.data) {
      const data = response.data.map((item) => {
        const { enterprise_id, route_number, route_id } = item
        const label = `Tuyến ${route_number}`
        const value = route_number
        return { enterprise_id, value, label, route_id, value }
      })
      const options = [...new Map(data.map((item) => [item['value'], item])).values()]
      options.unshift(TOTAL_SELECT)
      yield put(eventListActions.getRouteDone({ routes: options, routesFull: data }))
    } else {
      errorToast('Thất bại', 'Tải dữ liệu tuyến không thành công')
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleChangeStatus({ payload }) {
  try {
    const { body, id, onSuccess } = payload
    const response = yield call(eventApi.changeStatusApi, body, id)
    if (response?.data) {
      successToast('Thành công', 'Thay đổi trạng thái thành công')
      onSuccess()
    } else {
      errorToast('Thất bại', response.error.messageVN)
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleExport({ payload }) {
  const { params, onSuccess } = payload
  try {
    const response = yield call(eventApi.getCampaignList, params)
    if (response?.data) {
      const data = formatData(response.data?.items, params)
      onSuccess(data)
    }
  } catch (error) {
    console.log('error', error)
  }
}

export default function* eventListSaga() {
  yield takeLatest(eventListActions.getEventList.type, handleGetEventList)
  yield takeLatest(eventListActions.createCampaign.type, handleCreateCampaign)
  yield takeLatest(eventListActions.getDetail.type, handleGetDetail)
  yield takeLatest(eventListActions.updateCampaign.type, handleUpdateCampaign)
  yield takeLatest(eventListActions.getRoute.type, handleGetRoute)
  yield takeLatest(eventListActions.changeStatusEvent.type, handleChangeStatus)
  yield takeLatest(eventListActions.handleExport.type, handleExport)
}
