import { fetch } from '.'

const tempReceiptsApi = {
  getReceiptsList(params) {
    const url = '/v3/bill/reservation/get-list'
    return fetch('post', url, params)
  },
  GET_RECEIPT_LIST: {
    url: '/v3/bill/reservation/get-list',
    method: 'POST'
  },
  PRINT_HISTORY: {
    url: '/v3/bill/reservation/print-history',
    method: 'POST'
  },
  getSumaryApi(params) {
    const url = '/v3/bill/reservation/get-summary'
    return fetch('post', url, params)
  },
  GET_SUMMARY: {
    url: '/v3/bill/reservation/get-summary',
    method: 'POST'
  },
  createReceiptsApi(params) {
    const url = '/v3/bill/reservation/create'
    return fetch('post', url, params)
  },

  getSymbolListApi(params) {
    const url = '/v3/bill/reservation/symbol/get-list'
    return fetch('post', url, params)
  },
  GET_SYMBOL_LIST: {
    url: '/v3/bill/reservation/symbol/get-symbols',
    method: 'POST'
  },
  createSymbolApi(params) {
    const url = '/v3/bill/reservation/symbol'
    return fetch('post', url, params)
  },
  updateSymbolApi(params) {
    const url = '/v3/bill/reservation/symbol'
    return fetch('put', url, params)
  },
  checkValidSerialApi(params) {
    const url = '/v3/bill/reservation/get-list-by-serial'
    return fetch('post', url, params)
  },
  CHECK_VALID_SERIAL_API: {
    url: '/v3/bill/reservation/get-list-by-serial',
    method: 'POST'
  },
  PRINT_RECEIPTS: {
    url: '/v3/bill/reservation/print',
    method: 'POST'
  },
  getTurnListApi(params) {
    const url = '/v3/turn/get-list'
    return fetch('post', url, params)
  },
  finishTurn(params) {
    const url = '/v3/turn/finish'
    return fetch('post', url, params)
  },
  createTurnApi(params) {
    const url = '/v3/turn/start'
    return fetch('post', url, params)
  },
  activeReceiptsApi(params) {
    const url = '/v3/bill/reservation/issue'
    return fetch('post', url, params)
  },
  deleteReceiptsApi(params) {
    const url = '/v3/bill/reservation/delete'
    return fetch('delete', url, params)
  },
  updateNoteReceiptsApi(params) {
    const url = '/v3/bill/reservation/update'
    return fetch('put', url, params)
  }
}

export default tempReceiptsApi
