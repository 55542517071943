import logo from '@ourSrc/assets/images/prism-logo-small.png'
import React from 'react'

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      <img
        src={logo}
        alt="logo"
        style={{
          width: 80,
          height: 80,
          position: 'absolute',
          top: '40%',
          left: 'calc(50% - 50px)'
        }}
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
