import { call, put, takeLatest, select } from 'redux-saga/effects'
import { vngBusActions } from './slice'
import _Sumby from 'lodash/sumBy'

function* handleSetPosition({ payload }) {
  const { data } = payload
  try {
    const { markers } = yield select((state) => state.vngBus.tracking)
    const findIndex = markers.findIndex((item) => item.carId === data.carId)
    const newMarkers = [...markers]
    if (findIndex === -1) {
      newMarkers.push({ ...data })
    } else {
      newMarkers[findIndex] = data
    }
    yield put(vngBusActions.setPositionSuccess(newMarkers))
  } catch (error) {
    console.log('error', error)
  }
}

export default function* vngBusSaga() {
  // tracking bus
  yield takeLatest(vngBusActions.setPosition.type, handleSetPosition)
}
