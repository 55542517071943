import { fetch } from '.'

const enterpriseApi = {
  getEnterprise(enterpriseType = {}) {
    const url = '/v3/enterprise/list-menu'
    return fetch('get', url, enterpriseType)
  },
  GET_ENTERPRISE: {
    url: '/v3/enterprise/list-menu',
    method: 'GET'
  },
  GET_ENTERPRISE_WITH_CONDITIONS: {
    url: '/v3/enterprise/list-all',
    method: 'POST'
  },
  GET_ENTERPRISE_WITH_ALL: {
    url: '/v3/enterprise/list-all',
    method: 'GET'
  },

  getEnterpriseAllData() {
    const url = '/v3/enterprise/list-all'
    return fetch('get', url, {})
  },

  updateEnterprise(enterpriseId, body) {
    const url = `/v3/enterprise/update/${enterpriseId}`
    return fetch('put', url, body)
  },

  addEnterprise(body) {
    const url = `/v3/enterprise/add`
    return fetch('post', url, body)
  }
}

export default enterpriseApi
