import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  manage: {
    list: [],
    summary: {},
    detail: {},
    listLoading: false,
    detailLoading: false,
    deleteLoading: false
  },

  sale: {
    list: {},
    total: 0,
    detail: [],
    listLoading: false,
    detailLoading: false
  },

  routes: [],
  fullRoute: [],
  routeLoading: false
}

const monthlyTicketSlice = createSlice({
  name: 'monthlyTicket',
  initialState,
  reducers: {
    getRoutes: (state) => {
      state.routes = []
      state.fullRoute = []
      state.routeLoading = true
    },
    getRoutesSuccess: (state, action) => {
      state.routeLoading = false
      state.routes = action.payload.arrayUniqueByKey
      state.fullRoute = action.payload.data
    },
    getRouteFailed: (state) => {
      state.routeLoading = false
    },

    getManageList: (state) => {
      state.manage.listLoading = true
      state.manage.list = []
    },
    getManageListSuccess: (state, action) => {
      state.manage.listLoading = false
      state.manage.list = action.payload
    },
    getManageListFailed: (state) => {
      state.manage.listLoading = false
    },

    delete: (state) => {
      state.manage.deleteLoading = true
    },
    deleteFinish: (state) => {
      state.manage.deleteLoading = false
    },

    getSaleList: (state) => {
      state.sale.listLoading = true
      state.sale.list = []
    },
    getSaleListSuccess: (state, action) => {
      state.sale.listLoading = false
      state.sale.list = action.payload.list
      state.sale.total = action.payload.total
    },
    getSaleListFailed: (state) => {
      state.sale.listLoading = false
    },

    getSaleDetail: (state) => {
      state.sale.detailLoading = true
      state.sale.detail = []
    },
    getSaleDetailSuccess: (state, action) => {
      state.sale.detailLoading = false
      state.sale.detail = action.payload
    },
    getSaleDetailFailed: (state) => {
      state.sale.detailLoading = false
    },
    getTotalSummary: (state) => {},
    getTotalSummarySuccess: (state, action) => {
      state.manage.summary = action.payload
    },
    getTotalSummaryFailed: (state) => {},
    createMonthlyTicket: (state) => {},
    getSkuById: (state) => {},
    getSkuByIdSuccess: (state, action) => {
      state.manage.detail = action.payload
    },
    getSkuByIdFailed: (state) => {},
    clearSkuById: (state, action) => {
      state.manage.detail = []
    },
    updateMonthlyTicket: (state) => {},
    updateMonthlyTicketSuccess: (state, action) => {
      // state.manage.detail = action.payload
    },
    updateMonthlyTicketFailed: (state) => {}
  }
})

export const monthlyTicketActions = monthlyTicketSlice.actions

const monthlyTicketReducer = monthlyTicketSlice.reducer
export default monthlyTicketReducer
