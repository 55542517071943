import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tracking: {
    markers: []
  }
}

const vngBus = createSlice({
  name: 'vngBus',
  initialState,
  reducers: {
    setPosition: (state) => {},
    setPositionSuccess: (state, action) => {
      state.tracking.markers = action.payload
    }
  }
})

export const vngBusActions = vngBus.actions

const vngBusReducer = vngBus.reducer
export default vngBusReducer
