import { call, put, takeLatest } from '@redux-saga/core/effects'

import reportApi from '@ourSrc/api/reportApi'
import { apiErrorToast } from '@ourUtils'
import { inventoryReportActions } from './inventoryReport.slice'

export const convertData = (data) => {
  return data.map((i) => {
    return {
      ...i,
      minSeriGeneral: i.minSeriGeneral === 0 ? '0' : i.minSeriGeneral,
      minSeriReduce: i.minSeriReduce === 0 ? '0' : i.minSeriReduce
    }
  })
}

function* handleGetInventoryReport({ payload }) {
  const { params } = payload
  try {
    const response = yield call(reportApi.getTicketReport, params)
    const data = convertData(response.data.items)
    yield put(
      inventoryReportActions.getInventoryReportSuccess({ data, total: response.data.totalItem })
    )
  } catch (error) {
    apiErrorToast('Error. Please try again')
    yield put(inventoryReportActions.getInventoryReportFailed())
  }
}

export default function* inventoryReportSaga() {
  yield takeLatest(inventoryReportActions.getInventoryReport.type, handleGetInventoryReport)
}
