import reportApi from '@ourSrc/api/reportApi'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { vietbankHistoryActions } from './vietbankHistory.slice'

export const convertVietbankHistoryData = (data, params) => {
  return data.map((i, idx) => ({
    ...i,
    date: i.created_date_time,
    paymentStatusName: i.payment_status === 2 ? 'Thành công' : 'Thất bại',
    cardPrefix: i?.card_no?.slice(0, 6),
    cardSuffix: i?.card_no?.slice(i.card_no.length - 4, i.card_no.length),
    zptransId: i?.zptransid?.toString()
  }))
}

function* handleGetVietbankHistory({ payload }) {
  const vietbankHistoryReport = yield select((state) => state.vietbankHistory)
  const { params } = payload

  let total = vietbankHistoryReport.total
  const responseTotal = yield call(reportApi.getVietbankHistoryTotal, params)
  total = responseTotal.data
  try {
    const response = yield call(reportApi.getHistoryVietbank, params)
    if (!response.data || response.data.length === 0)
      yield put(vietbankHistoryActions.getVietbankHistorySuccess([]))
    else {
      const data = convertVietbankHistoryData(response.data, params)
      yield put(vietbankHistoryActions.getVietbankHistorySuccess(data))
      yield put(vietbankHistoryActions.setVietbankHistoryTotal(total))
    }
  } catch (error) {
    console.log(error)
  }
}

function* handleExportExcel({ payload }) {
  const { params, onSuccess, setLoadingExport } = payload
  try {
    setLoadingExport(true)
    const response = yield call(reportApi.getHistoryVietbank, params)
    if (!response.data || response.data.length === 0) onSuccess([])
    else {
      const data = convertVietbankHistoryData(response.data, params)
      onSuccess(data)
    }
  } catch (error) {
    console.log(error)
  } finally {
    setLoadingExport(false)
  }
}

export default function* vietbankHistorySaga() {
  yield takeLatest(vietbankHistoryActions.getVietbankHistory.type, handleGetVietbankHistory)
  yield takeLatest(vietbankHistoryActions.exportExcel.type, handleExportExcel)
}
