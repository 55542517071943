import uploadApi from '@ourSrc/api/uploadApi'
import { pickFiles } from './Utils'

export const uploadImagesFromObject = async (obj) => {
  const files = pickFiles(obj)
  const keys = Object.keys(files)

  if (keys.length === 0) return {}

  const res = await uploadApi.uploadImages(Object.values(files))

  const result = {}
  keys.forEach((key, idx) => {
    result[key] = res.data[idx].image_thumbnail
  })

  return result
}
