import { put, takeLatest, call } from '@redux-saga/core/effects'
import _cloneDeep from 'lodash/cloneDeep'
import _groupBy from 'lodash/groupBy'
import _sumBy from 'lodash/sumBy'
import eInvoiceApi from '@ourSrc/api/eInvoiceApi'
import { eInvoiceActions } from './slice'
import { errorToast, formatVnFullDate, successToast, warningToast } from '@ourSrc/utility/Utils'
import { STATUS } from '@ourSrc/constants/e-invoice'
import { PAYMENT_METHOD, STEP, UNIPASS } from '@ourSrc/constants/dataType'

const formatData = (data) =>
  data.items.map((item, key) => {
    const f = Object.values(STATUS).find((i) => i.value === item.status)
    item.index = key + data.offset + 1
    item.created_time_format = formatVnFullDate(item.created_time)
    item.status_message = f?.label
    item.payment_method = Object.values(UNIPASS.paymentGateway.PAYMENT_METHOD).find(
      (f) => f.value === item?.payment_method
    )?.label
    return item
  })

function* handleGetEInvoiceList({ payload }) {
  const { params } = payload
  try {
    const response = yield call(eInvoiceApi.getEInvoiceList, params)
    const responseSummary = yield call(eInvoiceApi.getSumaryApi, params)
    if (response?.data && responseSummary?.data) {
      const data = { ...response.data, items: formatData(response.data) }
      yield put(eInvoiceActions.getEInvoiceListDone({ data, summary: responseSummary.data }))
    }
  } catch (error) {
    console.log('error', error)
  }
}
function* handleExport({ payload }) {
  const { params, onSuccess, setLoadingExport } = payload
  try {
    const response = yield call(eInvoiceApi.getEInvoiceList, params)
    if (response?.data) {
      onSuccess(formatData(response.data))
    }
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoadingExport(false)
  }
}

function* handleCancelInvoice({ payload }) {
  const { data, onSuccess, setLoading } = payload
  try {
    const response = yield call(eInvoiceApi.cancelInvoiceApi, data.guid)
    if (response?.error?.code === 1) {
      successToast('Gửi yêu cầu hủy vé thành công', 'Yêu cầu đã được gửi về nhà cung cấp')
      onSuccess()
    } else {
      errorToast('Gửi yêu cầu hủy vé thất bại', response.error.messageVN)
    }
    setLoading(false)
  } catch (error) {
    console.log('error', error)
  }
}

function* handleResendInvoice({ payload }) {
  const { data, onSuccess, setLoading } = payload
  try {
    const response = yield call(eInvoiceApi.resendInvoiceApi, data.guid)
    if (response?.data) {
      successToast('Gửi lại thông tin vé thành công', 'Vé đã được gửi lại về nhà cung cấp')
      onSuccess()
    } else {
      errorToast('Gửi lại thất bại', response.error.messageVN)
    }
    setLoading(false)
  } catch (error) {
    console.log('error', error)
  }
}
function* handleResendMultipleInvoice({ payload }) {
  const {
    data,
    toggle,
    updateProgress,
    updateStep,
    updateTotalInvoiceSend,
    updateInvoiceSendFailedList,
    refetch
  } = payload
  try {
    const filterData = data.filter(
      (item) => item.status !== STATUS.success.value && item.status !== STATUS.cancel.value
    )
    updateTotalInvoiceSend(filterData.length)
    if (filterData.length) {
      function* process(data) {
        yield* data.map((x) => x)
      }
      updateStep(STEP.progress)
      let totalItemResendSucess = 0
      const invoiceSendFailedList = []
      let totalItemProgress = 0
      for (const n of process(filterData)) {
        const response = yield call(eInvoiceApi.resendInvoiceApi, n.guid)
        totalItemProgress += 1
        updateProgress(Number.parseFloat((totalItemProgress / filterData.length) * 100).toFixed(2))
        if (response.error.code === 1) {
          totalItemResendSucess += 1
        } else {
          invoiceSendFailedList.push(n)
        }
      }
      if (totalItemResendSucess === filterData.length) {
        updateStep(STEP.success)
        yield put(eInvoiceActions.setListCheckedBos([]))
      } else {
        updateStep(STEP.fail)
        updateInvoiceSendFailedList(invoiceSendFailedList)
      }
      refetch()
    } else {
      successToast(
        'Gửi lại thông tin vé thành công',
        'Các vé đã chọn không nằm trong trường hợp gửi lại'
      )
      toggle()
    }
  } catch (error) {
    console.log('error', error)
  }
}
function* handleRefreshStatusInvoice({ payload }) {
  const { data, onSuccess, setLoading } = payload
  try {
    const response = yield call(eInvoiceApi.refreshStatusInvoiceApi, data.guid)
    if (response?.error?.code === 1) {
      successToast('Cập nhật trạng thái thành công', 'Vé đã được cập nhất trạng thái mới nhất')
      onSuccess()
    } else {
      errorToast('Cập nhật trạng thái thất bại', response.error.messageVN)
    }
    setLoading(false)
  } catch (error) {
    console.log('error', error)
  }
}
function* handleRefreshStatusMultipleInvoice({ payload }) {
  const { data, refetch } = payload
  try {
    function* process(data) {
      yield* data.map((x) => x)
    }
    let totalItemRefreshSuccess = 0
    for (const n of process(data)) {
      const response = yield call(eInvoiceApi.refreshStatusInvoiceApi, n.guid)
      if (response?.error?.code === 1) {
        totalItemRefreshSuccess += 1
      }
    }
    if (totalItemRefreshSuccess === data.length) {
      successToast('Cập nhật trạng thái thành công', 'Vé đã được cập nhất trạng thái mới nhất')
    } else {
      warningToast(
        'Cảnh báo',
        `Có ${totalItemRefreshSuccess}/${data.length} vé cập nhật thành công`
      )
    }
    yield put(eInvoiceActions.setListCheckedBos([]))
  } catch (error) {
    console.log('error', error)
  }
}
export default function* eInvoiceSaga() {
  yield takeLatest(eInvoiceActions.getEInvoiceList.type, handleGetEInvoiceList)
  yield takeLatest(eInvoiceActions.handleExport.type, handleExport)
  yield takeLatest(eInvoiceActions.cancelInvoice.type, handleCancelInvoice)
  yield takeLatest(eInvoiceActions.resendInvoice.type, handleResendInvoice)
  yield takeLatest(eInvoiceActions.resendMultipleInvoice.type, handleResendMultipleInvoice)
  yield takeLatest(eInvoiceActions.refreshStatusInvoice.type, handleRefreshStatusInvoice)
  yield takeLatest(
    eInvoiceActions.refreshStatusMultipleInvoice.type,
    handleRefreshStatusMultipleInvoice
  )
}
