import axios from 'axios'
import { REACT_APP_DASHBOARD_BASE_URL } from '../constants/env'
import { Redirect } from 'react-router-dom'
import { history } from '@ourSrc/history'
import { apiErrorToast } from '@ourSrc/utility/Utils'
import QueryString from 'qs'

const axiosClient = axios.create({
  baseURL: REACT_APP_DASHBOARD_BASE_URL,
  withCredentials: true
})

axiosClient.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const axiosExternal = axios.create({
  paramsSerializer: (params) =>
    QueryString.stringify(params, { arrayFormat: 'repeat', encode: false })
})

axiosExternal.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const config = ({ headers = {}, method, url, data }) => {
  const _config = { method, url, headers: { 'Content-type': 'application/json', ...headers } }
  if (method?.toLowerCase() === 'get') _config.params = data
  else _config.data = data
  return _config
}

export const fetch = async (method, url, params, headers = {}) => {
  const config = { method, url, headers: { 'Content-type': 'application/json', ...headers } }

  if (method.toLowerCase() === 'get') config.params = params
  else config.data = params

  let response = null
  try {
    response = await axiosClient(config)
  } catch (error) {
    console.log('error', error)
    if (error.response.status === 401) window.location = '/login'
    // else if (error.response.status === 403) apiErrorToast(`Bạn không có quyền truy cập vào ${url}`)
  }

  return response
}

export default axiosClient
