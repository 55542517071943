import { call, put, takeLatest } from '@redux-saga/core/effects'
import customerApi from '@ourSrc/api/customerApi'
import { newCustomerActions } from './newCustomer.slice'
import { successToast, apiErrorToast } from '@ourSrc/utility/Utils'
import { uploadImagesFromObject } from '@ourSrc/utility/Upload'

function* handleAddCustomer({ payload: { body, onCreateCustomerSuccess } }) {
  try {
    const fileUrls = yield call(uploadImagesFromObject, body)
    const params = { ...body, ...fileUrls }
    const res = yield call(customerApi.addCustomer, params)

    if (res.data) {
      yield put(newCustomerActions.addCustomerSuccess(res.data))
      onCreateCustomerSuccess()
      successToast('Thông báo', 'Tạo khách hàng thành công!')
    } else {
      apiErrorToast(res.error.messageVN)
      yield put(newCustomerActions.addCustomerFailed())
    }
  } catch (error) {
    apiErrorToast('Đã có lỗi xảy ra')
    yield put(newCustomerActions.addCustomerFailed())
  }
}

export default function* newCustomerSaga() {
  yield takeLatest(newCustomerActions.addCustomer.type, handleAddCustomer)
}
