import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  error: false,
  data: []
}

const userChartSlice = createSlice({
  name: 'userChart',
  initialState,
  reducers: {
    getUserChart: (state) => {
      state.loading = true
    },

    getUserChartSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },

    getUserChartFailed: (state) => {
      state.loading = false
      state.error = true
    }
  }
})

export const userChartActions = userChartSlice.actions

const userChartReducer = userChartSlice.reducer
export default userChartReducer
