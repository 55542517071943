import reportApi from '@ourSrc/api/reportApi'
import { call, put, takeLatest } from '@redux-saga/core/effects'

import { userChartActions } from '../slices/userChart.saga'

const calculateTotal = (item, target) => {
  let total = 0
  for (const field in item) {
    if (field.includes(target)) total += item[field] || 0
  }
  return total
}

const convertUserChartData = (data, year) => {
  return data.map((month, idx) => ({
    name: `T${idx + 1} ${year}`,
    general: month.total_ticket_customer_1,
    college: month.total_ticket_customer_2,
    elder: month.total_ticket_customer_3,
    child: month.total_ticket_customer_4,
    disability: month.total_ticket_customer_5,
    poor: month.total_ticket_customer_6,
    student: month.total_ticket_customer_7,
    control: month.total_ticket_customer_8,
    total: calculateTotal(month, 'total_ticket_customer_')
  }))
}

function* handleGetUserChart({ payload }) {
  const { params } = payload
  params.paymentStatus = 2
  try {
    const response = yield call(reportApi.getCustomerTotalByYear, params)
    let data = convertUserChartData(response.data, params.year || '')
    data = data.filter((i) => i.total !== 0).length !== 0 ? data : []
    yield put(userChartActions.getUserChartSuccess(data))
  } catch (error) {
    yield put(userChartActions.getUserChartFailed())
    console.log('error', error)
  }
}

export default function* userChartSaga() {
  yield takeLatest(userChartActions.getUserChart.type, handleGetUserChart)
}
