import { createSlice } from '@reduxjs/toolkit'

const formatData = (data) =>
  data.map((route, idx) => ({
    ...route,
    index: idx + 1
  }))

const initialState = {
  loading: false,
  error: false,
  data: []
}

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    getRoutes: (state) => {
      state.loading = true
      state.error = false
      state.data = []
    },

    getRoutesSuccess: (state, action) => {
      state.loading = false
      state.error = false
      state.data = formatData(action.payload)
    },

    getRoutesFailed: (state) => {
      state.loading = false
      state.error = true
    }
  }
})

export const routeActions = routeSlice.actions

const routeReducer = routeSlice.reducer
export default routeReducer
