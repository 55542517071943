import { all } from 'redux-saga/effects'

import customerListSaga from '@ourSrc/views/center/customer-list/customerList.saga'
import monthlyTicketWithEnterpriseSaga from '@ourSrc/views/center/monthy-ticket-with-enterprise/monthlyTicketWithEnterprise.saga'
import monthlyTicketWithZionSaga from '@ourSrc/views/center/monthy-ticket-with-zion/monthlyTicketWithZion.saga'
import periodicReportSaga from '@ourSrc/views/center/periodic-report/periodicReport.saga'
import allCustomersSaga from '@ourSrc/views/customer/all-customers/allCustomers.saga'
import newCustomerSaga from '@ourSrc/views/customer/new-customer/newCustomer.saga'
import promotionListSaga from '@ourSrc/views/customer/promotion-list/promotionList.saga'
import queryCustomerSaga from '@ourSrc/views/customer/query-customer/queryCustomer.saga'
import eInvoiceSaga from '@ourSrc/views/e-invoice/export/saga'
import carListSaga from '@ourSrc/views/enterprise/car-list/carList.saga'
import enterpriseListSaga from '@ourSrc/views/enterprise/enterprise-list/enterpriseList.saga'
import errorSystemSaga from '@ourSrc/views/error-system/saga'
import syncDataSaga from '@ourSrc/views/error-system/synchronize-data/saga'
import eventListSaga from '@ourSrc/views/event/event-list/saga'
import recipientsSaga from '@ourSrc/views/event/promotion-recipients/saga'
import monthlyTicketAccounting from '@ourSrc/views/finance/accounting-monthy-ticket/monthlyTicketAccounting.saga'
import cashFlowSaga from '@ourSrc/views/finance/cash-flow-management/saga'
import revenueChartSaga from '@ourSrc/views/home/sagas/revenueChart.saga'
import ticketChartSaga from '@ourSrc/views/home/sagas/ticketChart.saga'
import userChartSaga from '@ourSrc/views/home/sagas/userChart.saga'
import montlyTicketSaga from '@ourSrc/views/monthly-ticket/saga'
import carReportSaga from '@ourSrc/views/reports/car-report/carReport.saga'
import inventoryReportSaga from '@ourSrc/views/reports/inventory-reports/inventoryReport.saga'
import monthlyTicketWithEnterpriseA1Saga from '@ourSrc/views/reports/monthy-ticket-with-enterprise/monthlyTicketWithEnterprise.saga'
import routeReportSaga from '@ourSrc/views/reports/route-report/routeReport.saga'
import stationReportSaga from '@ourSrc/views/reports/station-report/stationReport.saga'
import historyReportA2Saga from '@ourSrc/views/reportsA2/history-report/historyReportA2.saga'
import changePasswordSaga from '@ourSrc/views/setting/change-password/changePassword.saga'
import permissionListSaga from '@ourSrc/views/setting/permission-list/permissionList.saga'
import roleListSaga from '@ourSrc/views/setting/role-list/roleList.saga'
import userListSaga from '@ourSrc/views/setting/user-list/userList.saga'
import tempReceipts from '@ourSrc/views/temp-receipts/saga'
import unipassSaga from '@ourSrc/views/unipass/saga'
import vietbankHistorySaga from '@ourSrc/views/vietbank/vietbank-history/vietbankHistory.saga'
import vngBusSaga from '@ourSrc/views/vng-bus/saga'
import authSaga from './auth.saga'
import carSaga from './car.saga'
import routeSaga from './route.saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    carSaga(),
    routeSaga(),
    revenueChartSaga(),
    ticketChartSaga(),
    userChartSaga(),
    carReportSaga(),
    inventoryReportSaga(),
    routeReportSaga(),
    stationReportSaga(),
    customerListSaga(),
    periodicReportSaga(),
    monthlyTicketWithZionSaga(),
    monthlyTicketWithEnterpriseSaga(),
    monthlyTicketWithEnterpriseA1Saga(),
    monthlyTicketAccounting(),
    permissionListSaga(),
    changePasswordSaga(),
    roleListSaga(),
    userListSaga(),
    vietbankHistorySaga(),
    enterpriseListSaga(),
    carListSaga(),
    allCustomersSaga(),
    promotionListSaga(),
    queryCustomerSaga(),
    newCustomerSaga(),
    montlyTicketSaga(),
    historyReportA2Saga(),
    errorSystemSaga(),
    syncDataSaga(),
    cashFlowSaga(),
    recipientsSaga(),
    eventListSaga(),
    eInvoiceSaga(),
    vngBusSaga(),
    tempReceipts(),
    unipassSaga()
  ])
}
