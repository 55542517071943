import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  isLoggedIn: false,
  verified: false,
  lock: false,
  user: {},
  errors: { login: '', resetPassword: '', register: { email: '', password: '' } },
  roles: [],
  permissions: [],
  isRoot: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.loading = true
    },
    loginSuccess: (state, action) => {
      state.loading = false
      state.verified = action.payload.verified
      state.roles = action.payload.roles
      state.permissions = action.payload.permissions
      state.isRoot = action.payload.isRoot
    },
    loginFailed: (state, action) => {
      state.loading = false
      state.isLoggedIn = false
      state.roles = []
      state.permissions = []
      state.lock = action?.payload?.lock
      state.errors = {
        ...state.errors
        // login:
        //   'Mật khẩu hoặc email không chính xác. Hãy thử lại hoặc nhấp vào “Quên mật khẩu?” để đặt lại mật khẩu.'
      }
    },
    clearErrorsLogin: (state) => {
      state.errors = { ...state.errors, login: '' }
    },
    register: (state) => {},
    registerSuccess: (state) => {
      state.errors = { ...state.errors, register: '' }
    },
    registerFailed: (state, action) => {
      state.errors = { ...state.errors, register: action.payload }
    },
    clearErrorsRegister: (state) => {
      state.errors = { ...state.errors, register: { email: '', password: '' } }
    },
    verifyEmail: (state) => {},
    verifyEmailSuccess: (state, action) => {},
    verifyEmailFailed: (state) => {},
    logout: (state) => {},
    logoutSuccess: (state) => {
      state.isLoggedIn = false
    },
    getInfo: (state) => {},
    recoveryPassword: (state, action) => {
      state.loading = true
    },
    recoveryPasswordSuccess: (state, action) => {
      state.loading = false
    },
    recoveryPasswordFailed: (state, action) => {
      state.loading = false
    },
    resetPassword: (state, action) => {},
    resetPasswordSuccess: (state, action) => {},
    resetPasswordFailed: (state) => {
      state.errors = {
        ...state.errors,
        resetPassword:
          'Vui lòng chọn một mật khẩu có tính bảo mật cao hơn. Hãy thử kết hợp các chữ cái, số và ký hiệu.'
      }
    },
    clearErrorsResetPassword: (state) => {
      state.errors = { ...state.errors, resetPassword: '' }
    },

    getInfoSuccess: (state, action) => {
      state.isLoggedIn = true
      state.user = action.payload
    },
    getError: (state) => {},
    getErrorSuccess: (state, action) => {
      state.errors = action.payload
    },
    logoutAllDevice: (state) => {},
    loginWithOidc: (state) => {}
  }
})

export const authActions = authSlice.actions

const authReducer = authSlice.reducer
export default authReducer
