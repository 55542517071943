import { createSlice } from '@reduxjs/toolkit'

const formatData = data => {
  return data.map((enterprise, idx) => ({
    index: idx + 1,
    ...enterprise
  }))
}

const initialState = {
  data: [],
  loading: false,
  updating: false,
  adding: false,
  error: false
}

const enterpriseListSlice = createSlice({
  name: 'enterpriseList',
  initialState,
  reducers: {
    getEnterpriseList: (state) => {
      state.loading = true
      state.error = false
      state.data = []
    },
    getEnterpriseListSuccess: (state, action) => {
      state.loading = false
      state.data = formatData(action.payload.data)
    },

    getEnterpriseListFailed: (state) => {
      state.loading = false
      state.error = true
      state.data = []
    },

    updateEnterprise: (state) => {
      state.updating = true
    },

    updateEnterpriseSuccess: (state, action) => {
      state.updating = false
      state.data = state.data.map(e => {
        if (action.payload.enterprise_id !== e.enterprise_id) return e
        return { ...e, ...action.payload }
      })
    },

    updateEnterpriseFailed: (state) => {
      state.updating = false
    },

    addEnterprise: (state) => {
      state.adding = true
    },

    addEnterpriseSuccess: (state, action) => {
      state.adding = false
      state.data = formatData([...state.data, action.payload])
    },

    addEnterpriseFailed: (state) => {
      state.adding = false
    }
  }
})

export const enterpriseListActions = enterpriseListSlice.actions

const enterpriseListReducer = enterpriseListSlice.reducer
export default enterpriseListReducer
