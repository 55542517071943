import { fetch } from '.'

const syncDataApi = {
  getListDataRoot(params) {
    const url = '/v3/outbound/validation/get-cars'
    return fetch('post', url, params)
  },
  getListDataLv1(params) {
    const url = '/v3/outbound/validation/get-dates'
    return fetch('post', url, params)
  },
  getListDataLv2(params) {
    const url = '/v3/outbound/validation/get-turns'
    return fetch('post', url, params)
  },
  getReportDay(params) {
    const url = '/v3/outbound/validation/get-turn-reports'
    return fetch('post', url, params)
  },
  updateStatusValidate(params, turnCode) {
    const url = `/v3/outbound/validation/turn/${turnCode}`
    return fetch('put', url, params)
  },
  updateNote(params, turnCode) {
    const url = `/v3/outbound/validation//turn/${turnCode}/note`
    return fetch('put', url, params)
  },
  //history
  getHistory(params) {
    const url = `/v3/device/report/history`
    return fetch('post', url, params)
  },
  //checkDeviceStatus
  getSettings() {
    const url = `/v3/device/setting`
    return fetch('get', url, null)
  },
  getDeviceStatus(params) {
    const url = `/v3/device/report/list`
    return fetch('post', url, params)
  },
  getDeviceStatusSummary(params) {
    const url = `/v3/device/report/summary`
    return fetch('post', url, params)
  },
  updateStatus(deviceId, body) {
    const url = `/v3/device/id/${deviceId}`
    return fetch('put', url, body)
  },
  updateTimeDelay(body) {
    const url = `/v3/device/setting`
    return fetch('put', url, body)
  }
}

export default syncDataApi
