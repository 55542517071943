import errorApi from '@ourSrc/api/errorApi'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { errorSystemActions } from './slice'
import { successToast, errorToast, formatVnFullDate } from '@ourUtils'
import { STATUS } from '@ourSrc/constants/dataType'

import _ from 'lodash'
function* handleGetBlacklistZalopay({ payload }) {
  const { params } = payload
  try {
    const response = yield call(errorApi.getBlackListZalopay, params)
    if (response?.error?.code === 1) {
      const { offset } = response?.data
      const formatData = response?.data?.items?.map((item, key) => {
        item.index = offset + key + 1
        return item
      })
      const data = { ...response?.data, items: formatData }
      yield put(errorSystemActions.getBlacklistZalopaySuccess(data))
    } else {
      yield put(errorSystemActions.getBlacklistZalopayFailed())
    }
  } catch (error) {
    console.log('error', error)
    yield put(errorSystemActions.getBlacklistZalopayFailed())
  }
}
function* handleDeleteBlacklistZalopay({ payload }) {
  try {
    const { filter } = yield select((state) => state.errorSystem.zalopay)
    //delete 1 invoice
    if (typeof payload === 'number') {
      const response = yield call(errorApi.deleteBlackListZalopay, payload)
      if (response?.error?.code === 1) {
        successToast('Thông báo', 'Xóa dữ liệu thành công')
      } else {
        errorToast('Thất bại', 'Xóa dữ liệu thất bại')
      }
    } else if (payload?.length) {
      let itemSuccess = 0
      let itemProcessed = 0
      for (let i = 0; i < payload?.length; i++) {
        itemProcessed = itemProcessed + 1
        const response = yield call(errorApi.deleteBlackListZalopay, payload?.[i]?.id)
        if (response?.error?.code === 1) {
          itemSuccess = itemSuccess + 1
        }
      }
      if (itemSuccess === payload?.length) {
        yield put(errorSystemActions.setListCheckedZalopay([]))
        successToast('Thành công', 'Xóa dữ liệu thành công')
      } else {
        errorToast('Thất bại', 'Xóa dữ liệu thất bại')
      }
    }
    yield handleGetBlacklistZalopay({ payload: { params: filter } })
    yield put(errorSystemActions.deleteBlacklistZalopayFinish())
  } catch (error) {
    console.log('error', error)
  }
}

function* handleRepayZalopay({ payload }) {
  try {
    if (payload?.length) {
      let itemSuccess = 0
      let listItemWhenRemoveSuccess = [...payload]
      for (let i = 0; i < payload.length; i++) {
        const response = yield call(errorApi.repayZalopay, payload?.[i])
        if (response?.error?.code !== 1) {
          errorToast('Thất bại', `Thanh toán lại thất bại`)
        } else {
          listItemWhenRemoveSuccess = listItemWhenRemoveSuccess.filter(
            (item) => !_.isEqual(item, payload[i])
          )
          itemSuccess = itemSuccess + 1
        }
      }
      if (payload.length === itemSuccess) {
        successToast('Thành công', 'Thanh toán lại thành công')
      }
      yield put(errorSystemActions.setListCheckedZalopay(listItemWhenRemoveSuccess))
    } else {
      const response = yield call(errorApi.repayZalopay, payload)
      if (response?.error?.code === 1) {
        successToast('Thành công', 'Thanh toán lại thành công')
      } else {
        errorToast('Thất bại', `Thanh toán lại thất bại`)
      }
    }
    yield put(errorSystemActions.repayZalopayFinish())
    const { filter } = yield select((state) => state.errorSystem.zalopay)
    yield handleGetBlacklistZalopay({ payload: { params: filter } })
  } catch (error) {
    console.log('error', error)
  }
}

//bos
function* handleGetListErrorBos({ payload }) {
  const { params } = payload
  try {
    const response = yield call(errorApi.getListErrorBos, params)
    if (response?.error?.code === 1) {
      const { offset, limit } = response?.data
      const formatData = response?.data?.items?.map((item, key) => {
        item.index = offset * limit + key + 1
        return item
      })
      const data = { ...response?.data, items: formatData }
      yield put(errorSystemActions.getListErrorBosSuccess(data))
    } else {
      yield put(errorSystemActions.getListErrorBosFailed())
    }
  } catch (error) {
    console.log('error', error)
    yield put(errorSystemActions.getListErrorBosFailed())
  }
}
//bos
function* handleDeleteInvoiceErrorBos({ payload }) {
  try {
    const user = yield select((state) => state.auth.user)
    const { filter } = yield select((state) => state.errorSystem.bos)
    const body = {
      updater: user?.identity?.traits?.email
    }
    if (typeof payload === 'number') {
      const response = yield call(errorApi.deleteInvoiceErrorBos, payload, body)
      if (response?.error?.code === 1) {
        successToast('Thành công', 'Xóa dữ liệu thành công')
        yield handleGetListErrorBos({ payload: { params: filter } })
      } else {
        errorToast('Thất bại', 'Xóa dữ liệu thất bại')
      }
    } else if (payload?.length) {
      for (let i = 0; i < payload?.length; i++) {
        const response = yield call(errorApi.deleteInvoiceErrorBos, payload?.[i]?.id, body)
        if (response?.error?.code !== 1) {
          errorToast('Thất bại', 'Xóa dữ liệu thất bại')
        }
      }
      yield put(errorSystemActions.setListCheckedBos([]))
      successToast('Thành công', 'Xóa dữ liệu thành công')
    }
    yield put(errorSystemActions.deleteInvoiceErrorBosFinish())
    yield handleGetListErrorBos({ payload: { params: filter } })
  } catch (error) {
    console.log('error', error)
  }
}
function* handleCreateInvoiceBos({ payload }) {
  try {
    const user = yield select((state) => state.auth.user)
    const body = {
      ...payload.body,
      updater: user?.identity?.traits?.email
    }
    const response = yield call(errorApi.createInvoiceBos, body)
    if (response) {
      if (response?.error?.code === 1) {
        const { filter } = yield select((state) => state.errorSystem.bos)
        if (filter?.fromDate) yield handleGetListErrorBos({ payload: { params: filter } })
        successToast('Thành công', 'Tạo dữ liệu thành công')
        yield put(errorSystemActions.createInvoiceBosSuccess())
      } else {
        errorToast('Thất bại', `Tạo dữ liệu thất bại. ${response?.error?.messageVN}`)
      }
    } else {
      errorToast('Thất bại', `Bạn không có quyền để thực hiện thao tác này`)
    }
    yield put(errorSystemActions.createInvoiceBosFinish())
  } catch (error) {
    errorToast('Thất bại', `Tạo dữ liệu thất bại. ${error}`)
    console.log('error', error)
  }
}

function* handleUpdateInvoiceBos({ payload }) {
  try {
    const user = yield select((state) => state.auth.user)
    const body = {
      ...payload.body,
      updater: user?.identity?.traits?.email
    }
    const response = yield call(errorApi.updateInvoiceBos, body.id, body)
    if (response) {
      if (response?.error?.code === 1) {
        const { filter } = yield select((state) => state.errorSystem.bos)
        successToast('Thành công', 'Chỉnh sửa dữ liệu thành công')
        yield put(errorSystemActions.updateInvoiceBosSuccess())
        yield handleGetListErrorBos({ payload: { params: filter } })
      } else {
        errorToast('Thất bại', `Chỉnh sửa dữ liệu thất bại. ${response?.error?.messageVN}`)
      }
    } else {
      errorToast('Thất bại', `Bạn không có quyền để thực hiện thao tác này`)
    }
    yield put(errorSystemActions.updateInvoiceBosFinish())
  } catch (error) {
    errorToast('Thất bại', `Chỉnh sửa dữ liệu thất bại. ${error}`)
    yield put(errorSystemActions.updateInvoiceBosFinish())
    console.log('error', error)
  }
}
function* handleSyncInvoiceBos({ payload }) {
  try {
    const user = yield select((state) => state.auth.user)
    const { filter } = yield select((state) => state.errorSystem.bos)

    if (typeof payload === 'number') {
      const body = {
        requestId: payload,
        updater: user?.identity?.traits?.email
      }
      const response = yield call(errorApi.syncInvoiceBos, body)
      if (response?.error?.code === 1) {
        successToast('Thành công', 'Dữ liệu đồng bộ thành công')
      } else {
        errorToast('Thất bại', `Dữ liệu đồng bộ thất bại. ${response?.error?.messageVN}`)
      }
    } else if (payload?.length) {
      let itemSuccess = 0
      let itemProcessed = 0
      let listItemWhenRemoveSuccess = [...payload]
      for (let i = 0; i < payload?.length; i++) {
        itemProcessed = itemProcessed + 1
        const body = {
          requestId: payload?.[i]?.id,
          updater: user?.identity?.traits?.email
        }
        const response = yield call(errorApi.syncInvoiceBos, body)
        if (response?.error?.code === 1) {
          itemSuccess = itemSuccess + 1
          listItemWhenRemoveSuccess = listItemWhenRemoveSuccess.filter(
            (item) => !_.isEqual(item, payload[i])
          )
        }
        yield put(
          errorSystemActions.syncInvoiceBosSuccess({
            total: payload?.length,
            itemSuccess,
            itemProcessed
          })
        )
      }
      yield put(errorSystemActions.setListCheckedBos(listItemWhenRemoveSuccess))
      yield put(errorSystemActions.syncInvoiceBosFinish())
      if (itemSuccess === payload?.length) {
        successToast('Thành công', 'Dữ liệu đồng bộ thành công')
      } else {
        errorToast('Thất bại', `Dữ liệu đồng bộ thất bại.`)
      }
    }
    yield handleGetListErrorBos({ payload: { params: filter } })
  } catch (error) {
    errorToast('Thất bại', `Dữ liệu đồng bộ thất bại. ${error}`)
    console.log('error', error)
  }
}
function* handleUpdateAndSyncInvoiceBos({ payload }) {
  try {
    const user = yield select((state) => state.auth.user)
    const body = {
      ...payload.body,
      updater: user?.identity?.traits?.email
    }
    const response = yield call(errorApi.updateInvoiceBos, body.id, body)
    if (response) {
      if (response?.error?.code === 1) {
        const { filter } = yield select((state) => state.errorSystem.bos)
        successToast('Thành công', 'Chỉnh sửa dữ liệu thành công')
        yield put(errorSystemActions.updateInvoiceBosSuccess())
        yield handleGetListErrorBos({ payload: { params: filter } })
        yield handleSyncInvoiceBos({ payload: payload?.body?.id })
      } else {
        errorToast(
          'Thất bại',
          `Chỉnh sửa dữ liệu thất bại, quá trình đồng bộ sẽ không thể diễn ra. (${response?.error?.messageVN})`
        )
      }
    } else {
      errorToast('Thất bại', `Bạn không có quyền để thực hiện thao tác này`)
    }
    yield put(errorSystemActions.updateInvoiceBosFinish())
  } catch (error) {
    console.log('error', error)
  }
}
function* handleCreateAndSyncInvoiceBos({ payload }) {
  try {
    const user = yield select((state) => state.auth.user)
    const body = {
      ...payload.body,
      updater: user?.identity?.traits?.email
    }
    const response = yield call(errorApi.createInvoiceBos, body)
    if (response) {
      if (response?.error?.code === 1) {
        const { filter } = yield select((state) => state.errorSystem.bos)
        if (filter?.fromDate) yield handleGetListErrorBos({ payload: { params: filter } })
        successToast('Thành công', 'Tạo dữ liệu thành công')
        yield put(errorSystemActions.createInvoiceBosSuccess())
        yield handleSyncInvoiceBos({ payload: response?.data?.id })
      } else {
        errorToast('Thất bại', `Tạo dữ liệu thất bại. ${response?.error?.messageVN}`)
      }
    } else {
      errorToast('Thất bại', `Bạn không có quyền để thực hiện thao tác này`)
    }
    yield put(errorSystemActions.createInvoiceBosFinish())
  } catch (error) {
    console.log('error', error)
  }
}

function* handleExportBosError({ payload }) {
  const { params, onSuccess } = payload
  try {
    const response = yield call(errorApi.getListErrorBos, params)
    if (response?.error?.code === 1) {
      const { offset, limit } = response?.data
      const formatData = response?.data?.items?.map((item, key) => {
        item.index = offset * limit + key + 1
        item.status = STATUS[item.status].name
        item.createdTime = formatVnFullDate(item.createdTime)
        return item
      })
      onSuccess(formatData)
    }
  } catch (error) {
    console.log('error', error)
  }
}
function* handleExportZalopayError({ payload }) {
  const { params, onSuccess } = payload
  try {
    const response = yield call(errorApi.getBlackListZalopay, params)
    if (response?.error?.code === 1) {
      const { offset } = response?.data
      const formatData = response?.data?.items?.map((item, key) => {
        item.index = offset + key + 1
        item.status = STATUS[item.status].name
        item.created_date_time = formatVnFullDate(item.created_date_time)
        return item
      })
      onSuccess(formatData)
    }
  } catch (error) {
    console.log('error', error)
  }
}
export default function* errorSystemSaga() {
  yield takeLatest(errorSystemActions.getBlacklistZalopay.type, handleGetBlacklistZalopay)
  yield takeLatest(errorSystemActions.deleteBlacklistZalopay.type, handleDeleteBlacklistZalopay)
  yield takeLatest(errorSystemActions.repayZalopay.type, handleRepayZalopay)
  yield takeLatest(errorSystemActions.handleExportZalopayError.type, handleExportZalopayError)

  yield takeLatest(errorSystemActions.getListErrorBos.type, handleGetListErrorBos)
  yield takeLatest(errorSystemActions.deleteInvoiceErrorBos.type, handleDeleteInvoiceErrorBos)
  yield takeLatest(errorSystemActions.createInvoiceBos.type, handleCreateInvoiceBos)
  yield takeLatest(errorSystemActions.createAndSyncInvoiceBos.type, handleCreateAndSyncInvoiceBos)
  yield takeLatest(errorSystemActions.updateInvoiceBos.type, handleUpdateInvoiceBos)
  yield takeLatest(errorSystemActions.updateAndSyncInvoiceBos.type, handleUpdateAndSyncInvoiceBos)
  yield takeLatest(errorSystemActions.syncInvoiceBos.type, handleSyncInvoiceBos)
  yield takeLatest(errorSystemActions.handleExportBosError.type, handleExportBosError)
}
