import { createSlice } from '@reduxjs/toolkit'
import _cloneDeep from 'lodash/cloneDeep'

const initialState = {
  forms: [],

  isDirty: false,
  confirmModal: {
    title: 'modal.confirm_close.title',
    desc: 'modal.confirm_close.desc',
    confirmMsg: 'modal.confirm_close.confirm',
    cancelMsg: 'modal.confirm_close.cancel',
    isOpen: false,
    confirm: () => {}
  }
}

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    reset: (state) => {
      const cloned = _cloneDeep(state.forms)
      cloned.pop()
      state.forms = cloned
      state.confirmModal = initialState.confirmModal
    },

    setFormDirty: (state, action) => {},

    setNewForm: (state, action) => {
      const item = { name: action.payload.name, isDirty: false }

      const cloned = _cloneDeep(state.forms)
      const index = cloned.findIndex((i) => i.name === action.payload.name)
      if (index === -1) cloned.push(item)
      else cloned[index] = { ...item }

      state.forms = cloned
    },

    setIsDirtyWithKey: (state, action) => {
      const cloned = _cloneDeep(state.forms)
      if (cloned.length !== 0) {
        const form = cloned.find((i) => i.name === action.payload.name)
        if (form) form.isDirty = action.payload.isDirty
        state.forms = cloned
      }
    },

    setIsDirty: (state, action) => {
      const cloned = _cloneDeep(state.forms)
      if (cloned.length !== 0) {
        const latestForm = cloned[cloned.length - 1]
        latestForm.isDirty = action.payload
        state.forms = cloned
      }
    },

    toggleConfirmClose: (state, action) => {
      state.confirmModal = {
        ...state.confirmModal,
        title: action?.payload?.title || 'modal.confirm_close.title',
        desc: action?.payload?.desc || 'modal.confirm_close.desc',
        confirmMsg: action?.payload?.confirmMsg || 'modal.confirm_close.confirm',
        cancelMsg: action?.payload?.cancelMsg || 'modal.confirm_close.cancel',
        isOpen: !state.confirmModal.isOpen,
        confirm: action?.payload?.confirm ? action.payload.confirm : () => {}
      }
    }
  }
})

export const formActions = formSlice.actions

const formReducer = formSlice.reducer
export default formReducer
