export const APPROVAL_STATUS = {
  all: {
    value: undefined,
    label: 'Tất cả'
  },
  pending: {
    value: '0',
    label: 'Đang chờ xét duyệt'
  },
  approved: {
    value: '1',
    label: 'Đã duyệt'
  },
  rejected: {
    value: '2',
    label: 'Bị từ chối'
  }
}