import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  total: {},
  loading: false,
  error: false
}

const historyReportA2Slice = createSlice({
  name: 'historyReportA2',
  initialState,
  reducers: {
    getHistoryReportA2: (state) => {
      state.data = []
      state.loading = true
      state.error = false
    },
    getHistoryReportA2Success: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    getHistoryReportA2Failed: (state) => {
      state.loading = false
      state.error = true
    },
    getHistoryReportA2Total: (state, action) => {
      state.total = action.payload
    }
  }
})

export const historyReportA2Actions = historyReportA2Slice.actions

const historyReportA2Reducer = historyReportA2Slice.reducer
export default historyReportA2Reducer
