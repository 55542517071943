import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  receiptsManagement: {
    data: {},
    loading: false,
    loadingExecute: false,
    selected: [],
    sumary: { totalCount: 0, reservedCount: 0, activatedCount: 0, canceledCount: 0 }
  },
  symbolManagement: { data: {}, loading: false },
  turnManagement: { data: {}, loading: false },
  enterpriseOpts: [],
  denominatorList: [],
  carCodeList: [],
  routeList: [],
  carList: [],
  turnList: [],
  ticketTypeList: [],
  prices: []
}

const tempReceiptsSlice = createSlice({
  name: 'tempReceipts',
  initialState,
  reducers: {
    getTempReceiptsList: (state) => {
      state.receiptsManagement.loading = true
      state.receiptsManagement.data = {}
      state.receiptsManagement.sumary = {
        totalCount: 0,
        reservedCount: 0,
        activatedCount: 0,
        canceledCount: 0
      }
    },
    getTempReceiptsListDone: (state, action) => {
      state.receiptsManagement.loading = false
      state.receiptsManagement.data = action.payload
    },
    setSumary: (state, action) => {
      state.receiptsManagement.sumary = action.payload
    },
    addNote: () => {},
    createReceipts: (state, action) => {},
    deleteReceipts: () => {},
    setListCheckedReceipts: (state, action) => {
      state.receiptsManagement.selected = action.payload
    },
    activeReceipts: () => {},
    exportExcel: () => {},
    //symbol
    getSymbol: (state) => {
      state.symbolManagement.data = {}
      state.symbolManagement.loading = true
    },
    getSymbolDone: (state, action) => {
      state.symbolManagement.data = action.payload
      state.symbolManagement.loading = false
    },
    createSymbol: () => {},
    updateSymbol: () => {},
    getEnterpriseOptions: () => {},
    getEnterpriseOptionsDone: (state, action) => {
      state.enterpriseOpts = action.payload
    },
    getDenomination: () => {},
    getDenominationDone: (state, action) => {
      state.denominatorList = action.payload
    },
    getCarCode: (state) => {
      state.carCodeList = []
      state.ticketTypeList = []
      state.prices = []
    },
    getCarCodeDone: (state, action) => {
      state.carCodeList = action.payload.carCode
      state.ticketTypeList = action.payload.ticketTypes
      state.prices = action.payload.prices
    },
    checkValidSerial: () => {},
    //turn
    getTurn: (state) => {
      state.turnManagement.loading = true
    },
    getTurnDone: (state, action) => {
      state.turnManagement.loading = false
      state.turnManagement.data = action.payload
    },
    finishTurn: () => {},
    getRouteOpts: () => {},
    setRouteOpts: (state, action) => {
      state.routeList = action.payload
    },
    getCarOpts: () => {},
    setCarOpts: (state, action) => {
      state.carList = action.payload
    },
    createTurn: () => {},
    getTurnOpts: () => {},
    getTurnOptsDone: (state, action) => {
      state.turnList = action.payload
    },
    getRouteOptsByAmountAndTicketTypeId: () => {}
  }
})

export const tempReceiptsActions = tempReceiptsSlice.actions

const tempReceiptsReducer = tempReceiptsSlice.reducer
export default tempReceiptsReducer
