export const QUERY_TYPE = {
  card: {
    value: 'cardNoOrigin',
    label: 'Mã thẻ'
  },
  cardHash: {
    value: 'cardNo',
    label: 'Mã thẻ đã hash'
  },
  phone: {
    value: 'phone',
    label: 'Số điện thoại'
  }
}

export const USER_TYPE = {
  other: {
    value: 0,
    label: 'Khác'
  },
  general: {
    value: 1,
    label: 'Phổ thông'
  },
  college: {
    value: 2,
    label: 'Sinh viên'
  },
  elder: {
    value: 3,
    label: 'Người cao tuổi'
  },
  child: {
    value: 4,
    label: 'Trẻ em'
  },
  disability: {
    value: 5,
    label: 'Khuyết tật/thương binh'
  },
  poor: {
    value: 6,
    label: 'Người nghèo'
  },
  student: {
    value: 7,
    label: 'Học sinh'
  },
  control: {
    value: 8,
    label: 'Nhân viên kiểm soát'
  }
}

export const TRANSACTION_STATUS = {
  all: {
    value: 0,
    label: 'Tất cả'
  },
  success: {
    value: 2,
    label: 'Thành công'
  },
  failed: {
    value: 3,
    label: 'Thất bại'
  }
  // refund: {
  //   value: undefined,
  //   label: 'Hoàn tiền'
  // }
}

export const GENDER = {
  male: { label: 'Nam', value: 0 },
  female: { label: 'Nữ', value: 1 }
}

export const CARD_STATUS = {
  inactive: { value: 0, label: 'Đang tạm ngưng hoạt động' },
  active: { value: 1, label: 'Đang hoạt động' },
  expired: { value: 2, label: 'Hết hạn' }
}
export const ACCOUNT_STATUS = {
  inactive: { value: 0, label: 'Đang tạm ngưng hoạt động' },
  active: { value: 1, label: 'Đang hoạt động' }
}

export const CUSTOMER_TYPE = {
  general: {
    value: 4,
    label: 'Vé phổ thông'
  },
  priority: {
    value: 5,
    label: 'Vé giảm'
  },
  free: {
    value: 6,
    label: 'Vé miễn'
  }
}
