import { put, takeLatest, select, call } from '@redux-saga/core/effects'
import _cloneDeep from 'lodash/cloneDeep'
import _groupBy from 'lodash/groupBy'
import _sumBy from 'lodash/sumBy'
import moment from 'moment'

import { syncDataActions } from './slice'
import syncDataApi from '@ourSrc/api/syncDataApi'
import {
  apiErrorToast,
  warningToast,
  successToast,
  errorToast,
  formatVnFullDate
} from '@ourSrc/utility/Utils'
import { STATUS_VALIDATE_TICKET } from '@ourSrc/constants/syncData'
import { checkDeviceStatus } from '@ourSrc/constants/dataType'

const convertData = (data, keyField, index) => {
  return data.map((i) => {
    const {
      car_number,
      turn_number,
      server_total_tickets,
      client_total_tickets,
      validation_status
    } = i

    const newItem = {
      ...i
    }
    if (keyField === 'car_number') {
      newItem.key = car_number
      newItem.type = 'car'
    } else if (keyField === 'turn') {
      newItem.key = `Chuyến ${turn_number}`
      newItem.type = 'turn'
      if (validation_status) {
        newItem.validation_status_message = STATUS_VALIDATE_TICKET[validation_status]?.label
      }
      if (server_total_tickets) {
        for (const [key, value] of Object.entries(server_total_tickets)) {
          newItem[key] = value
        }
      }
      if (client_total_tickets) {
        for (const [key, value] of Object.entries(client_total_tickets)) {
          newItem[`${key}_client`] = value
        }
      }
    }
    return newItem
  })
}
const formatDataHistory = (data) =>
  data?.items?.map((item, key) => {
    item.index = data?.offset + key + 1
    item.createdTimeFormat = formatVnFullDate(item?.createdTime)
    item.actionNameVN = checkDeviceStatus.action?.[item?.actionName]?.label ?? item?.actionName
    item.statusAfterVN = checkDeviceStatus.status?.[item?.statusAfter]?.label
    item.deviceStatus = checkDeviceStatus.status?.[item?.status]?.label
    item.statusBeforeVN = checkDeviceStatus.status?.[item?.statusBefore]?.label
    item.updatedTimeFormat = formatVnFullDate(item?.updatedTime)
    item.turnTimeFinishFormat = formatVnFullDate(item?.turnTimeFinish)
    item.turnTimeStartFormat = formatVnFullDate(item?.turnTimeStart)
    item.eventTimeFormat = formatVnFullDate(item?.eventTime)
    item.disconnectTimeMinute = item?.disconnectTimeMs
      ? (item?.disconnectTimeMs / 60000).toFixed(0) + ' Phút'
      : null
    item.dayReportTimes = item?.dayReports?.length
    item.dayReportLists = formatVnFullDate(item?.dayReports?.at(-1))
    item.turnNumber = item?.turn?.split('_')?.at(-1)
    return item
  })

function* handleGetListData({ payload }) {
  const { params } = payload
  try {
    const response = yield call(syncDataApi.getListDataRoot, params)
    if (response?.data) {
      const dataFormat = convertData(response?.data?.items, 'car_number')
      yield put(syncDataActions.getListDataSuccess(dataFormat))
    }
  } catch (error) {}
}
function* handleGetLv1Data({ payload }) {
  const syncData = yield select((state) => state.syncData)
  const { params } = payload

  try {
    const response = yield call(syncDataApi.getListDataLv1, params)
    if (response?.data?.items?.length) {
      const syncNewData = _cloneDeep(syncData.data)
      const index = syncNewData.findIndex((e) => e.car_id === params.carId)
      const data = response.data.items.map((i) => {
        return {
          key: moment(i).format('DD/MM/YYYY'),
          type: 'date',
          created_date: i,
          parentIndex: index,
          car_id: params?.carId,
          route_number: params?.route_number,
          car_number: params?.car_number
        }
      })
      syncNewData[index].expand = data.length === 0 ? null : data

      yield put(syncDataActions.getLv1DataSuccess(syncNewData))
    } else {
      warningToast('Cảnh báo', 'Không có dữ liệu')
    }
  } catch (error) {
    yield put(syncDataActions.getLv1DataFailed())
  }
}
function* handleGetLv2Data({ payload }) {
  const syncData = yield select((state) => state.syncData)
  const { lv0Idx, lv1Idx, params, key } = payload
  try {
    const response = yield call(syncDataApi.getListDataLv2, params)
    const data = convertData(response?.data?.items, 'turn')
    if (data.length === 0) {
      apiErrorToast(
        `Không tìm thấy dữ liệu vào ngày ${moment(new Date(params.fromDate)).format(
          'DD/MM/YYYY'
        )} của xe ${key}`
      )
    }
    const syncNewData = _cloneDeep(syncData.data)
    syncNewData[lv0Idx].expand[lv1Idx] = {
      ...syncNewData[lv0Idx].expand[lv1Idx],
      expand: data.length === 0 ? null : data
    }
    // //note
    // let note = {}
    // data.forEach((element) => {
    //   note = {
    //     ...note,
    //     [element.id]: {
    //       value: element?.validation_note,
    //       step: element?.validation_note ? STEP_EDIT_NOTE.INITIAL_DATA : STEP_EDIT_NOTE.ADD
    //     }
    //   }
    // })
    // yield put(syncDataActions.updateNote(note))
    yield put(syncDataActions.getLv2DataSuccess(syncNewData))
  } catch (error) {
    console.log('error: ', error)
    yield put(syncDataActions.getLv2DataFailed())
  }
}

function* handleGetReportDetailDay({ payload }) {
  const { params } = payload
  try {
    const response = yield call(syncDataApi.getReportDay, params)
    if (response?.data) {
      yield put(syncDataActions.getReportDetailDaySuccess(response.data))
    }
  } catch (error) {
    yield put(syncDataActions.getLv2DataFailed())
  }
}

function* handleUpdateStatusValidate({ payload }) {
  const { body, onSuccess } = payload
  try {
    const response = yield call(syncDataApi.updateStatusValidate, body, body?.turn_code)
    if (response?.data) {
      const { data } = yield select((state) => state.syncData)
      let index = { idRoot: 0, idLv1: 0, idLv2: 0 }
      data.some((element, idRoot) => {
        if (element?.expand?.length) {
          return element.expand.some((itemLv1, idLv1) => {
            if (itemLv1?.expand?.length) {
              return itemLv1.expand.some((itemLv2, idLv2) => {
                if (itemLv2?.turn_code === body?.turn_code) {
                  index = {
                    idRoot: idRoot,
                    idLv1,
                    idLv2
                  }
                  return true
                } else return false
              })
            }
          })
        }
      })
      const syncNewData = _cloneDeep(data)
      syncNewData[index.idRoot].expand[index.idLv1].expand[index.idLv2] = {
        ...syncNewData[index.idRoot].expand[index.idLv1].expand[index.idLv2],
        validation_status: response?.data?.validation_status,
        validation_status_message: STATUS_VALIDATE_TICKET[response?.data?.validation_status]?.label
      }
      console.log('syncNewData: ', syncNewData)
      yield put(syncDataActions.updateStatusValidateSuccess(syncNewData))
      successToast('Thành công', 'Cập nhật trạng thái thành công')
      onSuccess(response.data?.validation_status)
    } else {
      errorToast('Thất bại', response?.error?.messageVN)
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

function* handleExecUpdateNote({ payload }) {
  try {
    const response = yield call(syncDataApi.updateNote, payload, payload?.turn_code)
    if (response?.data) {
      const { data } = yield select((state) => state.syncData)
      let index = { idRoot: 0, idLv1: 0, idLv2: 0 }
      data.some((element, idRoot) => {
        if (element?.expand?.length) {
          return element.expand.some((itemLv1, idLv1) => {
            if (itemLv1?.expand?.length) {
              return itemLv1.expand.some((itemLv2, idLv2) => {
                if (itemLv2?.turn_code === payload?.turn_code) {
                  index = {
                    idRoot: idRoot,
                    idLv1,
                    idLv2
                  }
                  return true
                } else return false
              })
            }
          })
        }
      })
      const syncNewData = _cloneDeep(data)
      syncNewData[index.idRoot].expand[index.idLv1].expand[index.idLv2] = {
        ...syncNewData[index.idRoot].expand[index.idLv1].expand[index.idLv2],
        validation_note: response?.data?.validation_note
      }
      yield put(syncDataActions.execUpdateNoteSuccess(syncNewData))
      successToast('Thành công', 'Cập nhật mô tả thành công')
    } else {
      yield put(syncDataActions.execUpdateNoteFail())
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

function* handleGetHistory({ payload }) {
  try {
    const { params } = payload
    const response = yield call(syncDataApi.getHistory, params)

    yield put(
      syncDataActions.getHistoryDone({
        ...response?.data,
        items: formatDataHistory(response?.data)
      })
    )
  } catch (error) {
    console.log('error: ', error)
  }
}

function* handleExportHistory({ payload }) {
  const { params, onSuccess, setLoadingExport } = payload
  try {
    setLoadingExport(true)
    const response = yield call(syncDataApi.getHistory, params)
    onSuccess(formatDataHistory(response?.data))
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoadingExport(false)
  }
}

//check device status
function* handleGetSettings() {
  try {
    const response = yield call(syncDataApi.getSettings)
    yield put(
      syncDataActions.getSettingsDone({
        ...response?.data,
        offlineThresholMinute: response?.data?.offlineThresholdMs / 60000 //parse ms to minutes
      })
    )
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetDeviceStatus({ payload }) {
  try {
    const { params } = payload
    const response = yield call(syncDataApi.getDeviceStatus, params)
    const responseSummary = yield call(syncDataApi.getDeviceStatusSummary, params)
    yield put(
      syncDataActions.getDeviceStatusDone({
        data: {
          ...response?.data,
          items: formatDataHistory(response?.data)
        },
        summary: { ...responseSummary?.data, total: _sumBy(Object.values(responseSummary?.data)) }
      })
    )
  } catch (error) {
    console.log('error: ', error)
  }
}

function* handleUpdateStatus({ payload }) {
  const { data, option, refetch, note, setLoadingConfirm, toggle } = payload
  try {
    const { email } = yield select((state) => state.auth.user.identity.traits)

    setLoadingConfirm(true)
    function* process(data) {
      yield* data.map((x) => x)
    }
    let totalItemSuccess = 0
    for (const n of process(data)) {
      const body = {
        ...n,
        note,
        status: option?.value,
        requester: email
      }
      const response = yield call(syncDataApi.updateStatus, n?.deviceId, body)

      if (response.error.code === 1) {
        totalItemSuccess += 1
      }
    }
    if (data?.length === totalItemSuccess) {
      successToast(
        'Thành công',
        <span>
          Thay đổi thành trạng thái <b>{checkDeviceStatus.status?.[option?.value]?.label}</b> thành
          công
        </span>
      )
    } else {
      warningToast(
        'Cảnh báo',
        <span>
          Có <b>{data?.length - totalItemSuccess}</b> chưa chuyển trạng thái thành công
        </span>
      )
    }
  } catch (error) {
    console.log('error: ', error)
  } finally {
    toggle()
    setLoadingConfirm(false)
    refetch()
    yield put(syncDataActions.deviceStatusSelected([]))
  }
}

function* handleExportDeviceStatus({ payload }) {
  const { params, onSuccess, setLoadingExport } = payload
  try {
    setLoadingExport(true)
    const response = yield call(syncDataApi.getDeviceStatus, params)
    onSuccess(formatDataHistory(response?.data))
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoadingExport(false)
  }
}

function* handleSettingDelay({ payload }) {
  const { time, setLoadingConfirm, toggle, getTimeDelay } = payload
  try {
    setLoadingConfirm(true)
    const minutes = new Date(time).getMinutes()
    const hours = new Date(time).getHours()
    const parseToMs = (hours * 60 + minutes) * 60000
    const response = yield call(syncDataApi.updateTimeDelay, { offlineThresholdMs: parseToMs })
    if (response?.error?.code === 1) {
      getTimeDelay()
      successToast('Thành công', 'Đã cập nhật độ trễ thành công')
      toggle()
    } else {
      errorToast('Thất bại', response.error?.messageVN)
    }
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoadingConfirm(false)
  }
}

export default function* syncDataSaga() {
  yield takeLatest(syncDataActions.getListData.type, handleGetListData)
  yield takeLatest(syncDataActions.getLv1Data.type, handleGetLv1Data)
  yield takeLatest(syncDataActions.getLv2Data.type, handleGetLv2Data)
  yield takeLatest(syncDataActions.getReportDetailDay.type, handleGetReportDetailDay)
  yield takeLatest(syncDataActions.updateStatusValidate.type, handleUpdateStatusValidate)
  yield takeLatest(syncDataActions.execUpdateNote.type, handleExecUpdateNote)
  //checkDeviceStatus
  yield takeLatest(syncDataActions.getSettings.type, handleGetSettings)
  yield takeLatest(syncDataActions.getDeviceStatus.type, handleGetDeviceStatus)
  yield takeLatest(syncDataActions.updateStatus.type, handleUpdateStatus)
  yield takeLatest(syncDataActions.exportDeviceStatus.type, handleExportDeviceStatus)
  yield takeLatest(syncDataActions.settingDelay.type, handleSettingDelay)

  //history
  yield takeLatest(syncDataActions.getHistory.type, handleGetHistory)
  yield takeLatest(syncDataActions.exportHistory.type, handleExportHistory)
}
