import reportApi from '@ourSrc/api/reportApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import { monthlyTicketWithEnterpriseA1Actions } from './monthlyTicketWithEnterprise.slice'
import { formatVnDate, errorToast, successToast, formatVnFullDate } from '@ourSrc/utility/Utils'
import moment from 'moment'
function* handleGetCenterMonthlyTicketWithEnterpriseA1GenaralReport({ payload }) {
  const { params } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketCenterEnterpriseGeneralReport, params)
    if (res?.data) {
      yield put(
        monthlyTicketWithEnterpriseA1Actions.setCenterMonthlyTicketWithEnterpriseA1GenaralReportTotal(
          res?.data.totalItem
        )
      )
      const data = res?.data?.items.map((item) => {
        item.reconcileCode = `#${item.reconcileCode}`
        item.createdDate = formatVnDate(item.createdDate)
        item.status_message = item.status === 0 ? 'Chờ xử lý' : 'Thành công'
        return item
      })
      yield put(
        monthlyTicketWithEnterpriseA1Actions.getCenterMonthlyTicketWithEnterpriseA1GenaralReportSuccess(
          data
        )
      )
    } else {
      yield put(
        monthlyTicketWithEnterpriseA1Actions.getCenterMonthlyTicketWithEnterpriseA1GenaralReportFailed()
      )
    }
  } catch (error) {
    console.log('error: ', error)
  }
}
function* handleGetCenterMonthlyTicketWithEnterpriseA1GenaralDetail({ payload }) {
  const { id } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketCenterEnterpriseGeneralDetail, id)
    if (res?.data) {
      const formatDataForTable = res.data?.monthlyTicketItems?.map((item) => {
        item.amount = item.totalAmount / item.ticketCount
        return item
      })
      const data = {
        ...res.data,
        monthPublish: moment(res.data.createdDate).format('MM/YYYY'),
        datePublish: formatVnDate(res.data.createdDate),
        status: res?.data?.status === 0 ? 'Chờ xử lý' : 'Thành công',
        confirmedDate: formatVnDate(res.data.confirmedDate),
        monthlyTicketItems: formatDataForTable
      }
      yield put(
        monthlyTicketWithEnterpriseA1Actions.getCenterMonthlyTicketWithEnterpriseA1GenaralDetailSuccess(
          data
        )
      )
    } else {
      yield put(
        monthlyTicketWithEnterpriseA1Actions.getCenterMonthlyTicketWithEnterpriseA1GenaralReportFailed()
      )
    }
  } catch (error) {
    console.log('error: ', error)
  }
}
function* handleConfirmPayment({ payload }) {
  const { id, data, onSuccess } = payload
  try {
    const res = yield call(reportApi.confirmTransferTransactionForEnterprise, id, data)
    if (res?.data) {
      onSuccess()
      successToast('Khấu trừ - thuế thành công', 'Đã xác nhận khấu trừ - thuế thành công.')
      yield put(monthlyTicketWithEnterpriseA1Actions.confirmPaymentSuccess(res?.data))
    } else {
      errorToast('Khấu trừ - thuế thất bại', res?.error.messageVN)
    }
  } catch (error) {
    errorToast(
      'Khấu trừ - thuế thất bại',
      'Đã có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên để được hỗ trợ.'
    )
    console.log('error: ', error)
  }
}
function* handleGetHistory({ payload }) {
  const { params } = payload
  try {
    const res = yield call(reportApi.getMonthlyTicketHistoryCenterEnterpriseHistory, params)
    if (res?.data) {
      yield put(monthlyTicketWithEnterpriseA1Actions.setHistoryTotal(res?.data?.totalItem))

      const data = res?.data?.items.map((item, key) => {
        item.idx = res?.data?.offset + key + 1
        item.invoice_code = `#${item.invoice_code}`
        item.payment_status = item.payment_status === 2 ? 'Thành công' : ' Thất bại'
        return item
      })
      yield put(monthlyTicketWithEnterpriseA1Actions.getHistorySuccess(data))
    } else {
      yield put(monthlyTicketWithEnterpriseA1Actions.getHistoryFailed())
    }
  } catch (error) {
    console.log('error: ', error)
  }
}
export default function* monthlyTicketWithEnterpriseA1Saga() {
  yield takeLatest(
    monthlyTicketWithEnterpriseA1Actions.getCenterMonthlyTicketWithEnterpriseA1GenaralReport.type,
    handleGetCenterMonthlyTicketWithEnterpriseA1GenaralReport
  )
  yield takeLatest(monthlyTicketWithEnterpriseA1Actions.confirmPayment.type, handleConfirmPayment)

  yield takeLatest(
    monthlyTicketWithEnterpriseA1Actions.getCenterMonthlyTicketWithEnterpriseA1GenaralDetail.type,
    handleGetCenterMonthlyTicketWithEnterpriseA1GenaralDetail
  )
  yield takeLatest(monthlyTicketWithEnterpriseA1Actions.getHistory.type, handleGetHistory)
}
