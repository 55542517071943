import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dataWithRole: [],
  loading: false,
  error: false,
  chilPermissions: [],
  data: [],
  isOpenModal: false,
  permission: {}
}

const permissionListSlice = createSlice({
  name: 'permissionList',
  initialState,
  reducers: {
    setChilPermissions: (state, action) => {
      state.chilPermissions = action.payload
    },
    getPermissionListWithRole: (state) => {
      state.loading = true
      state.error = false
      state.dataWithRole = []
    },
    getPermissionList: (state) => {
      state.loading = true
      state.error = false
      state.data = []
    },
    toggleModal: (state) => {
      // state.permission = {}
      // state.chilPermissions = []
      state.isOpenModal = !state.isOpenModal
    },
    initialUpdatePermission: (state) => {
      state.isOpenModal = !state.isOpenModal
    },
    initialUpdatePermissionSuccess: (state, action) => {
      state.chilPermissions = action.payload.permissions
      state.permission = action.payload
    },
    getPermissionsSuccess: (state, action) => {
      state.data = action.payload
    },

    getPermissionListWithRoleSuccess: (state, action) => {
      state.loading = false
      state.dataWithRole = action.payload
    },

    getPermissionListWithRoleFailed: (state) => {
      state.loading = false
      state.error = true
      state.dataWithRole = []
    },

    addNewPermission: (state, action) => {
      state.isOpenModal = !state.isOpenModal
    },
    deletePermission: (state, action) => {},
    updatePermissionSuccess: (state) => {
      state.isOpenModal = !state.isOpenModal
    },
    updatePermission: (state) => {}
  }
})

export const permissionListActions = permissionListSlice.actions

const permissionListReducer = permissionListSlice.reducer
export default permissionListReducer
