import { call, put, takeLatest } from '@redux-saga/core/effects'
import { allCustomersActions, formatData } from './allCustomers.slice'
import customerApi from '@ourSrc/api/customerApi'
import { apiErrorToast, successToast, SuccessToast } from '@ourSrc/utility/Utils'
function* handleGetAllCustomers({ payload: { params } }) {
  try {
    const res = yield call(customerApi.getCustomerList, params)
    yield put(allCustomersActions.getAllCustomersSuccess({ data: res.data.items, params }))
    yield put(allCustomersActions.getTotalCount(res.data.totalItem))
  } catch (error) {
    yield put(allCustomersActions.getAllCustomersFailed())
  }
}
function* handleRemoveCard({ payload }) {
  try {
    const res = yield call(customerApi.removeCard, payload.customer.card_no)
    if (res && res.data && res.error.code === 1) {
      successToast('Thông báo', 'Xóa thẻ thành công')
      yield put(allCustomersActions.removeCardSuccess(res.data))
    } else {
      apiErrorToast(res.error.messageVN)
      yield put(allCustomersActions.removeCardFailed())
    }
  } catch (error) {
    apiErrorToast(res.error.messageVN)

    yield put(allCustomersActions.removeCardFailed())
  }
}

function* handleExportExcel({ payload: { params, onSuccess, setLoadingExport } }) {
  try {
    setLoadingExport(true)
    const res = yield call(customerApi.getCustomerList, params)
    onSuccess(formatData(res?.data?.items))
  } catch (error) {
    onSuccess([])
  } finally {
    setLoadingExport(false)
  }
}

export default function* allCustomersSaga() {
  yield takeLatest(allCustomersActions.getAllCustomers.type, handleGetAllCustomers)
  yield takeLatest(allCustomersActions.removeCard.type, handleRemoveCard)
  yield takeLatest(allCustomersActions.exportExcel.type, handleExportExcel)
}
