import { put, takeLatest, call } from '@redux-saga/core/effects'
import settingApi from '@ourSrc/api/settingApi'
import { roleListActions } from './roleList.slice'

function* handleGetRoleList() {
  try {
    const response = yield call(settingApi.getRoleList, null)
    if (response.data.length !== 0) {
      const data = response.data.map((item, index) => {
        item.idx = index + 1
        item.value = item.id
        item.label = item.name
        return item
      })
      yield put(roleListActions.getRoleListSuccess(data))
    } else yield put(roleListActions.getRoleListFailed())
  } catch (error) {
    console.log(`error`, error)
    yield put(roleListActions.getRoleListFailed())
  }
}

function* handleAddNewRole({ payload }) {
  try {
    const response = yield call(settingApi.addNewRole, payload.params)
    if (response.data) {
      yield handleGetRoleList()
    }
  } catch (error) {
    console.log(`error`, error)
  }
}

function* handleDeleteRole({ payload }) {
  try {
    const response = yield call(settingApi.deleteRole, payload)
    if (response.error.code === 1) {
      yield handleGetRoleList()
    }
  } catch (error) {
    console.log(`error`, error)
  }
}

function* handleUpdateRole({ payload }) {
  try {
    const response = yield call(settingApi.updateRole, payload.id, payload.params)
    if (response.data) {
      yield put(roleListActions.updateRoleSuccess())
      yield handleGetRoleList()
    }
  } catch (error) {
    console.log(`error`, error)
  }
}

function* handleInitialUpdateRole({ payload }) {
  try {
    if (payload.roleId) {
      const response = yield call(settingApi.getRoleById, payload.roleId)
      if (response.data) {
        const permissions = response.data.permissions.map((item) => {
          return item.id
        })
        const data = { ...response.data, permissions }
        yield put(roleListActions.getRoleByIdSuccess(data))
      }
    }
  } catch (error) {
    console.log(`error`, error)
  }
}

export default function* roleListSaga() {
  yield takeLatest(roleListActions.getRoleList.type, handleGetRoleList)
  yield takeLatest(roleListActions.addNewRole.type, handleAddNewRole)
  yield takeLatest(roleListActions.deleteRole.type, handleDeleteRole)
  yield takeLatest(roleListActions.initialUpdateRole.type, handleInitialUpdateRole)
  yield takeLatest(roleListActions.updateRole.type, handleUpdateRole)
}
