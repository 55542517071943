import { call, put, takeLatest } from '@redux-saga/core/effects'
import { promotionListActions } from './promotionList.slice'
import customerApi from '@ourSrc/api/customerApi'

function* handleGetPromotionList({ payload: { body } }) {
  try {
    const res = yield call(customerApi.getPromotions, body)
    yield put(promotionListActions.getPromotionListSuccess(res.data))
  } catch (error) {
    yield put(promotionListActions.getPromotionListFailed())
  }
}

export default function* promotionListSaga() {
  yield takeLatest(promotionListActions.getPromotionList.type, handleGetPromotionList)
}
