import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: undefined,
  loading: false,
  error: false
}

const newCustomerSlice = createSlice({
  name: 'newCustomer',
  initialState,
  reducers: {
    addCustomer: (state) => {
      state.loading = true
      state.error = false
      state.data = undefined
    },
    addCustomerSuccess: (state, action) => {
      state.loading = false
      state.error = false
      state.data = action.payload
    },

    addCustomerFailed: (state) => {
      state.loading = false
      state.error = true
      state.data = undefined
    }
  }
})

export const newCustomerActions = newCustomerSlice.actions

const newCustomerReducer = newCustomerSlice.reducer
export default newCustomerReducer
