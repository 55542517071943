import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pieLoading: false,
  pieError: false,
  pie: [],
  total: {},

  barLoading: false,
  barError: false,
  bar: []
}

const ticketChartSlice = createSlice({
  name: 'ticketChart',
  initialState,
  reducers: {
    getTicketPieChart: (state) => {
      state.pieLoading = true
      state.pieError = false
    },
    getTicketPieChartSuccess: (state, action) => {
      state.pieLoading = false
      state.pie = action.payload.pie
      state.total = action.payload.total
    },
    getTicketPieChartFailed: (state) => {
      state.pieLoading = false
      state.pieError = true
    },

    getTicketBarChart: (state) => {
      state.barLoading = true
      state.barError = false
    },
    getTicketBarChartSuccess: (state, action) => {
      state.barLoading = false
      state.bar = action.payload
    },
    getTicketBarChartFailed: (state) => {
      state.barLoading = false
      state.barError = true
    }
  }
})

export const ticketChartActions = ticketChartSlice.actions

const ticketChartReducer = ticketChartSlice.reducer
export default ticketChartReducer
