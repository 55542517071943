import { fetch } from '.'

const errorApi = {
  getBlackListZalopay(params) {
    const url = '/v3/black-list/list'
    return fetch('post', url, params)
  },
  deleteBlackListZalopay(id) {
    const url = `/v3/black-list/delete/${id}`
    return fetch('delete', url, null)
  },
  repayZalopay(body) {
    const url = `/v3/black-list/re-pay`
    return fetch('post', url, body)
  },
  //bos
  getListErrorBos(body) {
    const url = `/v3/recovery/request/list`
    return fetch('post', url, body)
  },
  deleteInvoiceErrorBos(id, body) {
    const url = `/v3/recovery/request/${id}`
    return fetch('delete', url, body)
  },
  createInvoiceBos(body) {
    const url = `/v3/recovery/request`
    return fetch('post', url, body)
  },
  updateInvoiceBos(id, body) {
    const url = `/v3/recovery/request/${id}`
    return fetch('put', url, body)
  },
  syncInvoiceBos(body) {
    const url = `/v3/recovery/request/sync`
    return fetch('post', url, body)
  }
}

export default errorApi
