export const STATUS = {
  3: {
    value: 3,
    name: 'Thành công',
    color: 'light-success'
  },
  2: {
    value: 2,
    name: 'Thất bại',
    color: 'light-danger'
  },
  1: {
    value: 1,
    name: 'Chờ xử lý',
    color: 'light-info'
  }
}

export const PAYMENT_METHOD = {
  2: {
    value: 2,
    label: 'Vé điện tử',
    optionsTicketTypeId: [
      { value: 4, label: 'Vé thường' },
      { value: 5, label: 'Vé giảm' },
      { value: 6, label: 'Vé miễn' },
      { value: 9, label: 'Vé tập' }
    ]
  },
  4: {
    value: 4,
    label: 'Vé tiền mặt',
    optionsTicketTypeId: [
      {
        value: 1,
        label: 'Vé thường',
        price: [
          { value: 5000, label: '5000 VNĐ' },
          { value: 6000, label: '6000 VNĐ' },
          { value: 7000, label: '7000 VNĐ' },
          { value: 10000, label: '10000 VNĐ' },
          { value: 15000, label: '15000 VNĐ' },
          { value: 30000, label: '30000 VNĐ' }
        ]
      },
      {
        value: 2,
        label: 'Vé giảm',
        price: [{ value: 3000, label: '3000 VNĐ' }]
      },
      {
        value: 3,
        label: 'Vé miễn',
        price: [{ value: '0', label: '0 VNĐ' }]
      },
      { value: 7, label: 'Vé tập', price: [] }
    ]
  },
  6: {
    value: 6,
    label: 'Vietbank',
    optionsTicketTypeId: []
  }
}

export const PAYMENT_STATUS = {
  success: {
    value: 2,
    label: 'Thành công',
    color: 'success'
  },
  failed: { value: 4, label: 'Thất bại', color: 'danger' }
}
export const PAYMENT_FLATFORM = {
  ZALOPAY: {
    value: 'ZALOPAY',
    label: 'Ví ZaloPay'
  },
  // PAYPAL: {
  //   value: 'PAYPAL',
  //   label: 'Paypal'
  // },
  // CASH: {
  //   value: 'CASH',
  //   label: 'Tiền mặt'
  // },
  // VIETBANK: {
  //   value: 'VIETBANK',
  //   label: 'Thẻ VietBank'
  // },
  UNIPASS: {
    value: 'UNIPASS',
    label: 'Tài khoản UniPass'
  }
}
export const USER_STATUS = {
  pending: {
    value: 'pending',
    label: 'Pending',
    color: 'warning'
  },
  active: {
    value: 'active',
    label: 'Active',
    color: 'success'
  },
  inactive: { value: 'inactive', label: 'Inactive', color: 'danger' }
}

export const ENTERPRISE_TYPE = {
  a1: {
    value: 1,
    label: 'Doanh nghiệp A1'
  },
  a2: {
    value: 2,
    label: 'Doanh nghiệp A2'
  }
}

export const VNG_BUS_METHOD = {
  card: {
    value: 2,
    label: 'Thẻ nhân viên'
  },
  forget_card: {
    value: 4,
    label: 'Quên thẻ'
  }
}

export const STEP = {
  confirm: 1,
  progress: 2,
  success: 3,
  fail: 4
}

export const TEMP_RECEIPTS = {
  STATUS: {
    active: { value: 2, label: 'Đã kích hoạt', color: 'success' },
    inactive: { value: 1, label: 'Chưa kích hoạt', color: 'info' },
    failed: { value: 3, label: 'Đã xóa', color: 'danger' }
  },
  TASKS: {
    active: { value: 'active', label: 'Kích hoạt' },
    addNote: { value: 'addNote', label: 'Thêm ghi chú' },
    delete: { value: 'delete', label: 'Xóa' }
  },
  LIMIT: 1000,
  MESSAGES: {
    receipts: {
      activeAll: 'Những phiếu thu đã xóa sẽ không thể xóa lại và kích hoạt lại',
      active: 'Vé này đã kích hoạt hoặc đã xóa, sẽ không kích hoạt lại',
      deleteAll: 'Những phiếu thu đã xóa sẽ không thể xóa lại và kích hoạt lại',
      delete: 'Vé này đã kích hoạt hoặc đã xóa, sẽ không được xóa'
    }
  },
  PRINT_STATUS: {
    printed: { value: 2, label: 'Đã in', color: 'success' },
    waitPrint: { value: 1, label: 'Chưa in', color: 'warning' }
  },
  DATE_FILTER_TYPE: {
    ACTIVE_DATE: { value: 'ACTIVE_DATE', label: 'Ngày kích hoạt' },
    CREATE_DATE: { value: 'CREATE_DATE', label: 'Ngày tạo' },
    DELETE_DATE: { value: 'DELETE_DATE', label: 'Ngày xóa' },
    PRINT_DATE: { value: 'PRINT_DATE', label: 'Ngày in' }
  }
}

export const ERROR_SYSTEM = {
  STATUS: {
    success: { value: 3, label: 'Thành công' },
    fail: { value: 2, label: 'Thất bại' },
    waiting: { value: 1, label: 'Chờ xử lý' }
  },
  TASKS_BOS: {
    sync: { value: 'sync', label: 'Đồng bộ' },
    delete: { value: 'delete', label: 'Xóa' }
  },
  TASKS_ZALOPAY: {
    repay: { value: 'repay', label: 'Thanh toán lại' },
    delete: { value: 'delete', label: 'Xóa' }
  },
  STATUS_SYNC: {
    wrong: { value: 2, label: 'Lệch số liệu' },
    edited: { value: 3, label: 'Đã chỉnh sửa' },
    approved: { value: 4, label: 'Chấp nhận lệch' }
  }
}

export const CAR_STATUS = {
  active: { value: 1, label: 'Đang hoạt động', color: 'success' },
  inactive: { value: 2, label: 'Ngừng hoạt động', color: 'danger' }
}

export const CAR_STATUS_TABLE = {
  inactive: { value: 0, label: 'Ngừng hoạt động', color: 'danger' },
  active: { value: 1, label: 'Đang hoạt động', color: 'success' }
}

export const REGULAR_EXPRESSION = {
  number_characters: /^[a-zA-Z0-9_.-]*$/
}

export const CUSTOMER_MANAGEMENT = {
  FIND_TYPE: {
    createdDate: {
      value: '0',
      label: 'Ngày tạo tài khoản'
    },
    addressUpdatedDate: {
      value: '1',
      label: 'Ngày update địa chỉ'
    }
  },
  APPROVAL_STATUS: {
    pending: {
      value: '0',
      label: 'Đang chờ xét duyệt'
    },
    approved: {
      value: '1',
      label: 'Đã duyệt'
    },
    rejected: {
      value: '2',
      label: 'Bị từ chối'
    }
  },
  REGISTER_FROM: {
    bos: { value: 'BOS', label: 'BOS' },
    zalo: { value: 'ZALO', label: 'ZALO' },
    zalopay: { value: 'ZALOPAY', label: 'ZALOPAY' },
    VNG: { value: 'VNG', label: 'VNG' },
    ebms: { value: 'EBMS', label: 'EBMS' }
  }
}

export const UNIPASS = {
  refund: {
    nameSheet: 'OUT',
    importField: {
      no: 'No',
      zalopayTransactionCode: 'Mã GD ZaloPay',
      accountName: 'Điện thoại',
      amount: 'Số tiền',
      desc: 'Mô tả',
      refundStatus: 'Trạng thái',
      note: 'Lý do',
      name: 'Họ và tên'
    },
    refundType: {
      user: {
        value: 1,
        label: 'MiniApp'
      },
      admin: {
        value: 2,
        label: 'BOS'
      }
    },
    dateType: {
      registeredDate: {
        value: 1,
        label: 'Thời gian đăng ký hoàn tiền'
      },
      sendZaloPayDate: {
        value: 2,
        label: 'Thời gian gửi ZaloPay'
      },
      refundDate: {
        value: 3,
        label: 'Thời gian hoàn tiền'
      }
    },
    status: {
      PENDING: {
        value: 1,
        label: 'Chờ xử lý',
        color: 'info'
      },
      CONFIRMED: {
        value: 2,
        label: 'Đã gửi ZaloPay',
        color: 'warning'
      },
      FINISHED: {
        value: 3,
        label: 'Đã hoàn tiền',
        color: 'success'
      },
      CANCELED: {
        value: 4,
        label: 'Đã hủy',
        color: 'danger'
      },
      FAILED: {
        value: 5,
        label: 'Thất Bại',
        color: 'danger'
      }
    },
    TASKS: {
      send: { value: 'send', label: 'Gửi ZaloPay', color: '#FF9F43', code: 2 },
      refund: { value: 'refund', label: 'Hoàn tiền', code: 3, color: '#28C76F' },
      cancel: {
        value: 'cancel',
        label: 'Hủy hoàn tiền',
        code: 4,
        title: 'Hủy yêu cầu hoàn tiền',
        content: 'Số tiền yêu cầu sẽ được hoàn lại vào ví UniPass cho User',
        labelField: 'Lý do hủy yêu cầu hoàn tiền'
      },
      failed: {
        value: 'failed',
        label: 'Hoàn tiền thất bại',
        code: 5,
        color: '#EB2227',
        title: 'Hủy yêu cầu hoàn tiền',
        content: '',
        labelField: 'Lý do hoàn tiền thất bại'
      }
    },
    STEP: {
      form: 1,
      confirm: 2,
      success: 3,
      fail: 4
    }
  },
  paymentGateway: {
    partner: {
      MOMO: { value: 'MOMO', label: 'Ví Momo' },
      ZALOPAY: { value: 'ZALOPAY', label: 'Ví ZaloPay' }
    },
    paymentMethodGroup: {
      PAYMENT_GATEWAY: { value: 'PAYMENT_GATEWAY', label: 'Payment gateway' },
      QUICK_PAY: { value: 'QUICK_PAY', label: 'Quick pay' }
    },
    paymentChanel: {
      INTERNATIONAL_CREDIT_CARD: { value: 'INTERNATIONAL_CREDIT_CARD', label: 'Visa/Master/JCB' },
      DOMESTIC_DEBIT_IB: { value: 'DOMESTIC_DEBIT_IB', label: 'Internet Banking' },
      ZALOPAY_WALLET: { value: 'ZALOPAY_WALLET', label: 'Ví ZaloPay' },
      DOMESTIC_DEBIT_CARD: { value: 'DOMESTIC_DEBIT_CARD', label: 'Thẻ ATM nội địa' },
      INTERNATIONAL_DEBIT_CARD: { value: 'INTERNATIONAL_DEBIT_CARD', label: 'Visa/Master Debit' },
      MOMO_WALLET: { value: 'MOMO_WALLET', label: 'Ví MoMo' },
      APPLE_WALLET: {
        value: 'APPLE_WALLET',
        label: 'Ví ApplePay'
      }
    },
    paymentPurpose: {
      CASH_IN: { value: 'CASH_IN', label: 'Nạp tiền vào tài khoản UniPass' },
      TICKET_PAYMENT: { value: 'TICKET_PAYMENT', label: 'Thanh toán vé xe buýt' }
    },
    status: {
      PENDING: { value: 1, label: 'Giao dịch đang được xử lý' },
      SUCCESS: { value: 2, label: 'Giao dịch thành công' },
      TIMEOUT: { value: 3, label: 'Giao dịch quá hạn xử lý' },
      FAILED: { value: 4, label: 'Giao dịch thất bại' }
    },
    PAYMENT_METHOD: {
      PAYMENT_CARD: {
        value: 2,
        label: 'Thẻ/QR UniPass',
        sourceOfFund: {
          UNIPASS: { value: 'UNIPASS', label: 'Tài Khoản UniPass' },
          ZALOPAY: { value: 'ZALOPAY', label: 'Ví ZaloPay' }
        },
        ticketType: {
          normal: {
            value: 4,
            label: 'Vé thường điện tử',
            price: [
              { value: 5000, label: '5000 VNĐ' },
              { value: 6000, label: '6000 VNĐ' },
              { value: 7000, label: '7000 VNĐ' },
              { value: 10000, label: '10000 VNĐ' },
              { value: 15000, label: '15000 VNĐ' },
              { value: 30000, label: '30000 VNĐ' }
            ]
          },
          discount: {
            value: 5,
            label: 'Vé giảm điện tử',
            price: [{ value: 3000, label: '3000 VNĐ' }]
          },
          free: {
            value: 6,
            label: 'Vé miễn điện tử'
          },
          package: {
            value: 9,
            label: 'Vé tập điện tử'
          }
        }
      },
      PAYMENT_CASH: {
        value: 4,
        label: 'Tiền mặt',
        sourceOfFund: {
          CASH: { value: 'CASH', label: 'Tiền mặt' }
        },
        ticketType: {
          normal: {
            value: 1,
            label: 'Vé thường tiền mặt',
            price: [
              { value: 5000, label: '5000 VNĐ' },
              { value: 6000, label: '6000 VNĐ' },
              { value: 7000, label: '7000 VNĐ' },
              { value: 10000, label: '10000 VNĐ' },
              { value: 15000, label: '15000 VNĐ' },
              { value: 30000, label: '30000 VNĐ' }
            ]
          },
          discount: {
            value: 2,
            label: 'Vé giảm tiền mặt',
            price: [{ value: 3000, label: '3000 VNĐ' }]
          },
          free: {
            value: 3,
            label: 'Vé miễn tiền mặt'
          },
          package: {
            value: 7,
            label: 'Vé tập tiền mặt'
          }
        }
      },
      PAYMENT_VIETBANK: {
        value: 6,
        label: 'VietBank',
        sourceOfFund: {
          VIETBANK: { value: 'VIETBANK', label: 'VietBank' }
        },
        ticketType: {
          normal: {
            value: 4,
            label: 'Vé thường điện tử',
            price: [
              { value: 5000, label: '5000 VNĐ' },
              { value: 6000, label: '6000 VNĐ' },
              { value: 7000, label: '7000 VNĐ' },
              { value: 10000, label: '10000 VNĐ' },
              { value: 15000, label: '15000 VNĐ' },
              { value: 30000, label: '30000 VNĐ' }
            ]
          },
          discount: {
            value: 5,
            label: 'Vé giảm điện tử',
            price: [{ value: 3000, label: '3000 VNĐ' }]
          }
        }
      },
      PAYMENT_ZLP_GATEWAY: {
        value: 10,
        label: 'ZaloPay Gateway',
        sourceOfFund: {
          ZLP_PAYMENT_GATEWAY_INTERNATIONAL_CREDIT_CARD: {
            value: 'ZLP_PAYMENT_GATEWAY_INTERNATIONAL_CREDIT_CARD',
            label: 'Visa/Master/JCB'
          },
          ZLP_PAYMENT_GATEWAY_DOMESTIC_DEBIT_IB: {
            value: 'ZLP_PAYMENT_GATEWAY_DOMESTIC_DEBIT_IB',
            label: 'Internet Banking'
          },
          ZLP_PAYMENT_GATEWAY_ZALOPAY_WALLET: {
            value: 'ZLP_PAYMENT_GATEWAY_ZALOPAY_WALLET',
            label: 'Ví ZaloPay'
          },
          ZLP_PAYMENT_GATEWAY_DOMESTIC_DEBIT_CARD: {
            value: 'ZLP_PAYMENT_GATEWAY_DOMESTIC_DEBIT_CARD',
            label: 'Thẻ ATM nội địa'
          },
          ZLP_PAYMENT_GATEWAY_INTERNATIONAL_DEBIT_CARD: {
            value: 'ZLP_PAYMENT_GATEWAY_INTERNATIONAL_DEBIT_CARD',
            label: 'Visa/Master Debit'
          },
          ZLP_PAYMENT_GATEWAY_APPLE_WALLET: {
            value: 'ZLP_PAYMENT_GATEWAY_APPLE_WALLET',
            label: 'Ví ApplePay'
          }
        },
        ticketType: {
          normal: {
            value: 4,
            label: 'Vé thường điện tử',
            price: [
              { value: 5000, label: '5000 VNĐ' },
              { value: 6000, label: '6000 VNĐ' },
              { value: 7000, label: '7000 VNĐ' },
              { value: 10000, label: '10000 VNĐ' },
              { value: 15000, label: '15000 VNĐ' },
              { value: 30000, label: '30000 VNĐ' }
            ]
          },
          discount: {
            value: 5,
            label: 'Vé giảm điện tử',
            price: [{ value: 3000, label: '3000 VNĐ' }]
          }
        }
      },
      PAYMENT_ZLP_QUICKPAY: {
        value: 12,
        label: 'ZaloPay quickpay',
        sourceOfFund: {
          ZLP_QUICK_PAY_INTERNATIONAL_CREDIT_CARD: {
            value: 'ZLP_QUICK_PAY_INTERNATIONAL_CREDIT_CARD',
            label: 'Visa/Master/JCB'
          },
          ZLP_QUICK_PAY_DOMESTIC_DEBIT_IB: {
            value: 'ZLP_QUICK_PAY_DOMESTIC_DEBIT_IB',
            label: 'Internet Banking'
          },
          ZLP_QUICK_PAY_ZALOPAY_WALLET: {
            value: 'ZLP_QUICK_PAY_ZALOPAY_WALLET',
            label: 'Ví ZaloPay'
          },
          ZLP_QUICK_PAY_DOMESTIC_DEBIT_CARD: {
            value: 'ZLP_QUICK_PAY_DOMESTIC_DEBIT_CARD',
            label: 'Thẻ ATM nội địa'
          },
          ZLP_QUICK_PAY_INTERNATIONAL_DEBIT_CARD: {
            value: 'ZLP_QUICK_PAY_INTERNATIONAL_DEBIT_CARD',
            label: 'Visa/Master Debit'
          },
          ZLP_QUICK_PAY_APPLE_WALLET: {
            value: 'ZLP_QUICK_PAY_APPLE_WALLET',
            label: 'Ví ApplePay'
          }
        },
        ticketType: {
          normal: {
            value: 4,
            label: 'Vé thường điện tử',
            price: [
              { value: 5000, label: '5000 VNĐ' },
              { value: 6000, label: '6000 VNĐ' },
              { value: 7000, label: '7000 VNĐ' },
              { value: 10000, label: '10000 VNĐ' },
              { value: 15000, label: '15000 VNĐ' },
              { value: 30000, label: '30000 VNĐ' }
            ]
          },
          discount: {
            value: 5,
            label: 'Vé giảm điện tử',
            price: [{ value: 3000, label: '3000 VNĐ' }]
          }
        }
      },
      PAYMENT_MOMO_QUICKPAY: {
        value: 14,
        label: 'MoMo quickpay',
        sourceOfFund: {
          MOMO_QUICK_PAY_MOMO_WALLET: {
            value: 'MOMO_QUICK_PAY_MOMO_WALLET',
            label: 'Ví MoMo'
          }
        },
        ticketType: {
          normal: {
            value: 4,
            label: 'Vé thường điện tử',
            price: [
              { value: 5000, label: '5000 VNĐ' },
              { value: 6000, label: '6000 VNĐ' },
              { value: 7000, label: '7000 VNĐ' },
              { value: 10000, label: '10000 VNĐ' },
              { value: 15000, label: '15000 VNĐ' },
              { value: 30000, label: '30000 VNĐ' }
            ]
          },
          discount: {
            value: 5,
            label: 'Vé giảm điện tử',
            price: [{ value: 3000, label: '3000 VNĐ' }]
          }
        }
      }
    }
  },
  finance: {
    invoiceTypes: {
      INVOICE_TYPE_CARD: {
        value: 1,
        label: 'Thẻ UniPass'
      },
      INVOICE_TYPE_QR: {
        value: 2,
        label: 'Mã QR'
      }
    }
  }
}

export const ticketType = [
  { ticketTypeName: 'Vé tiền mặt', parent: true },
  {
    ticketTypeName: 'Vé thường',
    parentName: 'Vé tiền mặt',
    ticketTypeId: 1,
    enableAdd: true,
    show: true
  },
  {
    ticketTypeName: 'Vé thường 1',
    parentName: 'Vé tiền mặt',
    ticketTypeId: 11,
    addBy: 1,
    show: false
  },
  {
    ticketTypeName: 'Vé thường 2',
    parentName: 'Vé tiền mặt',
    ticketTypeId: 12,
    addBy: 1,
    show: false
  },
  {
    ticketTypeName: 'Vé thường 3',
    parentName: 'Vé tiền mặt',
    ticketTypeId: 13,
    addBy: 1,
    show: false
  },
  {
    ticketTypeName: 'Vé thường 4',
    parentName: 'Vé tiền mặt',
    ticketTypeId: 14,
    addBy: 1,
    show: false
  },
  {
    ticketTypeName: 'Vé thường 5',
    parentName: 'Vé tiền mặt',
    ticketTypeId: 15,
    addBy: 1,
    show: false
  },
  {
    ticketTypeName: 'Vé thường 6',
    parentName: 'Vé tiền mặt',
    ticketTypeId: 16,
    addBy: 1,
    show: false
  },

  { ticketTypeName: 'Vé giảm', parentName: 'Vé tiền mặt', ticketTypeId: 2, show: true },
  { ticketTypeName: 'Vé miễn', parentName: 'Vé tiền mặt', ticketTypeId: 3, show: true },
  { ticketTypeName: 'Vé tập', parentName: 'Vé tiền mặt', ticketTypeId: 7, show: true },
  {
    ticketTypeName: 'Vé điện tử',
    parent: true
  },
  {
    ticketTypeName: 'Vé thường',
    parentName: 'Vé điện tử',
    ticketTypeId: 4,
    enableAdd: false,
    show: true
  },
  {
    ticketTypeName: 'Vé thường 1',
    parentName: 'Vé điện tử',
    ticketTypeId: 41,
    addBy: 4,
    show: false
  },
  {
    ticketTypeName: 'Vé thường 2',
    parentName: 'Vé điện tử',
    ticketTypeId: 42,
    addBy: 4,
    show: false
  },
  { ticketTypeName: 'Vé giảm', parentName: 'Vé điện tử', ticketTypeId: 5, show: true },
  { ticketTypeName: 'Vé miễn', parentName: 'Vé điện tử', ticketTypeId: 6, show: true },
  { ticketTypeName: 'Vé tập', parentName: 'Vé điện tử', ticketTypeId: 9, show: true }
]

export const checkDeviceStatus = {
  status: {
    active: {
      value: 'active',
      label: 'Đang kết nối',
      color: 'success',
      change: ['repair', 'inactive']
    },
    disconnect: {
      value: 'disconnect',
      label: 'Mất kết nối',
      color: 'danger',
      change: ['repair', 'inactive']
    },
    repair: { value: 'repair', label: 'Xe hư', color: 'info', change: ['active', 'inactive'] },
    inactive: {
      value: 'inactive',
      label: 'Không hoạt động',
      color: 'warning',
      change: ['repair', 'active']
    },
    unavailable: {
      value: 'unavailable',
      label: 'Ngừng hoạt động',
      color: 'secondary',
      change: []
    }
  },
  action: {
    reconnect: {
      value: 'reconnect',
      label: 'Kết nối lại'
    },
    disconnect: { value: 'disconnect', label: 'Ngắt kết nối' }
  },
  STEP: {
    form: 1,
    success: 3,
    fail: 4
  }
}

export const STATION = {
  gpsStatus: {
    pass: {
      value: 1,
      label: 'Qua đủ trạm'
    },
    fail: {
      value: 2,
      label: 'Bỏ trạm'
    }
  },
  stationStatus: {
    exploring: {
      value: 'Đang khai thác',
      label: 'Đang khai thác'
    },
    untapped: {
      value: 'Chưa khai thác',
      label: 'Chưa khai thác'
    },
    cancelled: {
      value: 'Đã hủy',
      label: 'Đã hủy'
    }
  },
  stopType: {
    busStop: {
      value: 'Trụ dừng',
      label: 'Trụ dừng'
    },
    paint: {
      value: 'Ô sơn',
      label: 'Ô sơn'
    },
    waitingArea: {
      value: 'Nhà chờ',
      label: 'Nhà chờ'
    },
    busStation: {
      value: 'Bến xe',
      label: 'Bến xe'
    },
    signboard: {
      value: 'Biển treo',
      label: 'Biển treo'
    },
    centralInterchangeStation: {
      value: 'Bến bãi QH chung QH',
      label: 'Bến bãi QH chung QH'
    },
    tempStaion: {
      value: 'Trạm tạm',
      label: 'Trạm tạm'
    }
  },
  stationType: {
    realStaion: {
      value: 1,
      label: 'Trạm chính thức'
    },
    virtualStaion: {
      value: 2,
      label: 'Trạm ảo'
    }
  }
}

export const ROUTE = {}
export const MAP = {
  positionDefaultCenter: [10.78252517522869, 106.68534799462223],
  defaultZoom: 14
}

export const NORMAL_TICKET_ID = [1, 11, 12, 13, 14, 15, 16]
export const PAYMENT_METHOD_E_TICKET = [1, 2, 5, 6, 7]
