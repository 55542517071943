import { put, takeLatest, call } from '@redux-saga/core/effects'
import { resetPassword } from '@ourSrc/api/authApi'
import { changePasswordActions } from './changePassword.slice'

function* handleChangePassword({ payload }) {
  const { isSuccess, data } = yield call(resetPassword, payload.newPassword)
  if (isSuccess) {
    yield put(changePasswordActions.changePasswordSuccess())
    payload.onSuccess()
  } else {
    yield put(changePasswordActions.changePasswordFailed())
  }
}

export default function* changePasswordSaga() {
  yield takeLatest(changePasswordActions.changePassword.type, handleChangePassword)
}
