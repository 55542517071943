import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  general: { data: [], loading: false, error: false, total: 0 },
  transactionDetail: { data: [], loading: false, error: false },
  historyDetail: { data: [], loading: false, error: false }
}

const monthlyTicketWithZionSlice = createSlice({
  name: 'monthlyTicketWithZion',
  initialState,
  reducers: {
    getCenterMonthlyTicketWithZionGenaralReport: (state) => {
      state.general = { data: [], loading: true, error: false, total: 0 }
    },
    getCenterMonthlyTicketWithZionGenaralReportSuccess: (state, action) => {
      state.general = { ...state.general, data: action.payload, loading: false }
    },
    getCenterMonthlyTicketWithZionGenaralReportFailed: (state) => {
      state.general = { ...state.general, loading: false, error: true }
    },
    setCenterMonthlyTicketWithZionGenaralReportTotal: (state, action) => {
      state.general = { ...state.general, total: action.payload }
    },
    getCenterMonthlyTicketWithZionGenaralDetail: (state) => {
      state.transactionDetail = { data: [], loading: true, error: false }
    },
    getCenterMonthlyTicketWithZionGenaralDetailSuccess: (state, action) => {
      state.transactionDetail = { ...state.transactionDetail, data: action.payload, loading: false }
    },
    getCenterMonthlyTicketWithZionGenaralDetailFailed: (state) => {
      state.transactionDetail = { ...state.transactionDetail, loading: false, error: true }
    },
    getCenterMonthlyTicketWithZionHistoryDetail: (state) => {
      state.historyDetail = { data: [], loading: true, error: false }
    },
    getCenterMonthlyTicketWithZionHistoryDetailSuccess: (state, action) => {
      state.historyDetail = { ...state.historyDetail, data: action.payload, loading: false }
    },
    getCenterMonthlyTicketWithZionHistoryDetailFailed: (state) => {
      state.historyDetail = { ...state.historyDetail, loading: false, error: true }
    }
  }
})

export const monthlyTicketWithZionActions = monthlyTicketWithZionSlice.actions

const monthlyTicketWithZionReducer = monthlyTicketWithZionSlice.reducer
export default monthlyTicketWithZionReducer
