import { fetch } from '.'

const reportApi = {
  getRouteReport(params) {
    const url = '/v3/report/route/get-by-month'
    return fetch('post', url, params)
  },

  getTurnReport(params) {
    const url = '/v3/report/turn/get-by-day-and-car'
    return fetch('post', url, params)
  },

  getCarReportByMonth(params) {
    const url = '/v3/report/car/get-by-month'
    return fetch('post', url, params)
  },

  getCarReportByMonthAndCar(params) {
    const url = '/v3/report/car/group-by-month-and-car'
    return fetch('post', url, params)
  },

  getCarReportByCarId(params) {
    const url = '/v3/report/car/get-by-car-id'
    return fetch('post', url, params)
  },

  getTicketReport(params) {
    const url = '/v3/report/ticket/get-by-month'
    return fetch('post', url, params)
  },

  getStationReport(params) {
    const url = '/v3/report/station/get-by-month'
    return fetch('post', url, params)
  },

  getHistoryZion(params) {
    const url = '/v3/report/history/finance/get-by-month'
    return fetch('post', url, params)
  },

  getHistoryZionTotal(params) {
    const url = '/v3/report/history/finance/get-total-by-month'
    return fetch('post', url, params)
  },

  getHistory(params) {
    const url = '/v3/report/history/transaction/get-by-month'
    return fetch('post', url, params)
  },

  getHistoryTotal(params) {
    const url = '/v3/report/history/transaction/get-total-by-month'
    return fetch('post', url, params)
  },

  getHistoryTotalByYear(params) {
    const url = '/v3/report/history/transaction/get-total-by-year-summary'
    return fetch('post', url, params)
  },

  getCustomerTotalByYear(params) {
    const url = '/v3/report/history/customer/get-total-by-year-summary'
    return fetch('post', url, params)
  },

  getCustomerReport(params) {
    const url = '/v3/report/customer/list'
    return fetch('post', url, params)
  },

  getCustomerReportDetail(customerId) {
    const url = `/v3/report/customer/detail/${customerId}`
    return fetch('get', url, {})
  },

  getCustomerPaymentByMonth(params) {
    const url = '/v3/report/history/customer/get-total-payment-by-month'
    return fetch('post', url, params)
  },

  getCustomerRegistry(params) {
    const url = '/v3/report/customer/card/status'
    return fetch('post', url, params)
  },

  getRouteGeneral(params) {
    const url = '/v3/report/route/get-general'
    return fetch('post', url, params)
  },

  getRouteByPayment(params) {
    const url = '/v3/report/route/get-by-payment'
    return fetch('post', url, params)
  },

  getRouteByCustomer(params) {
    const url = '/v3/report/route/get-by-customer'
    return fetch('post', url, params)
  },

  getHistoryVietbank(params) {
    const url = '/v3/report/history/finance/vietbank/get-by-month'
    return fetch('post', url, params)
  },

  getVietbankHistoryTotal(params) {
    const url = '/v3/report/history/finance/vietbank/get-total-by-month'
    return fetch('post', url, params)
  },

  getMonthlyTicketCenterGeneralReport(params) {
    const url = `/v3/monthly-ticket/report/sold-report`
    return fetch('get', url, params)
  },
  getMonthlyTicketCenterZionGeneralDetail(params) {
    const url = `/v3/monthly-ticket/report/sold-report/detail/${params}`
    return fetch('get', url, {})
  },
  getMonthlyTicketCenterZionHistoryDetail(id) {
    const url = `/v3/monthly-ticket/bos/transaction/${id}`
    return fetch('get', url, {})
  },
  updateDiscountAndTax(id, body) {
    const url = `/v3/monthly-ticket/report/sold-report/update-percent/${id}`
    return fetch('put', url, body)
  },
  confirmTransferTransaction(id, body) {
    const url = `/v3/monthly-ticket/report/sold-report/confirm-transfer/${id}`
    return fetch('put', url, body)
  },
  getMonthlyTicketCenterEnterpriseGeneralReport(params) {
    const url = `/v3/monthly-ticket/report/usage-report/list`
    return fetch('post', url, params)
  },
  confirmTransferTransactionForEnterprise(id, body) {
    const url = `/v3/monthly-ticket/report/usage-report/confirm-transfer/${id}`
    return fetch('put', url, body)
  },
  getMonthlyTicketCenterEnterpriseGeneralDetail(id) {
    const url = `/v3/monthly-ticket/report/usage-report/detail/${id}`
    return fetch('get', url, {})
  },
  getMonthlyTicketHistoryCenterEnterpriseHistory(body) {
    const url = `/v3/monthly-ticket/report/usage-report/transaction/list`
    return fetch('post', url, body)
  },
  getCarReportByMonthExportExcel(body) {
    const url = `/v3/report/car/month-report`
    return fetch('post', url, body)
  },
  GET_LOCATION_POINTS: {
    url: '/v3/bill/reservation/get-list-by-serial',
    method: 'POST'
  },
  GET_HISTORY_TOTAL: {
    url: '/v3/report/history/transaction/get-total-by-month',
    method: 'POST'
  },
  GET_HISTORY: {
    url: '/v3/report/history/transaction/get-by-month',
    method: 'POST'
  },
  GET_HISTORY_REPORT: {
    url: '/v3/report/history/transaction/get-report',
    method: 'POST'
  },
  GET_CAR_DETAIL_TURN_REPORT: {
    url: '/v3/report/car/turn-detail-export',
    method: 'POST'
  }
}

export default reportApi
