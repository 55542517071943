export const PROMOTION_STATUS = {
  all: {
    value: 0,
    label: 'Tất cả'
  },
  success: {
    value: 1,
    label: 'Thành công'
  },
  failed: {
    value: 2,
    label: 'Thất bại'
  }
}

export const PROMOTION_TYPES = {
  discountPrice: {
    label: 'Giảm giá',
    value: 'discountAmount',
    method: 'useDiscountAmount'
  },
  samePrice: {
    label: 'Đồng giá',
    value: 'finalAmount',
    method: 'useFinalAmount'
  },
  // noApply: {
  //   label: 'Không áp dụng',
  //   value: 2
  // },
  discountPercentage: {
    label: 'Giảm %',
    value: 'discountPercent',
    method: 'usePercent'
  }
}

export const TICKET_TYPE = {
  genaral: {
    label: 'Loại vé Phổ Thông',
    value: 1,
    price: [
      { value: 5000, label: '5000 VNĐ' },
      { value: 6000, label: '6000 VNĐ' },
      { value: 7000, label: '7000 VNĐ' }
    ]
  },
  free: { label: 'Loại vé Miễn', value: 3, price: [] },
  discount: {
    label: 'Loại vé Giảm',
    value: 2,
    price: [
      { value: 2000, label: '2000 VNĐ' },
      { value: 3000, label: '3000 VNĐ' }
    ]
  }
}
