import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  refund: {
    data: {},
    loading: false,
    loadingExecute: false,
    selected: [],
    summary: {
      totalAmount: 0,
      totalAmountCanceled: 0,
      totalAmountConfirmed: 0,
      totalAmountFinished: 0,
      totalAmountPending: 0,
      totalUser: 0,
      totalUserCanceled: 0,
      totalUserConfirmed: 0,
      totalUserFinished: 0,
      totalUserPending: 0,
      totalAmountFailed: 0,
      totalUserFailed: 0
    }
  }
}

const unipassSlice = createSlice({
  name: 'unipassReceipts',
  initialState,
  reducers: {
    getRefundRequests: (state) => {
      state.refund.loading = true
      state.refund.data = {}
      state.refund.selected = []
      state.refund.summary = {
        totalAmount: 0,
        totalAmountCanceled: 0,
        totalAmountConfirmed: 0,
        totalAmountFinished: 0,
        totalAmountPending: 0,
        totalUser: 0,
        totalUserCanceled: 0,
        totalUserConfirmed: 0,
        totalUserFinished: 0,
        totalUserPending: 0,
        totalAmountFailed: 0,
        totalUserFailed: 0
      }
    },
    getRefundRequestsDone: (state, action) => {
      state.refund.loading = false
      state.refund.data = action.payload?.data
      state.refund.summary = action.payload?.summary
    },
    setRefundCheck: (state, action) => {
      state.refund.selected = action.payload
    },
    validatePhone: (state, action) => {},
    createRefundRequest: (state, action) => {},
    exportExcel: (state, action) => {},
    cancelRequest: (state, action) => {},
    updateRequest: (state, action) => {},
    import: (state, action) => {}
  }
})

export const unipassActions = unipassSlice.actions

const unipassReducer = unipassSlice.reducer
export default unipassReducer
