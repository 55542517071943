import { put, takeLatest, call } from '@redux-saga/core/effects'
import reportApi from '@ourSrc/api/reportApi'
import { USER_TYPE } from '@ourSrc/constants/queryCustomer'
import moment from 'moment'

import { customerListActions } from './customerList.slice'

const customers = Object.values(USER_TYPE)

export const convertData = (data) => {
  return data.map((i, idx) => ({
    ...i,
    index: idx + 1,
    cardType: customers.find((c) => c.value === i.cardType)?.label || '',
    dateAdd: moment(new Date(i.dateAdd)).format('DD/MM/YYYY HH:mm:ss')
  }))
}

function* handleGetCustomerList({ payload }) {
  const { params } = payload
  try {
    const response = yield call(reportApi.getCustomerReport, params)
    const data = convertData(response.data.items)
    yield put(customerListActions.getCustomerListSuccess({ data, total: response.data.totalItem }))
  } catch (error) {
    yield put(customerListActions.getCustomerListFailed())
  }
}

function* handleGetCustomerDetail({ payload }) {
  const { params } = payload

  try {
    const response = yield call(reportApi.getCustomerReportDetail, params.customerId)
    response.data.birthday = moment(new Date(response.data.birthday)).format('DD/MM/YYYY')
    response.data.dateAdd = moment(new Date(response.data.dateAdd)).format('DD/MM/YYYY')
    response.data.expireDate = response.data.expireDate
      ? response.data.expireDate.split('-').reverse().join('/')
      : ''
    if (response.data.avatar) {
      response.data.avatar = response.data.avatar.includes('http')
        ? response.data.avatar
        : `https://admin.unipass.vn${response.data.avatar}`
    }
    if (response.data.studentCardImage) {
      response.data.studentCardImage = response.data.studentCardImage.includes('http')
        ? response.data.studentCardImage
        : `https://admin.unipass.vn${response.data.studentCardImage}`
    }

    if (response.data.idppFrontImage) {
      response.data.idppFrontImage = response.data.idppFrontImage.includes('http')
        ? response.data.idppFrontImage
        : `https://admin.unipass.vn${response.data.idppFrontImage}`
    }

    if (response.data.idppAfterImage) {
      response.data.idppAfterImage = response.data.idppAfterImage.includes('http')
        ? response.data.idppAfterImage
        : `https://admin.unipass.vn${response.data.idppAfterImage}`
    }

    yield put(customerListActions.getCustomerDetailSuccess(response.data))
  } catch (error) {
    yield put(customerListActions.getCustomerDetailFailed())
  }
}

export default function* customerListSaga() {
  yield takeLatest(customerListActions.getCustomerList.type, handleGetCustomerList)
  yield takeLatest(customerListActions.getCustomerDetail.type, handleGetCustomerDetail)
}
