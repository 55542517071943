import { isExpired, isTmpCard } from '@ourSrc/utility/CustomerUtils'
import { getResourceURL, formatVnFullDate } from '@ourSrc/utility/Utils'
import { createSlice } from '@reduxjs/toolkit'

const formatData = ({
  card_no,
  card_no_origin,
  avatar,
  idpp_after_image,
  idpp_front_image,
  student_card_image,
  poor_certificate,
  incident_certificate,
  birth_certificate,
  ...rest
}) => ({
  ...rest,
  ...(isTmpCard(card_no)
    ? {
        card_no: null,
        card_no_origin: card_no
      }
    : {
        card_no_origin,
        card_no
      }),
  avatar: getResourceURL(avatar),
  idpp_after_image: getResourceURL(idpp_after_image),
  idpp_front_image: getResourceURL(idpp_front_image),
  student_card_image: getResourceURL(student_card_image),
  poor_certificate: getResourceURL(poor_certificate),
  incident_certificate: getResourceURL(incident_certificate),
  birth_certificate: getResourceURL(birth_certificate)
})

const formatPaymentHistoryData = (data) => {
  return data.items.map((item, idx) => ({
    ...item,
    index: idx + 1 + data.offset,
    customer_type:
      item.customer_type === 4
        ? 'Phổ thông'
        : item.customer_type === 5
        ? 'Học sinh/Sinh viên'
        : item.customer_type === 6
        ? 'Người già/Khuyết tật/Thương bình'
        : null,
    status: item.status === 2 ? 'Thành công' : 'Thất bại'
  }))
}

const initialState = {
  data: undefined,
  loading: false,
  updating: false,
  error: false
}

const queryCustomerSlice = createSlice({
  name: 'queryCustomer',
  initialState,
  reducers: {
    getQueryCustomer: (state) => {
      state.loading = true
      state.error = false
      state.data = undefined
    },

    getQueryCustomerSuccess: (state, action) => {
      state.loading = false
      state.error = false
      state.data = formatData(action.payload)
    },

    getQueryCustomerFailed: (state) => {
      state.loading = false
      state.error = true
      state.data = undefined
    },

    updateCustomer: (state) => {
      state.updating = true
      state.error = false
    },

    updateCustomerSuccess: (state, action) => {
      state.updating = false
      state.error = false
      state.data = formatData(action.payload)
    },

    updateCustomerFailed: (state) => {
      state.updating = false
      state.error = true
    },

    getPaymentHistory: (state) => {
      state.loading = true
      state.error = false
      state.data.rechargeHistory = []
      state.data.paymentHistory = []
      state.data.total = 0
    },

    getPaymentHistorySuccess: (state, action) => {
      state.loading = false
      state.error = false
      state.data.paymentHistory = formatPaymentHistoryData(action.payload)
      state.data.total = action.payload.totalItem
    },

    getPaymentHistoryFailed: (state) => {
      state.loading = false
      state.error = true
    },
    getRechargeHistorySuccess: (state, action) => {
      state.loading = false
      state.error = false
      state.data.rechargeHistory = formatPaymentHistoryData(action.payload)
      state.data.total = action.payload.totalItem
    },
    confirmRequestCustomer: (state) => {
      state.error = false
    },
    rejectRequestCustomer: (state) => {
      state.error = false
    }
  }
})

export const queryCustomerActions = queryCustomerSlice.actions

const queryCustomerReducer = queryCustomerSlice.reducer
export default queryCustomerReducer
