import carApi from '@ourSrc/api/carApi'
import enterpriseApi from '@ourSrc/api/enterpriseApi'
import routeApi from '@ourSrc/api/routeApi'
import tempReceiptsApi from '@ourSrc/api/tempReceiptsApi'
import { PAYMENT_METHOD, TEMP_RECEIPTS, ticketType } from '@ourSrc/constants/dataType'
import { TICKET_TYPE } from '@ourSrc/constants/ticketType'
import {
  errorToast,
  formatVnFullDate,
  snakeCaseToCamelForObject,
  successToast
} from '@ourSrc/utility/Utils'
import { call, put, select, takeLatest } from '@redux-saga/core/effects'
import { uniqBy } from 'lodash'
import moment from 'moment'
import { tempReceiptsActions } from './slice'

const formatData = (data) =>
  data?.items?.map((item, key) => {
    const _ticket_type = Object.values(TICKET_TYPE).find((t) => t.value === item.ticket_type_id)
    const _status = Object.values(TEMP_RECEIPTS.STATUS).find((t) => t.value === item.reserve_status)
    item.ticket_type = _ticket_type?.name
    item.created_time_formatted = formatVnFullDate(item.created_time)
    item.status_message = _status?.label
    item.index = item.guid
    item.active_time_formatted = item.active_time ? formatVnFullDate(item.active_time) : null
    return snakeCaseToCamelForObject(item)
  })

const toDataSymbol = (data) =>
  data?.items?.map((item, key) => {
    const _ticket_type = Object.values(TICKET_TYPE).find((t) => t.value === item.ticketTypeId)
    item.ticketType = _ticket_type?.name
    item.index = data?.offset * data?.limit + key + 1
    item.amount = `${item.amount}`
    return item
  })

const toDataTurn = (data) =>
  data?.items?.map((item, key) => {
    item.dateTimeStartFormat = item?.dateTimeStart ? formatVnFullDate(item?.dateTimeStart) : ''
    item.dateTimeFinishFormat = item?.dateTimeFinish ? formatVnFullDate(item?.dateTimeFinish) : ''
    item.status = item?.isFinish ? 'Đã kết thúc' : 'Chưa kết thúc'
    item.turnName = `Chuyến ${item?.turnNumber}`
    return item
  })

function* handleGetTempReceiptsList({ payload }) {
  const { params } = payload
  try {
    yield put(tempReceiptsActions.setListCheckedReceipts([]))
    const response = yield call(tempReceiptsApi.getReceiptsList, params)
    const summary = yield call(tempReceiptsApi.getSumaryApi, params)
    yield put(tempReceiptsActions.setSumary(summary?.data))
    const data = { ...response?.data, items: formatData(response?.data) }
    yield put(tempReceiptsActions.getTempReceiptsListDone(data))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleCreateReceipts({ payload }) {
  const { data, onSuccess, onFaied } = payload
  try {
    const response = yield call(tempReceiptsApi.createReceiptsApi, data)
    const total = Number(data.serialTo) - Number(data.serialFrom) + 1
    if (response?.error?.code === 1) {
      successToast('Thành công', 'Tạo vé thành công')
      onSuccess(total)
    } else {
      errorToast('Thất bại', response?.error?.messageVN)
      onFaied({ total })
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleExportExcel({ payload }) {
  const { params, onSuccess, setLoadingExport } = payload
  try {
    setLoadingExport(true)
    const response = yield call(tempReceiptsApi.getReceiptsList, params)
    if (response?.data) {
      onSuccess(formatData(response?.data))
    }
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoadingExport(false)
  }
}

function* handleGetSymbol({ payload }) {
  const { params } = payload
  try {
    const response = yield call(tempReceiptsApi.getSymbolListApi, params)
    const data = { ...response?.data, items: toDataSymbol(response?.data) }
    yield put(tempReceiptsActions.getSymbolDone(data))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleCreateSymbol({ payload }) {
  const { data, onSuccess, setLoading } = payload
  try {
    const response = yield call(tempReceiptsApi.createSymbolApi, data)
    if (response?.data) {
      successToast('Thành công', 'Tạo mẫu số ký hiệu thành công')
      onSuccess()
    } else {
      errorToast('Thất bại', response?.error?.messageVN)
    }
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoading(false)
  }
}

function* handleUpdateSymbol({ payload }) {
  const { data, onSuccess, setLoading } = payload
  try {
    const response = yield call(tempReceiptsApi.updateSymbolApi, data)
    if (response?.data) {
      successToast('Thành công', 'Cập nhật mẫu số ký hiệu thành công')
      onSuccess()
    } else {
      errorToast('Thất bại', response?.error?.messageVN)
    }
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoading(false)
  }
}

function* handleGetEnterpriseOptions() {
  try {
    const response = yield call(enterpriseApi.getEnterprise)
    const formatData = response?.data?.map((item) => {
      item.value = item.enterprise_code
      item.label = item.enterprise_name
      return item
    })
    yield put(tempReceiptsActions.getEnterpriseOptionsDone(formatData))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetDenomination({ payload }) {
  const { params } = payload
  try {
    const response = yield call(tempReceiptsApi.getSymbolListApi, params)
    const formatData = response?.data?.items?.map((item) => ({
      value: item?.registeredDenominator,
      label: item?.registeredDenominator
    }))
    yield put(tempReceiptsActions.getDenominationDone(uniqBy(formatData, 'value')))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetCarCode({ payload }) {
  const { params } = payload
  try {
    const response = yield call(tempReceiptsApi.getSymbolListApi, params)
    console.log('response', response)
    const formatCarCode = [...response?.data?.items]?.map((item) => {
      item.value = item?.registeredCarCode
      item.label = item?.registeredCarCode
      return item
    })

    console.log('formatCarCode', formatCarCode)

    const formatTicketType = response?.data?.items?.map((item) => {
      const ticket = PAYMENT_METHOD[4].optionsTicketTypeId.find(
        (f) => f.value === item?.ticketTypeId
      )
      item.value = item?.ticketTypeId
      item.label = ticket.label
      return item
    })

    const formatPrice = response?.data?.items?.map((item) => {
      item.value = item?.amount
      item.label = `${item?.amount} VNĐ`
      return item
    })

    console.log('formatCarCode', formatCarCode)
    yield put(
      tempReceiptsActions.getCarCodeDone({
        carCode: uniqBy(formatCarCode, 'value'),
        ticketTypes: uniqBy(formatTicketType, 'value'),
        prices: formatPrice
      })
    )
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetTurn({ payload }) {
  const { params } = payload
  try {
    const response = yield call(tempReceiptsApi.getTurnListApi, params)
    const data = { ...response?.data, items: toDataTurn(response?.data) }
    yield put(tempReceiptsActions.getTurnDone(data))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleFinishTurn({ payload }) {
  const { data, setLoading, onSuccess } = payload
  try {
    const response = yield call(tempReceiptsApi.finishTurn, data)
    if (response?.error?.code === 1) {
      successToast('Thành công', 'Kết thúc chuyến thành công')
      onSuccess()
    } else {
      errorToast('Thất bại', response?.error?.messageVN)
    }
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoading(false)
  }
}

function* handleGetRouteOpts({ payload }) {
  const { enterpriseCode } = payload
  try {
    const response = yield call(routeApi.getRouteByEnterprise, enterpriseCode)
    const formatData = response?.data?.map((item) => {
      item.value = item.route_id
      item.label = `${item.route_number} - ${item.route_name}`
      return item
    })
    yield put(tempReceiptsActions.setRouteOpts(formatData))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetRouteOptsByAmountAndTicketTypeId({ payload }) {
  try {
    const response = yield call(routeApi.getRouteOptsByAmountAndTicketTypeIdApi, payload)
    const formatData = response?.data?.map((item) => {
      item.value = item.route_id
      item.label = `${item.route_number} - ${item.route_name}`
      return item
    })
    yield put(tempReceiptsActions.setRouteOpts(formatData))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetCarOpts({ payload }) {
  const { enterpriseCode, routeNumber } = payload
  try {
    const response = yield call(
      carApi.getCarListByEnterpriseCodeAndRouteNumber,
      enterpriseCode,
      routeNumber
    )
    const formatData = response?.data?.map((item) => {
      item.value = item.car_id
      item.label = `xe ${item.car_number}`
      return item
    })
    yield put(tempReceiptsActions.setCarOpts(formatData))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleGetTurnOpts({ payload }) {
  const { params } = payload
  try {
    const response = yield call(tempReceiptsApi.getTurnListApi, {
      ...params,
      fromDate: moment().startOf('day'),
      toDate: moment().endOf('day')
    })
    const formatData = response?.data?.items?.map((item) => {
      item.value = item.turnCode
      item.label = `Chuyến ${item.turnNumber}`
      return item
    })
    yield put(tempReceiptsActions.getTurnOptsDone(formatData))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleCreateTurn({ payload }) {
  const { data, onSuccess, setLoading } = payload
  try {
    const response = yield call(tempReceiptsApi.createTurnApi, data)
    if (response?.data) {
      successToast('Thành công', 'Tạo chuyến thành công')
      onSuccess()
    } else {
      errorToast('Thất bại', response?.error?.messageVN)
    }
  } catch (error) {
    console.log('error', error)
  } finally {
    setLoading(false)
  }
}

function* handleActiveReceipts({ payload }) {
  try {
    const { data, onSuccess, onFaied, setProgressData } = payload
    function* process(data) {
      yield* data.map((x) => x)
    }
    let itemProcessed = 0
    let itemSuccess = 0
    const failedItems = []
    for (const n of process(data.activeReceipts)) {
      const params = { ...data.dataForm, guid: n.guid }
      const response = yield call(tempReceiptsApi.activeReceiptsApi, params)
      itemProcessed += 1
      setProgressData({ total: data?.activeReceipts.length, value: itemProcessed })
      if (response.error.code === 1) {
        itemSuccess += 1
      } else {
        failedItems.push(n)
      }
    }
    if (!failedItems?.length) {
      onSuccess(itemProcessed)
    } else {
      onFaied({
        ...data,
        activeReceipts: failedItems,
        total: data?.activeReceipts.length
      })
    }
    yield put(tempReceiptsActions.setListCheckedReceipts(failedItems))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleDeleteReceipts({ payload }) {
  try {
    const { email, company } = yield select((state) => state.auth?.user?.identity?.traits)
    const { data, onSuccess, onFaied, setProgressData, refetch } = payload
    function* process(data) {
      yield* data.map((x) => x)
    }
    let itemProcessed = 0
    let itemSuccess = 0
    const failedItems = []
    for (const n of process(data)) {
      const params = { requester: email, guid: n.guid, enterpriseCodes: company }
      const response = yield call(tempReceiptsApi.deleteReceiptsApi, params)
      itemProcessed += 1
      setProgressData({ total: data?.length, value: itemProcessed })
      if (response.error.code === 1) {
        itemSuccess += 1
      } else {
        failedItems.push(n)
      }
    }
    if (!failedItems?.length) {
      onSuccess(itemProcessed)
    } else {
      onFaied({
        deleteReceipts: failedItems,
        total: data?.length
      })
    }
    refetch()
    yield put(tempReceiptsActions.setListCheckedReceipts(failedItems))
  } catch (error) {
    console.log('error', error)
  }
}

function* handleAddNote({ payload }) {
  try {
    const { email, company } = yield select((state) => state.auth?.user?.identity?.traits)
    const { data, onSuccess, onFaied, setProgressData, refetch } = payload
    function* process(data) {
      yield* data.map((x) => x)
    }
    let itemProcessed = 0
    let itemSuccess = 0
    const failedItems = []
    for (const n of process(data.editNoteReceipts)) {
      const params = { requester: email, guid: n.guid, enterpriseCodes: company, note: data.note }
      const response = yield call(tempReceiptsApi.updateNoteReceiptsApi, params)
      itemProcessed += 1
      setProgressData({ total: data?.editNoteReceipts?.length, value: itemProcessed })
      if (response.error.code === 1) {
        itemSuccess += 1
      } else {
        failedItems.push(n)
      }
    }
    if (!failedItems?.length) {
      onSuccess(itemProcessed)
    } else {
      onFaied({
        deleteReceipts: failedItems,
        total: data?.editNoteReceipts?.length,
        note: data?.note
      })
    }
    refetch()
    yield put(tempReceiptsActions.setListCheckedReceipts(failedItems))
  } catch (error) {
    console.log('error', error)
  }
}

export default function* tempReceipts() {
  yield takeLatest(tempReceiptsActions.getTempReceiptsList.type, handleGetTempReceiptsList)
  yield takeLatest(tempReceiptsActions.addNote, handleAddNote)
  yield takeLatest(tempReceiptsActions.createReceipts, handleCreateReceipts)

  yield takeLatest(tempReceiptsActions.deleteReceipts, handleDeleteReceipts)
  yield takeLatest(tempReceiptsActions.activeReceipts, handleActiveReceipts)
  yield takeLatest(tempReceiptsActions.exportExcel, handleExportExcel)

  yield takeLatest(tempReceiptsActions.getSymbol.type, handleGetSymbol)
  yield takeLatest(tempReceiptsActions.createSymbol, handleCreateSymbol)
  yield takeLatest(tempReceiptsActions.updateSymbol, handleUpdateSymbol)
  yield takeLatest(tempReceiptsActions.getEnterpriseOptions, handleGetEnterpriseOptions)
  yield takeLatest(tempReceiptsActions.getDenomination, handleGetDenomination)
  yield takeLatest(tempReceiptsActions.getCarCode, handleGetCarCode)
  yield takeLatest(tempReceiptsActions.getRouteOpts, handleGetRouteOpts)
  yield takeLatest(tempReceiptsActions.getCarOpts, handleGetCarOpts)
  yield takeLatest(tempReceiptsActions.getTurnOpts, handleGetTurnOpts)
  yield takeLatest(
    tempReceiptsActions.getRouteOptsByAmountAndTicketTypeId,
    handleGetRouteOptsByAmountAndTicketTypeId
  )

  yield takeLatest(tempReceiptsActions.getTurn, handleGetTurn)
  yield takeLatest(tempReceiptsActions.finishTurn, handleFinishTurn)
  yield takeLatest(tempReceiptsActions.createTurn, handleCreateTurn)
}
