import { put, takeLatest, call } from '@redux-saga/core/effects'
import _cloneDeep from 'lodash/cloneDeep'
import _groupBy from 'lodash/groupBy'
import _sumBy from 'lodash/sumBy'
import eventApi from '@ourSrc/api/event'
import { recipientsActions } from './slice'
import { formatVnFullDate } from '@ourSrc/utility/Utils'

function* handleGetRecipientsList({ payload }) {
  const { params } = payload
  try {
    const response = yield call(eventApi.getRecipientsList, params)
    if (response?.data) {
      const formatData = response.data.items.map((item, key) => {
        item.stt = key + response.data.offset + 1
        return item
      })
      yield put(recipientsActions.getRecipientsListSuccess({ ...response.data, items: formatData }))
    } else {
      yield put(recipientsActions.getRecipientsListFail())
    }
  } catch (error) {
    console.log('error', error)
    yield put(recipientsActions.getRecipientsListFail())
  }
}
function* handleExport({ payload }) {
  const { params, onSuccess } = payload
  try {
    const response = yield call(eventApi.getRecipientsList, params)
    if (response?.data) {
      const formatData = response.data.items.map((item, key) => {
        item.stt = key + response.data.offset + 1
        item.customer_date_add = formatVnFullDate(item.customer_date_add)
        item.campaign_started = formatVnFullDate(item.campaign_started)
        item.campaign_expired = formatVnFullDate(item.campaign_expired)
        item.promotion_date = formatVnFullDate(item.promotion_date)
        return item
      })

      onSuccess(formatData)
    }
  } catch (error) {
    console.log('error', error)
  }
}
export default function* recipientsSaga() {
  yield takeLatest(recipientsActions.getRecipientsList.type, handleGetRecipientsList)
  yield takeLatest(recipientsActions.handleExport.type, handleExport)
}
