import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  loading: false,
  roles: [],
  enterprises: []
}

const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    getUserList: (state) => {
      state.loading = true
      state.data = {}
    },
    getUserListSuccess: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    getUserListFailed: (state) => {
      state.loading = false
      state.data = {}
    },
    addNewUserKratos: (state) => {},
    updateUser: (state) => {},
    deleteUser: (state) => {},
    getListRoles: (state) => {},
    getListRolesDone: (state, action) => {
      state.roles = action.payload
    },
    getListEnterprise: (state) => {},
    getListEnterpriseDone: (state, action) => {
      state.enterprises = action.payload
    }
  }
})

export const userListActions = userListSlice.actions

const userListReducer = userListSlice.reducer
export default userListReducer
